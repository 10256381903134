import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { reducer as formReducer } from 'redux-form';
import { ConcediiActions } from './types';
import { authentication } from './reducers/authentication.reducer';
import { users } from './reducers/users.reducer';
import { audit } from './reducers/audit.reducer';
import { authorities } from './reducers/authority.reducer';
import { alert } from './reducers/alert.reducer';
import { groups } from './reducers/groups.reducer';
import { taxonomies } from './reducers/taxonomy.reducer';
import { concedii } from './reducers/concedii.reducer';
import { reports } from './reducers/reports.reducer';
import { msign } from './reducers/msign.reducer';
/* eslint no-underscore-dangle: 0 */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistedAuthReducer = persistReducer(
  {
    key: 'auth',
    storage,
  },
  authentication,
);

const rootReducer = combineReducers({
  authentication: persistedAuthReducer,
  audit,
  users,
  authorities,
  alert,
  groups,
  taxonomies,
  form: formReducer.plugin({
    concediuForm: (state, action) => {
      switch (action.type) {
        case ConcediiActions.RESET_THE_ENTIRE_FORM:
          return {};
        default:
          return state;
      }
    },
  }),
  concedii,
  reports,
  msign,
});

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunkMiddleware)),
);

export const persistor = persistStore(store);
