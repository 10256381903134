import React from 'react';
import styled from 'styled-components';
import { FileUploaderButton, DataTable } from 'carbon-components-react/';
import { connect } from 'react-redux';
import Download16 from '@carbon/icons-react/es/download/16';
import Delete16 from '@carbon/icons-react/es/delete/16';
import PropTypes from 'prop-types';
import { fileActions } from '../redux/actions';
import BaseTable from '../components/table/BaseTable';
import AlchemyNotification from '../components/form/AlchemyNotification';
import { hasRole } from '../helpers/utils';

const Content = styled.div`
  overflow-x: hidden;
`;

const TitleModule = styled.h3`
  margin: 1rem 3rem;
  font-size: 1.5rem;
  font-weight: bold;
`;

const headers = [
  { code: 'nr', description: 'Nr' },
  { code: 'name', description: 'Nume' },
  { code: 'uploadDate', description: 'Data incarcarii' },
  { code: 'actions', description: 'Actiuni' },
];

class GuideFiles extends React.PureComponent {
  static propTypes = {
    files: PropTypes.instanceOf(Array).isRequired,
    getFiles: PropTypes.instanceOf(Function).isRequired,
    uploadFiles: PropTypes.instanceOf(Function).isRequired,
    downloadFile: PropTypes.instanceOf(Function).isRequired,
    deleteFile: PropTypes.instanceOf(Function).isRequired,
  };

  componentDidMount() {
    const { getFiles } = this.props;
    getFiles();
  }

  handleChange = evt => {
    const { uploadFiles } = this.props;
    uploadFiles(evt.target.files);
  };

  render() {
    const { files, downloadFile, deleteFile } = this.props;
    return (
      <Content>
        <TitleModule>Ghiduri</TitleModule>
        <React.Fragment>
          <AlchemyNotification />
          <BaseTable
            title=""
            headers={headers}
            rows={
              files
                ? files.map((file, indx) => {
                    return {
                      ...file,
                      nr: indx + 1,
                      uploadDate: file.uploadDate
                        ? new Date(file.uploadDate).toLocaleDateString()
                        : '',
                    };
                  })
                : []
            }
            cellRendering={cell => {
              if (cell.id.includes(':actions')) {
                const id = cell.id.match(/\d+/g)[0];
                return (
                  <DataTable.TableCell key={cell.id}>
                    <Download16 onClick={() => downloadFile(`/files/${id}`)} />
                    {hasRole('ROLE_ADMIN') && (
                      <Delete16 onClick={() => deleteFile(`/files/`, id)} />
                    )}
                  </DataTable.TableCell>
                );
              }
              return (
                <DataTable.TableCell key={cell.id}>
                  {cell.value}
                </DataTable.TableCell>
              );
            }}
          >
            {hasRole('ROLE_ADMIN') && (
              <FileUploaderButton
                component="topButton"
                buttonKind="primary"
                disableLabelChanges="true"
                labelText="Adauga"
                multiple
                onChange={this.handleChange}
              />
            )}
          </BaseTable>
        </React.Fragment>
      </Content>
    );
  }
}
export default connect(
  state => {
    const { concedii } = state;
    return concedii;
  },
  {
    uploadFiles: fileActions.uploadFiles,
    getFiles: fileActions.getFiles,
    downloadFile: fileActions.downloadFile,
    deleteFile: fileActions.deleteFile,
  },
)(GuideFiles);
