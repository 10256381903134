import { MsignActions } from '../types';

const initialState = {};

export function msign(state = initialState, action) {
  switch (action.type) {
    case MsignActions.GET_SIGN_REQUEST:
      return {
        ...state,
      };
    case MsignActions.GET_SIGN_SUCCESS:
      return {
        signResponse: action.signResponse,
      };
    case MsignActions.GET_SIGN_FAILURE:
      return {
        signResponse: state.signResponse,
        error: action.error,
      };
    case MsignActions.POST_SIGN_REQUEST:
      return {
        ...state,
      };
    case MsignActions.POST_SIGN_SUCCESS:
      return {
        postSignResult: action.postSignResult,
      };
    case MsignActions.POST_SIGN_FAILURE:
      return {
        postSignResult: state.postSignResult,
        error: action.error,
      };
    default:
      return state;
  }
}
