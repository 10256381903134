/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Form, Button } from 'carbon-components-react';
import get from 'lodash/get';
import AlchemyAdvancedSelect from '../../../components/form/AlchemyAdvancedSelect';
import {
  userActions,
  groupActions,
  taxonomyActions,
} from '../../../redux/actions';
import AlchemyTextInput from '../../../components/form/AlchemyTextInput';
import AlchemyCheckboxGroup from '../../../components/form/AlchemyCheckboxGroup';
import * as validation from '../../../helpers/validation';
import AlchemySelect from '../../../components/form/AlchemySelect';
import AlchemyCheckbox from '../../../components/form/AlchemyCheckbox';
import AlchemyDateTimePicker from '../../../components/form/AlchemyDateTimePicker';

class EditUserForm extends React.PureComponent {
  state = {
    isEdit: true,
  };

  componentDidMount() {
    const { data, getUser, getGroups, getAvailablerRecorders } = this.props;
    getUser(data.id);
    getAvailablerRecorders(data.id);
    getGroups();
  }

  clearEdit() {
    this.setState(state => ({
      isEdit: false,
    }));
  }

  saveUser = event => {
    const { editUserReduxForm, data } = this.props;
    editUserReduxForm(data.id);
  };

  loadRecorders(event) {
    if (event) {
      const { getRecordersUsersByInstitutionId } = this.props;
      getRecordersUsersByInstitutionId(event);
    }
  }

  render() {
    const { groups, taxonomies, recorders, handleSubmit } = this.props;
    const { isEdit } = this.state;

    return (
      <Form
        className="box white-backgrounds"
        onSubmit={handleSubmit(() => {
          return this.saveUser();
        })}
      >
        <div className="box__double-block">
          <div>
            <Field
              disabled
              name="userName"
              type="text"
              component={AlchemyTextInput}
              label="Nume utilizator"
              validate={[validation.required]}
            />

            <Field
              name="firstName"
              type="text"
              component={AlchemyTextInput}
              label="Nume"
              validate={[validation.required]}
            />

            <Field
              name="lastName"
              type="text"
              component={AlchemyTextInput}
              label="Prenume"
              validate={[validation.required]}
            />

            <Field
              name="idnp"
              type="text"
              component={AlchemyTextInput}
              label="IDNP"
              validate={[validation.required, validation.number]}
            />

            <Field
              name="email"
              type="email"
              component={AlchemyTextInput}
              label="Email"
              validate={[validation.required, validation.email]}
              warn={validation.aol}
            />

            <Field
              name="phoneNumber"
              type="text"
              component={AlchemyTextInput}
              label="Telefon"
              validate={[validation.required, validation.phoneNumber]}
            />

            {/* <Field
              name="dateExpiration"
              labelText="Data expirare parola"
              component={AlchemyDateTimePicker}
              minDate={new Date()}
            /> */}

            {/* <Field
              name="active"
              component={AlchemyCheckbox}
              label="Activ"
              type="checkbox"
            /> */}
          </div>
          <div>
            <div>
              <div>Roluri</div>
            </div>
            <Field
              name="roles"
              component={AlchemyCheckboxGroup}
              options={groups.map(group => ({
                ...group,
                name: group.description,
              }))}
            />
            <Field
              name="institution.id"
              component={AlchemyAdvancedSelect}
              labelText="Institutia"
              options={taxonomies && taxonomies.NmInstitutions}
              validate={validation.required}
              onChange={event => this.loadRecorders(event)}
            />
            <Field
              name="recorders"
              component={AlchemyAdvancedSelect}
              onChange={() => this.clearEdit()}
              isMulti
              isEdit={isEdit}
              labelText="Registratori spre verificare"
              options={
                recorders &&
                recorders.map(usr => {
                  return {
                    id: usr.id,
                    description: usr.lastName + ' ' + usr.firstName,
                  };
                })
              }
            />
          </div>
        </div>
        <div className="edit-user-form__save-bar">
          <Button className="bx--btn bx--btn--primary" type="submit">
            Salveaza
          </Button>
        </div>
      </Form>
    );
  }
}

const extractIds = arr => (Array.isArray(arr) ? arr.map(d => d.id || d) : arr);
const normalizeInitialValues = data => {
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      data[key] = extractIds(data[key]);
    }
  }
  return data;
};

const ReduxForm = reduxForm({
  form: 'editUserForm',
  enableReinitialize: true,
})(EditUserForm);

export default connect(
  (state, ownProps) => {
    const { groups, taxonomies, users } = state;
    const { data } = ownProps;
    normalizeInitialValues(data);
    return {
      recorders: get(users, 'recorders', []),
      groups: get(groups, 'groups', []),
      initialValues: { ...data },
      taxonomies: taxonomies.taxonomies,
    };
  },
  {
    getGroups: groupActions.getGroups,
    getUser: userActions.getUser,
    editUserReduxForm: userActions.editUserReduxForm,
    getRecordersUsersByInstitutionId:
      userActions.getRecordersUsersByInstitutionId,
    getAvailablerRecorders: userActions.getAvailablerRecorders,
  },
)(ReduxForm);
