import { MsignActions } from '../types';
import { alertActions } from './alert.actions';
import { get, post } from '../../helpers';
import { history } from '../../helpers/history';

const getSignResponseForCertIdAndRequestId = (
  certificateId,
  requestId,
) => async dispatch => {
  try {
    dispatch({ type: MsignActions.GET_SIGN_REQUEST });
    const response = await get(`/msign/get-sign/${certificateId}/${requestId}`);
    const signResponse = await response.json();
    dispatch({ type: MsignActions.GET_SIGN_SUCCESS, signResponse });
    setTimeout(() => history.push(`/`), 100);
  } catch (error) {
    dispatch({ type: MsignActions.GET_SIGN_FAILURE, error });
    dispatch(alertActions.error('Semnarea certificatului a esuat'));
    setTimeout(() => history.push(`/`), 5000);
  }
};

const postSignForCertId = certificateId => async dispatch => {
  try {
    dispatch({ type: MsignActions.POST_SIGN_REQUEST });
    const response = await post(`/msign/${certificateId}`);
    const postSignResult = await response.json();
    dispatch({ type: MsignActions.POST_SIGN_SUCCESS, postSignResult });
    dispatch(alertActions.clear());
    setTimeout(() => history.push(`/msign-redirect`), 500);
  } catch (error) {
    dispatch({ type: MsignActions.POST_SIGN_FAILURE, error });
    dispatch(
      alertActions.error(
        error.message || 'Datele nu au putut fi transmise la MSign',
      ),
    );
  }

  // try {
  //   dispatch({ type: ConcediiActions.SET_STATUS_SIGNED_REQUEST });
  //   const response = await post(`/certificate/set-status/${certificateId}/15`);
  //   const respCertificate = await response.json();
  //   dispatch({
  //     type: ConcediiActions.SET_STATUS_SIGNED_SUCCESS,
  //     respCertificate,
  //   });
  //   dispatch(alertActions.success('Certificatul a fost semnat cu succes.'));
  // } catch (error) {
  //   dispatch({
  //     type: ConcediiActions.SET_STATUS_SIGNED_FAILURE,
  //     error,
  //   });
  //   dispatch(
  //     alertActions.error(error.message || 'Nu a putut fi semnat certificatul'),
  //   );
  // }
};

export const msignActions = {
  getSignResponseForCertIdAndRequestId,
  postSignForCertId,
};
