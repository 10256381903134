import { ReportActions } from '../types/reports.types';

const initialState = {};

export function reports(state = initialState, action) {
  switch (action.type) {
    case ReportActions.GENERATEREP_REQUEST:
    case ReportActions.GETREP_REQUEST:
      return {
        ...state,
      };
    case ReportActions.GETREP_SUCCESS:
      return {
        reports: action.reports,
      };
    case ReportActions.GENERATEREP_FAILURE:
    case ReportActions.GETREP_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}
