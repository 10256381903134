import React from 'react';
import styled from 'styled-components';
import Logo from './logo/Logo';
import { $brand01 } from '../../themeVariables';
// import ProfileMenuComponent from './profile-menu/ProfileMenu';

const Header = styled.header`
  grid-column: full-start / full-end;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 2rem;
  padding: 0 1.2rem;
  background-color: ${$brand01};
  color: #fff;
`;

const HeaderComponent = () => (
  <Header>
    <Logo />
    {/* <ProfileMenuComponent imageUrl="https://mdbootstrap.com/img/Photos/Avatars/img%20(20).jpg" /> */}
  </Header>
);

export default HeaderComponent;
