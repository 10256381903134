import React from 'react';
import Grid from '../components/Grid';

const DefaultLayout = Component =>
  // eslint-disable-next-line react/prefer-stateless-function
  class extends React.Component {
    render() {
      return (
        <React.Fragment>
          <Grid>
            <Component {...this.props} />
          </Grid>
        </React.Fragment>
      );
    }
  };

export default DefaultLayout;
