import { GroupActions } from '../types';

const initialState = {
  groups: [],
};

export function groups(state = initialState, action) {
  switch (action.type) {
    case GroupActions.GROUPS_GET_ALL_REQUEST:
      return {
        ...state,
      };
    case GroupActions.GROUPS_GET_ALL_SUCCESS:
      return {
        groups: action.groups.slice().sort((a, b) => {
          if (a.description > b.description) {
            return 1;
          }
          if (b.description > a.description) {
            return -1;
          }
          return 0;
        }),
      };
    case GroupActions.GROUPS_EDIT_SUCCESS:
      return {
        groups: [
          ...state.groups.filter(group => group.id !== action.group.id),
          action.group,
        ]
          .slice()
          .sort((a, b) => {
            if (a.description > b.description) {
              return 1;
            }
            if (b.description > a.description) {
              return -1;
            }
            return 0;
          }),
      };
    case GroupActions.GROUPS_GET_ALL_ERROR:
    case GroupActions.GROUPS_EDIT_ERROR:
      return {
        error: action.error,
      };
    case GroupActions.GROUP_REMOVE_USER_SUCCESS:
    case GroupActions.GROUP_REMOVE_USER_ERROR:
      return {
        groups: state.groups.slice().sort((a, b) => a - b),
        error: action.error,
      };
    default:
      return state;
  }
}
