import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const selectDirection = props => {
    const { location } = props;
    return rest.restriction ? (
      <Redirect
        to={{ pathname: '/acces-interzis', state: { from: location } }}
      />
    ) : (
      <Component {...props} />
    );
  };
  return (
    <Route
      {...rest}
      render={props =>
        sessionStorage.getItem('token') ||
        props.location.pathname.startsWith('/login-result') ? (
          selectDirection(props)
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.string,
};

PrivateRoute.defaultProps = {
  location: undefined,
};
