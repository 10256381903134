export const UserActions = {
  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
  LOGOUT: 'USERS_LOGOUT',
  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',
  GETALL_REQUEST: 'USERS_GETALL_REQUEST',
  GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
  GETALL_FAILURE: 'USERS_GETALL_FAILURE',
  GETPAGE_REQUEST: 'USERS_GETPAGE_REQUEST',
  GETPAGE_SUCCESS: 'USERS_GETPAGE_SUCCESS',
  GETPAGE_FAILURE: 'USERS_GETPAGE_FAILURE',
  CREATE_REQUEST: 'USERS_CREATE_REQUEST',
  CREATE_SUCCESS: 'USERS_CREATE_SUCCESS',
  CREATE_FAILURE: 'USERS_CREATE_FAILURE',
  EDIT_REQUEST: 'USERS_EDIT_REQUEST',
  EDIT_SUCCESS: 'USERS_EDIT_SUCCESS',
  EDIT_FAILURE: 'USERS_EDIT_FAILURE',
  EDIT_ROLES_SUCCESS: 'EDIT_ROLES_SUCCESS',
  EDIT_ROLES_FAILURE: 'EDIT_ROLES_FAILURE',
  GET_AFTER_SEARCH_REQUEST: 'GET_AFTER_SEARCH_REQUEST',
  GET_AFTER_SEARCH_SUCCESS: 'GET_AFTER_SEARCH_SUCCESS',
  GET_AFTER_SEARCH_FAILURE: 'GET_AFTER_SEARCH_FAILURE',
  USERS_CHANGE_EXPIRED_PWD_REQUEST: 'USERS_CHANGE_EXPIRED_PWD_REQUEST',
  USERS_CHANGE_EXPIRED_PWD_SUCCESS: 'USERS_CHANGE_EXPIRED_PWD_SUCCESS',
  USERS_CHANGE_EXPIRED_PWD_FAILURE: 'USERS_CHANGE_EXPIRED_PWD_FAILURE',
  USERS_CHANGE_PWD_REQUEST: 'USERS_CHANGE_PWD_REQUEST',
  USERS_CHANGE_PWD_SUCCESS: 'USERS_CHANGE_PWD_SUCCESS',
  USERS_CHANGE_PWD_FAILURE: 'USERS_CHANGE_PWD_FAILURE',
  GET_USERS_FOR_SEARCH_REQUEST: 'GET_USERS_FOR_SEARCH_REQUEST',
  GET_USERS_FOR_SEARCH_SUCCESS: 'GET_USERS_FOR_SEARCH_SUCCESS',
  GET_USERS_FOR_SEARCH_FAILURE: 'GET_USERS_FOR_SEARCH_FAILURE',
  GET_USERS_BY_INSTITUTION_ID_REQUEST: 'GET_USERS_BY_INSTITUTION_ID_REQUEST',
  GET_USERS_BY_INSTITUTION_ID_SUCCESS: 'GET_USERS_BY_INSTITUTION_ID_SUCCESS',
  GET_USERS_BY_INSTITUTION_ID_FAILURE: 'GET_USERS_BY_INSTITUTION_ID_FAILURE',
  GET_RECORDERS_BY_INSTITUTION_REQUEST: 'GET_RECORDERS_BY_INSTITUTION_REQUEST',
  GET_RECORDERS_BY_INSTITUTION_SUCCESS: 'GET_RECORDERS_BY_INSTITUTION_SUCCESS',
  GET_RECORDERS_BY_INSTITUTION_FAILURE: 'GET_RECORDERS_BY_INSTITUTION_FAILURE',
  GET_AVAILABLE_RECORDERS_REQUEST: 'GET_AVAILABLE_RECORDERS_REQUEST',
  GET_AVAILABLE_RECORDERS_SUCCESS: 'GET_AVAILABLE_RECORDERS_SUCCESS',
  GET_AVAILABLE_RECORDERS_FAILURE: 'GET_AVAILABLE_RECORDERS_FAILURE',
  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',
  LOGIN_SAML_REQUEST: 'LOGIN_SAML_REQUEST',
  LOGIN_SAML_SUCCESS: 'LOGIN_SAML_SUCCESS',
  LOGIN_SAML_FAILURE: 'LOGIN_SAML_FAILURE',
};
