import { AuditActions } from '../types';

export function audit(state = {}, action) {
  switch (action.type) {
    case AuditActions.GET_USER_LOGINS_SUCCESS:
      return {
        auditLoginsPage: action.page,
      };
    case AuditActions.GET_USER_LOGINS_REQUEST:
      return {
        auditLoginsPage: action.page,
      };
    case AuditActions.GET_USER_LOGINS_ERROR:
      return {
        error: action.error,
      };
    case AuditActions.GET_USER_VISITS_SUCCESS:
      return {
        auditVisitsPage: action.page,
      };
    case AuditActions.GET_USER_VISITS_ERROR:
      return {
        error: action.error,
      };
    case AuditActions.CLEAR:
      return {};
    default:
      return state;
  }
}
