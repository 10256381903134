import { history } from './helpers';

const inactivityChecker = () => {
  let logoutTimeout = null;

  const logout = () => {
    const token = sessionStorage.getItem('token');
    if (token) {
      sessionStorage.removeItem('token');
    }
    history.push('/');
  };

  const timeoutSetting = () => {
    const milliseconds = process.env.REACT_APP_INACTIVITY_TIMEOUT;
    if (milliseconds) {
      logoutTimeout = setTimeout(logout, milliseconds);
    }
  };

  const resetTimeout = () => {
    if (logoutTimeout) {
      clearTimeout(logoutTimeout);
    }
    timeoutSetting();
  };

  const events = [
    'load',
    'mousemove',
    'mousedown',
    'click',
    'scroll',
    'keypress',
  ];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < events.length; i++) {
    window.addEventListener(events[i], resetTimeout);
  }

  timeoutSetting();
};

export { inactivityChecker };
