/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
import React, { Component } from 'react';
import { DataTable, Pagination } from 'carbon-components-react';
import styled from 'styled-components';

import { $ui01, $ui03 } from '../../themeVariables';
import './base-table.scss';
import { buildHeaders } from '../../helpers/utils';

const {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  TableSelectAll,
  TableToolbar,
  TableToolbarSearch,
  TableSelectRow,
  TableExpandHeader,
  TableExpandRow,
  TableExpandedRow,
} = DataTable;

const getChildComponent = (name, childComponents) =>
  childComponents.filter(child => child.props.component === name)[0];

const StyledTableContainer = styled(TableContainer)`
  // padding: 1rem 3rem;
`;

const StyledTable = styled(Table)`
  thead {
    background-color: ${$ui03};
  }
  tbody {
    background-color: ${$ui01};
  }
  td:last-child {
    min-width: 120px !important;
  }
`;

const TableAddNew = styled.div`
  margin-left: auto;
`;

class BaseTable extends Component {
  render() {
    const cnlButton = document.querySelector('.bx--batch-summary__cancel');
    if (cnlButton) {
      document.querySelector('.bx--batch-summary__cancel').innerHTML =
        'Anulare';
    }

    const {
      rows,
      pageDetails,
      headers,
      title,
      paginationProps,
      onSearchInputChange,
      onSortParamsChange,
      children,
      searcheable,
      cellRendering,
      onChange,
      isExpanded,
      tableResponsive,
      tableExpanded,
    } = this.props;

    const childComponents = React.Children.toArray(children);
    const batchActions = getChildComponent('batchActions', childComponents);
    const topButton = getChildComponent('topButton', childComponents);
    const editForm = getChildComponent('editForm', childComponents);

    const data = rows;
    return (
      rows && (
        <DataTable
          rows={rows}
          headers={buildHeaders(headers)}
          render={renderProps => {
            let selectionButtons = null;
            if (batchActions) {
              selectionButtons = React.Children.toArray(
                batchActions.props.children,
              );

              selectionButtons = selectionButtons.map(c =>
                React.cloneElement(c, {
                  onClick: () => {
                    if (c.props.onClick) {
                      c.props.onClick(renderProps.selectedRows);
                    }
                  },
                }),
              );
            }
            const configuredBatchActions =
              batchActions &&
              React.cloneElement(batchActions, {
                ...renderProps.getBatchActionProps(),
                children: selectionButtons,
              });
            return (
              <StyledTableContainer title={title}>
                <TableToolbar>
                  {batchActions && (
                    <React.Fragment>{configuredBatchActions}</React.Fragment>
                  )}
                  {searcheable && (
                    <TableToolbarSearch onChange={onSearchInputChange} />
                  )}
                  {topButton && <TableAddNew>{topButton}</TableAddNew>}
                </TableToolbar>
                <StyledTable
                  className={`${tableResponsive ? 'table-responsive' : ''} ${
                    tableExpanded ? 'table-expanded' : ''
                  }
                  `}
                >
                  <TableHead>
                    <TableRow>
                      {isExpanded ? <TableExpandHeader /> : null}
                      {batchActions && (
                        <TableSelectAll {...renderProps.getSelectionProps()} />
                      )}
                      {renderProps.headers.map(header => (
                        <TableHeader
                          {...renderProps.getHeaderProps({
                            header,
                            children: header,
                            isSortable: false,
                            isSortHeader: false,
                            onClick: onSortParamsChange,
                          })}
                        >
                          {header.header}
                        </TableHeader>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {renderProps.rows.map(row => (
                      <React.Fragment key={row.id}>
                        {isExpanded ? (
                          <TableExpandRow
                            {...renderProps.getRowProps({ row })}
                            onExpand={() => {
                              renderProps.rows
                                .filter(r => r.isExpanded && r.id !== row.id)
                                .forEach(r => renderProps.expandRow(r.id));
                              renderProps.expandRow(row.id);
                            }}
                          >
                            {batchActions && (
                              <TableSelectRow
                                {...renderProps.getSelectionProps({ row })}
                              />
                            )}
                            {row.cells.map(
                              cellRendering ||
                                (cell => (
                                  <TableCell key={cell.id}>
                                    {cell.value}
                                  </TableCell>
                                )),
                            )}
                          </TableExpandRow>
                        ) : (
                          <TableRow {...renderProps.getRowProps({ row })}>
                            {batchActions && (
                              <TableSelectRow
                                {...renderProps.getSelectionProps({ row })}
                              />
                            )}
                            {row.cells.map(
                              cellRendering ||
                                (cell => (
                                  <TableCell key={cell.id}>
                                    {cell.value}
                                  </TableCell>
                                )),
                            )}
                          </TableRow>
                        )}
                        {row.isExpanded && (
                          <TableExpandedRow
                            colSpan={renderProps.headers.length + 3}
                          >
                            {editForm && (
                              <React.Fragment>
                                {React.cloneElement(editForm, {
                                  data: data.filter(d => d.id === row.id)[0],
                                })}
                              </React.Fragment>
                            )}
                          </TableExpandedRow>
                        )}
                      </React.Fragment>
                    ))}
                  </TableBody>
                </StyledTable>
                {paginationProps && (
                  <Pagination
                    {...paginationProps(pageDetails)}
                    onChange={onChange}
                  />
                )}
              </StyledTableContainer>
            );
          }}
          isSortable
        />
      )
    );
  }
}

export default BaseTable;
