import React from 'react';
import styled from 'styled-components';
import { Tabs, Tab } from 'carbon-components-react';
import LoginsTabs from './LoginsTabs';
import '../users/section-tabs.scss';
import AlchemyNotification from '../../components/form/AlchemyNotification';
import VisitsTabs from './VisitsTabs';

const tabProps = {
  tabs: {
    selected: 0,
    triggerHref: '#',
    role: 'user-list',
  },
  tab: {
    href: '#',
    role: 'groups',
    tabIndex: 0,
  },
};

const Content = styled.div`
  overflow-x: hidden;
`;

const TitleModule = styled.h3`
  margin: 1rem 3rem;
  font-size: 1.5rem;
  font-weight: bold;
`;

const UserManagementTabs = () => (
  <Content>
    <TitleModule>Audit</TitleModule>
    <React.Fragment>
      <AlchemyNotification />
      <div className="bx--row bx--tabs-header">
        <div className="bx--col bx--no-gutter">
          <Tabs {...tabProps.tabs}>
            <Tab {...tabProps.tab} label="Autentificari">
              <LoginsTabs />
            </Tab>
            <Tab {...tabProps.tab} label="Accesari">
              <VisitsTabs />
            </Tab>
          </Tabs>
        </div>
      </div>
    </React.Fragment>
  </Content>
);

export default UserManagementTabs;
