import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Button } from 'carbon-components-react';

import PropTypes from 'prop-types';
import { faStethoscope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { $brand01, $ui01, $ui05 } from '../themeVariables';
import { $ui01 } from '../themeVariables';
import { userActions } from '../redux/actions';
import AlchemyNotification from '../components/form/AlchemyNotification';
import { history } from '../helpers/history';

const LoginComponent = styled.div`
  display: block;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: #fff;
`;

// const FormHeader = styled.div`
//   margin-bottom: 2.5rem;
// `;

const Headline = styled.h1`
  font-size: 2.625rem;
  position: relative;
  display: block;
  margin-bottom: 2rem;
  font-weight: 500;
`;
const LoginForm = styled.form`
  /* background: ${$ui01}; */
  border-radius: 0.2rem;
  padding: 1rem 1.5rem;
  width: 12rem;
  margin: 0 auto;
`;

const Content = styled.div`
  max-width: 1200px;
  background: ${$ui01};
  padding: 1.5rem;
  border-radius: 0.2rem;
`;

// const FormTitle = styled.h4`
//   color: #171717;

//   font-size: 1.25rem;
//   font-weight: 500;
//   letter-spacing: 0;
//   margin-bottom: 0.5rem;
// `;

// const FormSubtitle = styled.p`
//   color: #616161;
// `;

// const InputGroup = styled.div`
//   margin-bottom: 1.3rem;
//   text-align: left;
// `;

// const Label = styled.label`
//   font-size: 0.75rem;
// `;

// const Input = styled.input`
//   font-size: 0.875rem;
//   font-weight: 400;
//   width: 100%;
//   background: ${$ui01};
//   padding: 0 1rem;
//   margin: 8px 0;
//   box-sizing: border-box;
//   border: 0.2rem solid transparent;
//   -webkit-transition: 0.1s;
//   transition: 0.1s;
//   outline: 2px solid #e0e0e0;
//   outline-offset: -2px;
//   background: #fff;
//   height: 2.5rem;

//   &::placeholder {
//     color: #8c8c8c;
//   }
//   &:focus {
//     color: ${$ui05};
//     outline: 2px solid ${$brand01};
//   }
// `;

class LoginNewPage extends React.Component {
  static propTypes = {
    // alert: PropTypes.instanceOf(Object),
    login: PropTypes.instanceOf(Function).isRequired,
  };

  static defaultProps = {
    // alert: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
    };
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  moveToChangePassword = () => {
    history.push('/schimbare-parola-expirata');
  };

  handleSubmit = e => {
    e.preventDefault();
    const { username, password } = this.state;
    const { login } = this.props;
    if (username && password) {
      login(username, password);
    }
  };

  render() {
    // const { alert } = this.props;
    // const { username, password } = this.state;
    return (
      <LoginComponent>
        <AlchemyNotification />
        <Content>
          <Headline>
            <FontAwesomeIcon icon={faStethoscope} />
            Portalul certificatelor de concediu medical
          </Headline>
          <LoginForm onSubmit={this.handleSubmit}>
            {/* <FormHeader>
            <FormTitle>Autentificare</FormTitle>
            <FormSubtitle>
              Introduceti numele de utilizator si parola
            </FormSubtitle>
            <div>{alert.message}</div>
          </FormHeader>
          <InputGroup>
            <Label>Utilizator</Label>
            <Input
              type="text"
              name="username"
              value={username}
              onChange={this.handleChange}
            />
          </InputGroup>
          <InputGroup>
            <Label>Parola</Label>
            <Input
              type="password"
              name="password"
              value={password}
              onChange={this.handleChange}
            />
          </InputGroup>
          <Button kind="primary" type="submit">
            Autentificare
          </Button>
          <Button kind="ghost" onClick={this.moveToChangePassword}>
            Schimbare parola
          </Button> */}
            <Button kind="primary" type="submit">
              Autentificare
            </Button>
          </LoginForm>
        </Content>
      </LoginComponent>
    );
  }
}

export default connect(
  state => {
    const { loggingIn } = state.authentication;
    const { alert } = state;
    return {
      loggingIn,
      alert,
    };
  },
  {
    login: userActions.login,
    logout: userActions.logout,
  },
)(LoginNewPage);
