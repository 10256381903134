import React from 'react';
import { Select, SelectItem } from 'carbon-components-react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

const Content = styled.div`
  padding: 6rem 3rem 2rem 3rem;
`;

const Title = styled.h3`
  font-size: 1.25rem;
  margin-bottom: 3.375rem;
`;

class BlankPage extends React.PureComponent {
  render() {
    return (
      <Content>
        <Title>Primirea blanchetelor</Title>
        <Select
          light
          id="tipulPrimirii"
          defaultValue="defaultSelectedItem"
          labelText="Tipul primirii"
          onChange={e => {
            // eslint-disable-next-line react/prop-types
            const { history } = this.props;
            history.push(`/blanks-management/${e.target.value}`);
          }}
        >
          <SelectItem
            value="defaultSelectedItem"
            text="Tipul primirii"
            disabled
            hidden
          />
          <SelectItem value="new-blanks" text="Blanchete nou aduse" />
          <SelectItem value="returned-blanks" text="Blanchete returnate" />
        </Select>
      </Content>
    );
  }
}

export default withRouter(BlankPage);
