/* eslint-disable eqeqeq */
/* eslint-disable react/prop-types */
import decode from 'jwt-decode';
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { Button, Form, DataTable } from 'carbon-components-react';
import Download16 from '@carbon/icons-react/es/download/16';
import Search16 from '@carbon/icons-react/es/search/16';
import { Field, reduxForm } from 'redux-form';
import { alertActions, concediiActions } from '../../redux/actions';
import AlchemyDateTimePicker from '../../components/form/AlchemyDateTimePicker';
import AlchemyTextInput from '../../components/form/AlchemyTextInput';
import BaseTable from '../../components/table/BaseTable';
import '../style.scss';
import AlchemyNotification from '../../components/form/AlchemyNotification';
import * as validation from '../../helpers/validation';

const Content = styled.div`
  overflow-x: hidden;
  background: #f3f3f3;
`;

const TitleModule = styled.h3`
  padding: 1rem 10rem;
  background: #f3f3f3;
  font-size: 1.75rem;
  @media (max-width: 1100px) {
    padding: 1rem;
  }
`;

const headers = [
  { code: 'id', description: 'ID' },
  { code: 'typeDescr', description: 'Tip certificat' },
  { code: 'categDescr', description: 'Categorie certificat' },
  { code: 'subcategDescr', description: 'Subcategorie certificat' },
  { code: 'seriesNr', description: 'Serie si numar certificat' },
  { code: 'period', description: 'Perioada acordata' },
  { code: 'diffDays', description: 'Durata zile' },
  { code: 'statusDescr', description: 'Statut' },
  {
    code: 'prescribedDescr',
    description: 'Decizie la inchidere certificat',
  },
  { code: 'institDescr', description: 'Institutie' },
  { code: 'validator', description: 'Validator (nume, tel)' },
  { code: 'transmissionType', description: 'Transmis CNAS (da/nu)' },
];

// eslint-disable-next-line react/prop-types
class FisaBeneficiarForm extends React.PureComponent {
  componentDidMount() {
    this.myRef = React.forwardRef();
    this.form = React.forwardRef();
  }

  validateStatus = () => {
    const { valid, reduxFormData } = this.props;
    if (valid) {
      const { getFisaBeneficiar } = this.props;
      getFisaBeneficiar(
        reduxFormData.idnp,
        reduxFormData.startDate,
        reduxFormData.endDate,
      );
    }
  };

  downloadFile = () => {
    const { valid, reduxFormData } = this.props;
    if (valid) {
      const { generateFisaBeneficiar } = this.props;
      generateFisaBeneficiar(
        reduxFormData.idnp,
        reduxFormData.startDate,
        reduxFormData.endDate,
      );
    }
  };

  getDateWithoutTime = value => {
    if (value) {
      const valueDate = new Date(value);
      valueDate.setHours(0, 0, 0, 0);
      return valueDate.toLocaleString().substring(0, 10);
    }
    return '';
  };

  loadRecorders = event => {
    if (event) {
      const { getUsersByInstitutionId } = this.props;
      getUsersByInstitutionId(event);
    }
  };

  render() {
    const { handleSubmit, reduxFormData, fisaBeneficiarDetails } = this.props;

    return (
      <React.Fragment>
        <Content>
          <TitleModule>Fișă beneficiar</TitleModule>
          <AlchemyNotification />
          {
            <Form
              onSubmit={handleSubmit(() => {
                return this.validateStatus();
              })}
            >
              <div className="box__quarter-block">
                <React.Fragment>
                  <Field
                    name="idnp"
                    type="text"
                    label="IDNP/CPAS"
                    component={AlchemyTextInput}
                    validate={validation.required}
                  />
                  <Field
                    name="startDate"
                    labelText="De la"
                    component={AlchemyDateTimePicker}
                    showTimeSelect={false}
                    validate={validation.required}
                    maxDate={get(reduxFormData, 'endDate', null)}
                  />
                  <Field
                    name="endDate"
                    labelText="Pina la"
                    component={AlchemyDateTimePicker}
                    showTimeSelect={false}
                    validate={validation.required}
                    minDate={get(reduxFormData, 'startDate', null)}
                  />
                  <div className="box__quarter-block reset-container">
                    <div className="box__triple-block">
                      <Button
                        component="topButton"
                        kind="primary"
                        type="submit"
                      >
                        Cautare
                        <Search16 className="bx--btn__icon" />
                      </Button>
                    </div>
                  </div>
                </React.Fragment>
              </div>
              {fisaBeneficiarDetails && fisaBeneficiarDetails.length > 0 && (
                <React.Fragment>
                  <div>
                    <BaseTable
                      headers={headers}
                      rows={fisaBeneficiarDetails.map(el => ({
                        ...el,
                        id: el.id.toString(),
                      }))}
                      cellRendering={cell => {
                        if (
                          cell.id.includes(':sendDate') ||
                          cell.id.includes(':startDate') ||
                          cell.id.includes(':endDate')
                        ) {
                          return (
                            <DataTable.TableCell key={cell.id}>
                              {this.getDateWithoutTime(cell.value)}
                            </DataTable.TableCell>
                          );
                        }
                        return (
                          <DataTable.TableCell key={cell.id}>
                            {cell.value}
                          </DataTable.TableCell>
                        );
                      }}
                    />
                  </div>
                  <div className="box__quarter-block reset-container">
                    <div className="box__triple-block">
                      <Button
                        component="topButton"
                        kind="primary"
                        onClick={() => this.downloadFile()}
                      >
                        Descarca
                        <Download16 className="bx--btn__icon" />
                      </Button>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </Form>
          }
        </Content>
      </React.Fragment>
    );
  }
}

const ReduxForm = reduxForm({
  form: 'concediuForm',
  enableReinitialize: true,
})(FisaBeneficiarForm);

export default connect(
  state => {
    const { taxonomies, concedii, form } = state;
    const {
      requestedConcediu,
      totalDays,
      continuousPeriod,
      loading,
    } = concedii;
    const { concediuForm } = form;
    const { values, valid } = concediuForm || {};
    const tokens = decode(sessionStorage.getItem('token'));

    return {
      fisaBeneficiarDetails: concedii.fisaBeneficiarDetails,
      taxonomies: taxonomies.taxonomies,
      serverDate: taxonomies.serverDate,
      initialValues: {
        certificationPlace: { id: 1 },
        ...values,
        ...requestedConcediu,
        isChild:
          (requestedConcediu && requestedConcediu.childDetails) ||
          (values && values.isChild),
        username: `${tokens.lastname} ${tokens.firstname}`,
        institutionName: `${tokens.institutionName}`,
        phoneNumberToken: `${tokens.phoneNumber}`,
        totalDays: totalDays + (values && values.grantedDays),
        continuousPeriod,
      },
      reduxFormData: values,
      formValid: valid,
      loading,
    };
  },
  {
    showError: alertActions.error,
    generateFisaBeneficiar: concediiActions.generateFisaBeneficiar,
    getFisaBeneficiar: concediiActions.getFisaBeneficiar,
  },
)(ReduxForm);
