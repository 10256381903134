/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import styled from 'styled-components';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { DataTable } from 'carbon-components-react';
import WarningFilled20 from '@carbon/icons-react/es/warning--filled/20';
import AlchemyTextInput from '../components/form/AlchemyTextInput';
import BaseTable from '../components/table/BaseTable';
import './profile-page.scss';
import { concediiActions } from '../redux/actions';
import * as validation from '../helpers/validation';

const TitleModule = styled.h3`
  margin: 1rem 3rem;
  font-size: 1.5rem;
  font-weight: bold;
`;

const PTButton = styled.div`
  padding-top: 1rem;
`;

const AlertConcediu = styled.div`
  border-left: 2.5px solid #da1e28;
  padding-bottom: 5px;
  padding-top: 5px;
  display: grid;
  grid-template-columns: 2rem 1fr;
  max-width: 100%;
  column-gap: 1rem;
  background: #f3f3f3;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
`;

const AlertIcon = styled.div`
  padding-left: 1rem;
`;

const AlertMessage = styled.p`
  color: #da1e28;
  font-size: 1rem;
`;

const WarningFilled20Icon = styled(WarningFilled20)`
  fill: #da1e28;
`;

const headers = [
  { code: 'nrCertificate', description: 'Seria și numarul certificatului' },
  { code: 'dateCompletion', description: 'Data completării' },
  { code: 'startDate', description: 'De la' },
  { code: 'endDate', description: 'Pina la' },
  { code: 'type', description: 'Tip certificat' },
  { code: 'category', description: 'Categorie certificat' },
  { code: 'statut', description: 'Statut certificat' },
  { code: 'institution', description: 'Institutia' },
  { code: 'phoneNumberValidator', description: 'Date de contact' },
];

class CheckCertificateStatus extends Component {
  getDateWithoutTime = value => {
    if (value) {
      const valueDate = new Date(value);
      valueDate.setHours(0, 0, 0, 0);
      return valueDate.toLocaleString().substring(0, 10);
    }
    return '';
  };

  render() {
    const { getPublicHistory, reduxFormData, respHistory } = this.props;

    return (
      <>
        <TitleModule>
          Verificare statut certificat de concediu medical
        </TitleModule>
        <div className="new-container">
          <div className="box__triple-block">
            <Field
              name="idnp"
              label="IDNP/CPAS"
              component={AlchemyTextInput}
              validate={validation.required}
            />
            <PTButton>
              <button
                tabIndex={0}
                className="some-class bx--btn bx--btn--tertiary bx--btn--sm bx--btn--sm-2"
                type="button"
                onClick={() => getPublicHistory(reduxFormData.idnp)}
                disabled={!reduxFormData || !reduxFormData.idnp}
              >
                Cautare
              </button>
            </PTButton>
          </div>

          {respHistory && (
            <div style={{ overflowY: 'scroll', height: '80%' }}>
              <BaseTable
                title="Certificate de concediu medical"
                headers={headers}
                rows={respHistory.map(el => ({
                  ...el,
                  id: el.id.toString(),
                }))}
                cellRendering={cell => {
                  if (
                    cell.id.includes(':dateCompletion') ||
                    cell.id.includes(':startDate') ||
                    cell.id.includes(':endDate')
                  ) {
                    return (
                      <DataTable.TableCell key={cell.id}>
                        {this.getDateWithoutTime(cell.value)}
                      </DataTable.TableCell>
                    );
                  }
                  return (
                    <DataTable.TableCell key={cell.id}>
                      {cell.value}
                    </DataTable.TableCell>
                  );
                }}
              />
            </div>
          )}
          {respHistory && (
            <AlertConcediu>
              <AlertIcon>
                <WarningFilled20Icon />
              </AlertIcon>
              <AlertMessage>
                În cazul în care dețineți certificat eliberat pe format de
                hîrtie și acesta este lipsă în lista de mai sus, contactați
                instituția care a eliberat certificatul pentru a fi introdus în
                sistemul informațional
              </AlertMessage>
            </AlertConcediu>
          )}
        </div>
      </>
    );
  }
}

const ReduxForm = reduxForm({
  form: 'checkCertificateStatusForm',
})(CheckCertificateStatus);

export default connect(
  state => {
    const { form, concedii } = state;
    const { respHistory } = concedii;
    const { checkCertificateStatusForm } = form;
    const { values, valid } = checkCertificateStatusForm || {};
    return {
      reduxFormData: values,
      formValid: valid,
      respHistory,
    };
  },
  {
    getPublicHistory: concediiActions.getPublicHistory,
  },
)(ReduxForm);
