import decode from 'jwt-decode';
import React, { Component } from 'react';
import styled from 'styled-components';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Form, Link } from 'carbon-components-react';
import AlchemyTextInput from '../components/form/AlchemyTextInput';
import { groupActions, userActions } from '../redux/actions';
import './profile-page.scss';
import avatar from '../images/avatar.jpg';

const TitleModule = styled.h3`
  margin: 1rem 3rem;
  font-size: 1.5rem;
  font-weight: bold;
`;

const MarginTop = styled(Link)`
  margin-top: 0.375rem;
`;

class ProfilePage extends Component {
  state = {};

  render() {
    return (
      <div>
        <TitleModule>Profil utilizator</TitleModule>
        <React.Fragment>
          <Form className="profile-user-form">
            <div className="profile-user-form__form-container">
              <div className="profile-user-form__profile-data">
                <div className="profile-user-form__profile-info-container">
                  <div className="profile-user-form__profile-info-value">
                    <Field
                      name="userName"
                      type="text"
                      component={AlchemyTextInput}
                      label="Nume utilizator"
                      disabled
                    />
                  </div>
                </div>
                <div className="profile-user-form__profile-info-container">
                  <div className="profile-user-form__profile-info-value">
                    <Field
                      name="firstName"
                      type="text"
                      component={AlchemyTextInput}
                      label="Nume"
                      disabled
                    />
                  </div>
                </div>
                <div className="profile-user-form__profile-info-container">
                  <div className="profile-user-form__profile-info-value">
                    <Field
                      name="lastName"
                      type="text"
                      component={AlchemyTextInput}
                      label="Prenume"
                      disabled
                    />
                  </div>
                </div>
                <div className="profile-user-form__profile-info-container">
                  <div className="profile-user-form__profile-info-value">
                    <Field
                      name="email"
                      type="email"
                      component={AlchemyTextInput}
                      label="Email"
                      disabled
                    />
                  </div>
                </div>
                <div className="profile-user-form__profile-info-container">
                  <div className="profile-user-form__profile-info-value">
                    <Field
                      name="phoneNumber"
                      type="text"
                      component={AlchemyTextInput}
                      label="Telefon"
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="profile-user-form__profile-additional-data">
                <MarginTop className="profile-user-form__profile-info-container">
                  <div className="profile-info__profile-picture-container">
                    <img
                      className="profile-info__avatar"
                      src={avatar}
                      alt="profile-avatar"
                    />
                  </div>
                  {/* <MarginTop className="profile-user-form__profile-info-value">
                    <NavLink className="profile-link" to="/schimbare-parola">
                      Schimbare parola
                    </NavLink>
                  </MarginTop> */}
                </MarginTop>
              </div>
              {/* <div className="profile-user-form__save-bar">
                <button
                  className="bx--btn bx--btn--primary"
                  type="button"
                  disabled
                >
                  Salveaza
                </button>
              </div> */}
            </div>
          </Form>
        </React.Fragment>
      </div>
    );
  }
}

const ReduxForm = reduxForm({
  form: 'ProfilePageForm',
  enableReinitialize: true,
})(ProfilePage);

export default connect(
  state => {
    const { groups, taxonomies } = state;
    const tokens = decode(sessionStorage.getItem('token'));
    return {
      groups: groups && groups.groups ? groups.groups : [],
      initialValues: {
        active: false,
        roles: [],
        userName: tokens.username,
        email: tokens.email,
        firstName: tokens.firstname,
        lastName: tokens.lastname,
        phoneNumber: tokens.phoneNumber,
      },
      taxonomies: taxonomies.taxonomies,
    };
  },
  {
    getGroups: groupActions.getGroups,
    ProfilePageReduxForm: userActions.createUserReduxForm,
    getPage: userActions.getPage,
  },
)(ReduxForm);
