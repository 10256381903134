import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { faStethoscope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { history } from '../helpers';
import { userActions } from '../redux/actions';

const LoginComponent = styled.div`
  width: 100%;
  height: 35vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: #fff;
`;

const Headline = styled.h1`
  font-size: 2.625rem;
  position: relative;
  display: block;
  margin-bottom: 2rem;
  font-weight: 500;
`;

const LoginButton = styled.button`
  padding: calc(0.875rem - 3px) 60px calc(0.875rem - 3px) 60px;
`;

const Support = styled.div`
  position: absolute;
  bottom: 3rem;
  left: 50%;
  transform: translateX(-50%);
  padding: 1rem 2rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
  border-left: 3px solid #fb4b53;
  background: #3d3d3d;
`;

const SupportText = styled.span`
  font-weight: bold;
  color: #fff;
`;

const SupportLink = styled.a`
  margin-left: 0.375rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.16px;
  color: #fb4b53;
  text-decoration: none;
  outline: none;
  -webkit-transition: 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  :hover {
    color: #da1e28;
  }
  :active {
    color: #ba1b23;
  }
  :focus {
    color: #da1e28;
  }
`;

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    return (
      <React.Fragment>
        <LoginComponent>
          <Headline>Statutul certificatului medical</Headline>
          <LoginButton
            className="bx--btn bx--btn--secondary"
            type="button"
            onClick={() => {
              history.push('/check-certificate-status');
            }}
          >
            Verificare
          </LoginButton>
        </LoginComponent>

        <LoginComponent>
          <Headline>
            <FontAwesomeIcon icon={faStethoscope} />
            Portalul certificatelor de concediu medical
          </Headline>

          <LoginButton
            className="bx--btn bx--btn--secondary"
            type="button"
            onClick={() => {
              setTimeout(() => {
                window.location.href = `${
                  process.env.REACT_APP_API_BASE_URL
                }/login-protected`;
              }, 10);
              // setTimeout(() => {
              //   window.location.href = `${
              //     process.env.REACT_APP_API_BASE_URL
              //   }/login-non-protected`;
              // }, 10);
            }}
          >
            Autentificare
          </LoginButton>
          <Support>
            <SupportText>Adresa serviciului de suport:</SupportText>
            <SupportLink href="mailto:support.ecertificat@ms.gov.md">
              support.ecertificat@ms.gov.md
            </SupportLink>
          </Support>
        </LoginComponent>
      </React.Fragment>
    );
  }
}

export default connect(
  state => {
    const { loggingIn } = state.authentication;
    const { alert } = state;
    return {
      loggingIn,
      alert,
    };
  },
  {
    login: userActions.login,
    logout: userActions.logout,
    samlAuthenticate: userActions.samlAuthenticate,
  },
)(LoginPage);
