/* eslint-disable react/prop-types */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import { Select, SelectItem } from 'carbon-components-react';

class AlchemySelect extends React.PureComponent {
  render() {
    const {
      input,
      options,
      text,
      labelText,
      meta: { touched, error, warning },
      disabled,
      parentTaxonomy,
      parentIdField,
      parentId,
      className,
      filterByParentId,
    } = this.props;

    let filteredOptions = options;

    // this is for parent->child linking in non-FieldArray situations
    if (parentTaxonomy) {
      filteredOptions = options.filter(
        // eslint-disable-next-line eqeqeq
        option => option[parentIdField] == parentId,
      );

      // this is for parent->child linking in FieldArrays
    } else if (filterByParentId) {
      filteredOptions = options.filter(
        // eslint-disable-next-line eqeqeq
        option => option[parentIdField] == filterByParentId,
      );
    }
    return (
      <React.Fragment>
        <div className={className}>
          <Select
            className="bx--select--invalid"
            {...input}
            invalid={!!(touched && error)}
            labelText={labelText || ''}
            value={input.value ? input.value : ''}
            onChange={event => input.onChange(event.target.value)}
            disabled={disabled}
          >
            <SelectItem value="" text={text} />
            {filteredOptions &&
              filteredOptions.map(option => (
                <SelectItem value={option.id} text={option.description} />
              ))}
          </Select>

          {(touched &&
            (error && (
              <div className="bx--form-requirement bx--form-requirement--show-always">
                {error}
              </div>
            ))) ||
            (touched &&
              (warning && (
                <div className="bx--form-requirement bx--form-requirement--show-always">
                  {warning}
                </div>
              )))}
        </div>
      </React.Fragment>
    );
  }
}

export default AlchemySelect;
