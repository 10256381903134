/* eslint-disable react/prop-types */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import './alchemy-date-time-picker.scss';
import ro from 'date-fns/locale/ro';

registerLocale('ro', ro);

class AlchemyDateTimePicker extends React.PureComponent {
  handleChange = (date, input) => {
    if (!date || date instanceof Date) {
      return input.onChange(date);
    }
    return input.onChange(new Date(date));
  };

  formatDate = inputValue => {
    return inputValue ? new Date(inputValue) : inputValue;
  };

  render() {
    const {
      input,
      showTimeSelect,
      labelText,
      meta: { touched, error, warning },
      minDate,
      maxDate,
      disabled,
    } = this.props;
    return (
      <div className="date">
        <label className="bx--label">{labelText}</label>
        <DatePicker
          id={input.name}
          showTimeSelect={showTimeSelect}
          locale={ro}
          selected={this.formatDate(input.value)}
          timeCaption="Ora"
          timeFormat="HH:mm"
          minDate={minDate}
          maxDate={maxDate}
          dateFormat={showTimeSelect ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy'}
          onChange={date => this.handleChange(date, input)}
          className={touched && error ? 'react-datepicker-invalid' : null}
          isClearable={!disabled}
          disabled={disabled}
          autoComplete="off"
          showYearDropdown
          changeYear="true"
          yearRange="-70:+0"
          scrollableYearDropdown
        />
        {(touched &&
          (error && (
            <div className="bx--form-requirement bx--form-requirement--show-always">
              {error}
            </div>
          ))) ||
          (touched &&
            (warning && (
              <div className="bx--form-requirement bx--form-requirement--show-always">
                {warning}
              </div>
            )))}
      </div>
    );
  }
}

export default AlchemyDateTimePicker;
