/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import styled from 'styled-components';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Form, Link } from 'carbon-components-react';
import AlchemyTextInput from '../components/form/AlchemyTextInput';
import './profile-page.scss';
import avatar from '../images/avatar.jpg';
import { userActions } from '../redux/actions';
import { history } from '../helpers/history';
import * as validation from '../helpers/validation';
import AlchemyNotification from '../components/form/AlchemyNotification';

const TitleModule = styled.h3`
  margin: 1rem 3rem;
  font-size: 1.5rem;
  font-weight: bold;
`;

const MarginTop = styled(Link)`
  margin-top: 0.375rem;
`;

class ChangePassword extends Component {
  render() {
    const { changePassword, submitting, handleSubmit } = this.props;
    return (
      <div>
        <TitleModule>Schimbare parola</TitleModule>
        <React.Fragment>
          <AlchemyNotification />
          <Form
            className="profile-user-form"
            onSubmit={handleSubmit(() => {
              return changePassword();
            })}
          >
            <div className="profile-user-form__form-container">
              <div className="profile-user-form__profile-data">
                <div className="profile-user-form__profile-info-container">
                  <div className="profile-user-form__profile-info-value">
                    <Field
                      name="oldPassword"
                      type="password"
                      component={AlchemyTextInput}
                      label="Parola veche"
                      validate={validation.required}
                    />
                  </div>
                </div>
                <div className="profile-user-form__profile-info-container">
                  <div className="profile-user-form__profile-info-value">
                    <Field
                      name="newPassword"
                      type="password"
                      component={AlchemyTextInput}
                      label="Parola noua"
                      validate={[
                        validation.required,
                        validation.passwordNumber,
                        validation.passwordStandart,
                        validation.passwordUpperCase,
                        validation.minValue8,
                      ]}
                    />
                  </div>
                </div>
                <div className="profile-user-form__profile-info-container">
                  <div className="profile-user-form__profile-info-value">
                    <Field
                      name="confirmNewPassword"
                      type="password"
                      component={AlchemyTextInput}
                      label="Confirma parola noua"
                      validate={validation.required}
                    />
                  </div>
                </div>
              </div>
              <div className="profile-user-form__profile-additional-data">
                <MarginTop className="profile-user-form__profile-info-container">
                  <div className="profile-info__profile-picture-container">
                    <img
                      className="profile-info__avatar"
                      src={avatar}
                      alt="profile-avatar"
                    />
                  </div>
                  <MarginTop className="profile-user-form__profile-info-value" />
                </MarginTop>
              </div>
              <div className="profile-user-form__save-bar">
                <button
                  className="bx--btn bx--btn--secondary"
                  type="button"
                  onClick={() => {
                    history.push('/profil-utilizator');
                  }}
                >
                  Anuleaza
                </button>
                <button
                  className="bx--btn bx--btn--primary"
                  type="submit"
                  disabled={submitting}
                >
                  Salveaza
                </button>
              </div>
            </div>
          </Form>
        </React.Fragment>
      </div>
    );
  }
}

const ReduxForm = reduxForm({
  form: 'changePasswordForm',
})(ChangePassword);

const selector = formValueSelector('changePasswordForm');

export default connect(
  state => {
    return {
      formData: {
        oldPassword: selector(state, 'oldPassword'),
        newPassword: selector(state, 'newPassword'),
        confirmNewPassword: selector(state, 'confirmNewPassword'),
      },
    };
  },
  {
    changePassword: userActions.changePassword,
  },
)(ReduxForm);
