import { createBrowserHistory } from 'history';
import { store } from '../redux/store';
import { ConcediiActions } from '../redux/types';

const history = createBrowserHistory();

history.listen(location => {
  if (location.pathname === '/concediu-nou') {
    store.dispatch({ type: ConcediiActions.CLEAR_STATE });
  }
});

export { history };
