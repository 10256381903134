/* eslint-disable */
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { groupActions } from '../../../redux/actions';
import { authorityActions } from '../../../redux/actions';
import {
  Form,
  TextInput,
  TextArea,
  FormGroup,
  Checkbox,
} from 'carbon-components-react';

const CreateGroupForm = ({ authorities, visible }) => {
  return visible ? (
    <Form>
      <TextInput id="code" placeholder="Code" labelText="Code" />
      <TextArea
        id="description"
        placeholder="Description"
        labelText="Description"
      />
      {/* <FormGroup legendText="Authorities">
        {authorities.map(authority => (
          <Checkbox id={authority.id} labelText={authority.code} />
        ))}
      </FormGroup> */}
    </Form>
  ) : null;
};

const CreateGroupRedux = reduxForm({
  form: 'editGroupForm', // a unique identifier for this form
})(CreateGroupForm);

export default connect(
  state => {
    const { authorities } = state;
    if (authorities && authorities.authorities) {
      return {
        authorities: authorities.authorities,
      };
    }
    return {
      authorities: [],
    };
  },
  {
    // getAuthorities: authorityActions.getAuthorities,
  },
)(CreateGroupRedux);
