const required = value => (value ? undefined : 'Completare obligatorie');
const maxLength = max => value =>
  value && value.length > max
    ? `Trebuie sa contina maximum ${max} caractere`
    : undefined;
const minValue = min => value =>
  value && value.length < min
    ? `Trebuie sa contina min ${min} simboluri `
    : undefined;
const maxLength2 = maxLength(2);
const maxLength8 = maxLength(8);
const maxLength13 = maxLength(13);
const maxLength20 = maxLength(20);
const maxLength25 = maxLength(25);
const maxLength40 = maxLength(40);
const minValue13 = minValue(13);
const minValue8 = minValue(8);
const minLength = min => value =>
  value && value.length < min
    ? `Trebuie sa contina minimum ${min} caractere`
    : undefined;
const minLength11 = minLength(11);
const minLength2 = minLength(2);
const strictLength = min => value =>
  value && value.length !== min
    ? `Trebuie sa contina exact 13 caractere`
    : undefined;
const strict13 = strictLength(13);
const number = value =>
  // eslint-disable-next-line no-restricted-globals
  value && isNaN(Number(value)) ? 'Introduceti doar numere' : undefined;

const minValue18 = minValue(18);
const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined;
const tooOld = value =>
  value && value > 65 ? 'You might be too old for this' : undefined;
const aol = value =>
  value && /.+@aol\.com/.test(value)
    ? 'Really? You still use AOL for your email?'
    : undefined;
const tooYoung = value =>
  value && value < 13
    ? 'You do not meet the minimum age requirement!'
    : undefined;
const tooOldChild = value =>
  value && value > 19 ? 'You might be too old for this' : undefined;
const phoneNumber = value =>
  value && !/^(0|[0-9]{9})$/i.test(value)
    ? 'Numarul de telefon trebuie sa contina 9 cifre'
    : undefined;
const meteo = value =>
  value && !/^[0-9.+-]/i.test(value)
    ? 'Introduceti cifre, si urmatoarele semne (+-.)'
    : undefined;
const alphabet = value =>
  value && !/^[a-zA-Z\s]+$/.test(value)
    ? 'Introduceti doar litere alfabetice'
    : undefined;
const geoCoordinates = value =>
  value && !/^[0-9.A-Z]/i.test(value) ? 'Introduceti cifre' : undefined;
const idnp = value =>
  value &&
  ((typeof value === 'string' && value.includes(' ')) ||
    !Number.isInteger(Number(value)) ||
    // eslint-disable-next-line no-restricted-globals
    isNaN(Number(value)))
    ? 'Introduceti IDNP'
    : undefined;
const passwordStandart = value =>
  value &&
  !/^(?=.*[a-z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{1,30}$/i.test(value)
    ? 'Introduceti in parola simboluri specifice'
    : undefined;
const passwordNumber = value =>
  value && !/[0-9]/i.test(value) ? 'Introduceti in parola cifre' : undefined;

const passwordUpperCase = value =>
  value && !/[A-Z]/g.test(value)
    ? 'Introduceti minim o litera alfabetica majuscula'
    : undefined;

export {
  required,
  maxLength,
  number,
  minValue,
  minValue18,
  email,
  aol,
  minLength11,
  minLength2,
  tooYoung,
  tooOld,
  tooOldChild,
  phoneNumber,
  meteo,
  alphabet,
  strict13,
  geoCoordinates,
  maxLength2,
  maxLength8,
  maxLength13,
  maxLength20,
  maxLength25,
  maxLength40,
  idnp,
  minValue13,
  passwordStandart,
  passwordUpperCase,
  passwordNumber,
  minValue8,
};
