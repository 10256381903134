/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Form, Button, Checkbox } from 'carbon-components-react';
import { groupActions, userActions } from '../../../redux/actions';
import get from 'lodash/get';

import AlchemyTextInput from '../../../components/form/AlchemyTextInput';
import AlchemyCheckboxGroup from '../../../components/form/AlchemyCheckboxGroup';
import AlchemySelect from '../../../components/form/AlchemySelect';
import AlchemyCheckbox from '../../../components/form/AlchemyCheckbox';
import AlchemyDateTimePicker from '../../../components/form/AlchemyDateTimePicker';
import AlchemyAdvancedSelect from '../../../components/form/AlchemyAdvancedSelect';
import './create-user-form.scss';
import * as validation from '../../../helpers/validation';

class CreateUserForm extends React.PureComponent {
  componentDidMount() {
    const { getGroups } = this.props;
    getGroups();
  }

  saveUser = event => {
    const { createUserReduxForm, closeCreateUserForm } = this.props;
    createUserReduxForm();
    //closeCreateUserForm();
  };

  loadRecorders(event) {
    const { getRecordersUsersByInstitutionId } = this.props;
    getRecordersUsersByInstitutionId(event);
  }

  render() {
    const {
      groups,
      closeCreateUserForm,
      taxonomies,
      recorders,
      handleSubmit,
    } = this.props;

    return (
      <Form
        className="box white-background"
        onSubmit={handleSubmit(() => {
          return this.saveUser();
        })}
      >
        <h4 className="create-user-form__title-left">Adauga utilizator</h4>
        <div className="box__double-block">
          <div>
            <Field
              name="userName"
              type="text"
              component={AlchemyTextInput}
              label="Nume utilizator"
              validate={[validation.required]}
            />
            <Field
              name="firstName"
              type="text"
              component={AlchemyTextInput}
              label="Nume"
              validate={[validation.required]}
            />

            <Field
              name="lastName"
              type="text"
              component={AlchemyTextInput}
              label="Prenume"
              validate={[validation.required]}
            />

            <Field
              name="idnp"
              type="text"
              component={AlchemyTextInput}
              label="IDNP"
              validate={[
                validation.minValue13,
                validation.maxLength13,
                validation.required,
                validation.number,
              ]}
            />

            <Field
              name="email"
              type="email"
              component={AlchemyTextInput}
              label="Email"
              validate={(validation.email, validation.required)}
              warn={validation.aol}
            />

            {/* <Field
              name="password"
              type="password"
              component={AlchemyTextInput}
              label="Parola"
              validate={[
                validation.required,
                validation.passwordNumber,
                validation.passwordStandart,
                validation.passwordUpperCase,
                validation.minValue8,
              ]}
              warn={validation.aol}
            /> */}

            <Field
              name="phoneNumber"
              type="text"
              component={AlchemyTextInput}
              label="Telefon"
              validate={[validation.required, validation.phoneNumber]}
            />

            {/* <Field
              name="dateExpiration"
              labelText="Data expirare parola"
              component={AlchemyDateTimePicker}
              minDate={new Date()}
            /> */}

            {/* <Field
              name="active"
              component={AlchemyCheckbox}
              label="Activ"
              type="checkbox"
            /> */}
          </div>
          <div>
            <label>Roluri</label>

            <Field
              name="roles"
              component={AlchemyCheckboxGroup}
              options={groups.map(group => ({
                ...group,
                name: group.description,
              }))}
            />

            <Field
              name="institution.id"
              component={AlchemyAdvancedSelect}
              labelText="Institutia"
              options={taxonomies && taxonomies.NmInstitutions}
              validate={validation.required}
              onChange={event => this.loadRecorders(event)}
            />

            <Field
              name="recorders"
              component={AlchemyAdvancedSelect}
              isMulti={true}
              labelText="Registratori spre verificare"
              options={
                recorders &&
                recorders.map(usr => {
                  return {
                    id: usr.id,
                    description: usr.lastName + ' ' + usr.firstName,
                  };
                })
              }
            />
          </div>
        </div>
        <div className="create-user-form__save-bar">
          <button
            className="bx--btn bx--btn--secondary"
            type="button"
            onClick={closeCreateUserForm}
          >
            Anuleaza
          </button>
          <Button className="bx--btn bx--btn--primary" type="submit">
            Salveaza
          </Button>
        </div>
      </Form>
    );
  }
}

const ReduxForm = reduxForm({
  form: 'createUserForm',
  enableReinitialize: true,
})(CreateUserForm);

export default connect(
  state => {
    const { groups, taxonomies, users } = state;
    return {
      recorders: get(users, 'recorders', []),
      groups: get(groups, 'groups', []),
      initialValues: { active: false, roles: [] },
      taxonomies: taxonomies.taxonomies,
    };
  },
  {
    getGroups: groupActions.getGroups,
    createUserReduxForm: userActions.createUserReduxForm,
    getPage: userActions.getPage,
    getRecordersUsersByInstitutionId:
      userActions.getRecordersUsersByInstitutionId,
  },
)(ReduxForm);
