/* eslint-disable react/prop-types */
import React from 'react';

import { getNextSortDirection } from 'carbon-components-react/es/components/DataTable/state/sorting';

import { buildSearchPredicate } from '../../helpers/utils';

const withSearch = WrappedTable => {
  return class extends React.PureComponent {
    state = {
      direction: null,
      page: 1,
      pageSize: 10,
      orderBy: null,
      search: null,
      editing: false,
    };

    paginationProps = page => ({
      ...page,
      disabled: false,
      pagesUnknown: false,
      pageInputDisabled: false,
      backwardText: 'Previous page',
      forwardText: 'Next page',
      pageSizes: [10, 20, 50, 100],
      itemsPerPageText: 'Items per page:',
    });

    createSearchCriteria = () => {
      const { searchPrecondition, search } = this.props;
      let allSearchConditions = [...(search || [])];
      if (searchPrecondition) {
        if (searchPrecondition instanceof Array) {
          allSearchConditions = allSearchConditions.concat(searchPrecondition);
        } else {
          allSearchConditions.push(searchPrecondition);
        }
      }
      return allSearchConditions;
    };

    onSearchInputChange = event => {
      const { auditSearch } = this.state;
      const {
        searchFields,
        getPage,
        direction,
        entityName,
        searchPrecondition,
        pageDetails,
      } = this.props;
      const search =
        buildSearchPredicate(
          event.currentTarget ? event.currentTarget.value : '',
          searchFields,
        ) || [];

      let allSearchConditions = [...search];

      if (searchPrecondition) {
        if (searchPrecondition instanceof Array) {
          allSearchConditions = allSearchConditions.concat(searchPrecondition);
        } else {
          allSearchConditions.push(searchPrecondition);
        }
      }

      getPage({
        ...auditSearch,
        ...this.state,
        page: 1,
        direction,
        orderBy: pageDetails.orderBy,
        entityName,
        search: allSearchConditions,
      });
      this.setState({ page: 1, search, direction });
    };

    onSortParamsChange = (event, sortParams) => {
      const { getPage, entityName } = this.props;
      const { auditSearch } = this.state;
      const direction = getNextSortDirection(
        sortParams.sortHeaderKey,
        sortParams.sortHeaderKey,
        sortParams.sortDirection,
      );
      const search = this.createSearchCriteria();
      getPage({
        ...auditSearch,
        ...this.state,
        page: 1,
        orderBy: sortParams.sortHeaderKey,
        direction,
        entityName,
        search,
      });
      this.setState({ page: 1, orderBy: sortParams.sortHeaderKey, direction });
    };

    onPageChange = arg => {
      const { getPage, direction, entityName, pageDetails } = this.props;
      const { auditSearch } = this.state;
      const search = this.createSearchCriteria();
      getPage({
        ...auditSearch,
        ...this.state,
        ...arg,
        direction,
        orderBy: pageDetails.orderBy,
        entityName,
        search,
      });
      this.setState({ ...arg, direction });
    };

    render() {
      const { pageDetails, searcheable, auditSearch } = this.props;
      const fullPageDetails = { ...pageDetails, onChange: this.onPageChange };
      if (auditSearch) {
        this.setState({ ...auditSearch });
      }
      return (
        <WrappedTable
          {...this.props}
          searcheable={searcheable}
          pageDetails={fullPageDetails}
          paginationProps={this.paginationProps}
          onSearchInputChange={this.onSearchInputChange}
          onSortParamsChange={this.onSortParamsChange}
          onChange={this.onPageChange}
        />
      );
    }
  };
};

export default withSearch;
