/* eslint-disable eqeqeq */
import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import './alchemy-multi-select.scss';

const AlchemyAdvancedSelect = ({
  input,
  labelText,
  options,
  name,
  isMulti,
  isEdit,
  placeholder,
  parentTaxonomy,
  parentIdField,
  parentId,
  filterByParentId,
  clearField,
  reduxFormChange,
  meta: { touched, error, warning },
}) => {
  let filteredOptions = options;

  // this is for parent->child linking in non-FieldArray situations
  if (parentTaxonomy) {
    filteredOptions = options.filter(
      // eslint-disable-next-line eqeqeq
      option => option[parentIdField] == parentId,
    );

    // this is for parent->child linking in FieldArrays
  } else if (filterByParentId) {
    filteredOptions = options.filter(
      // eslint-disable-next-line eqeqeq
      option => option[parentIdField] == filterByParentId,
    );
  }

  const transformedOptions =
    filteredOptions &&
    filteredOptions.map(option => ({
      value: option.id,
      label: option.description,
      id: option.id,
    }));

  let inputValues = input.value;
  if (transformedOptions && isEdit) {
    const tempValues = inputValues;
    inputValues = [];
    transformedOptions.forEach(element => {
      if (tempValues.includes(element.id)) {
        inputValues.push(element);
      }
    });
  }

  return (
    <div className="test">
      <label className="bx--label">{labelText}</label>
      <Select
        id={input.name}
        {...input}
        labelText={labelText}
        invalid={!!(touched && error)}
        value={
          isMulti
            ? inputValues &&
              inputValues.map(value => {
                return {
                  ...value,
                  value: value.id,
                  label:
                    value.label ||
                    value.description ||
                    `${value.lastName} ${value.firstName}`,
                };
              })
            : transformedOptions &&
              transformedOptions.filter(option => option.id == inputValues)
        }
        name={name}
        onChange={value => {
          if (clearField && value === null) {
            reduxFormChange(clearField, null);
          } else if (isMulti) {
            input.onChange(value);
          } else {
            input.onChange(value ? value.id : null);
          }
        }}
        onBlur={() =>
          isMulti ? input.onBlur(inputValues) : input.onBlur(inputValues.id)
        }
        onBlurResetsInput={false}
        options={transformedOptions}
        isMulti={isMulti}
        placeholder={placeholder}
        isClearable
      >
        {transformedOptions &&
          transformedOptions.map(option => (
            <option value={option.value} text={option.label} />
          ))}
      </Select>
      {(touched &&
        (error && (
          <div className="bx--form-requirement bx--form-requirement--show-always">
            {error}
          </div>
        ))) ||
        (touched &&
          (warning && (
            <div className="bx--form-requirement bx--form-requirement--show-always">
              {warning}
            </div>
          )))}
    </div>
  );
};

AlchemyAdvancedSelect.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  labelText: PropTypes.string.isRequired,
  options: PropTypes.instanceOf(Array),
  name: PropTypes.string.isRequired,
  isMulti: PropTypes.bool,
  isEdit: PropTypes.bool,
  placeholder: PropTypes.string,
  parentTaxonomy: PropTypes.instanceOf(Object),
  parentIdField: PropTypes.string,
  parentId: PropTypes.number,
  filterByParentId: PropTypes.number,
  meta: PropTypes.instanceOf(Object).isRequired,
  clearField: PropTypes.string,
  reduxFormChange: PropTypes.instanceOf(Function),
};

AlchemyAdvancedSelect.defaultProps = {
  isMulti: false,
  isEdit: false,
  options: [],
  placeholder: 'Selecteaza valoarea',
  parentTaxonomy: null,
  parentIdField: null,
  parentId: null,
  filterByParentId: null,
  clearField: null,
  reduxFormChange: null,
};

export default AlchemyAdvancedSelect;
