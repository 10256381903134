import React from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import { msignActions } from '../../redux/actions';
import AlchemyNotification from '../../components/form/AlchemyNotification';

class MSignReceiveForm extends React.PureComponent {
  static propTypes = {
    getSignResponseForCertIdAndRequestId: PropTypes.instanceOf(Function)
      .isRequired,
    match: PropTypes.instanceOf(Object).isRequired,
  };

  componentDidMount() {
    const { match, getSignResponseForCertIdAndRequestId } = this.props;
    const { params } = match;
    const { certificateId, requestId } = params;

    if (certificateId && requestId) {
      getSignResponseForCertIdAndRequestId(certificateId, requestId);
    }
  }

  render() {
    return (
      <div>
        <AlchemyNotification />
      </div>
    );
  }
}

export default connect(
  state => {
    const { msign } = state;
    return {
      signResponse: msign.signResponse,
    };
  },
  {
    getSignResponseForCertIdAndRequestId:
      msignActions.getSignResponseForCertIdAndRequestId,
  },
)(MSignReceiveForm);
