import { GroupActions } from '../types';
import { alertActions } from './alert.actions';
import { get, put, post, history } from '../../helpers';

const getGroups = () => async dispatch => {
  try {
    dispatch({ type: GroupActions.GROUPS_GET_ALL_REQUEST });
    const response = await get(`/roles`);
    const groups = await response.json();
    dispatch({ type: GroupActions.GROUPS_GET_ALL_SUCCESS, groups });
    dispatch(alertActions.clear());
  } catch (error) {
    dispatch({ type: GroupActions.GROUPS_GET_ALL_ERROR, error });
    dispatch(alertActions.error('Rolurile nu au putut fi extrase'));
  }
};

const editGroup = group => async dispatch => {
  try {
    await put(`/roles`, group);
    dispatch({ type: GroupActions.GROUPS_EDIT_SUCCESS, group });
    dispatch(alertActions.clear());
  } catch (error) {
    dispatch({ type: GroupActions.GROUPS_EDIT_ERROR, error });
    dispatch(alertActions.error('Rolul nu a putut fi editat'));
  }
};

const removeUserFromGroup = (groupId, users) => async dispatch => {
  try {
    await post(`/roles/users/${groupId}`, users);
    dispatch({ type: GroupActions.GROUP_REMOVE_USER_SUCCESS });
    dispatch(alertActions.clear());
    history.push('/');
    history.push('/access-management');
  } catch (error) {
    dispatch({ type: GroupActions.GROUP_REMOVE_USER_ERROR, error });
    dispatch(alertActions.error('Rolurile nu au putut fi eliminate'));
  }
};

export const groupActions = {
  getGroups,
  editGroup,
  removeUserFromGroup,
};
