/* eslint-disable eqeqeq */
/* eslint-disable react/prop-types */
import decode from 'jwt-decode';
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { Button, Form } from 'carbon-components-react';
import { Field, reduxForm } from 'redux-form';
import { concediiActions, taxonomyActions } from '../../redux/actions';
import AlchemyTextInput from '../../components/form/AlchemyTextInput';
import AlchemySelect from '../../components/form/AlchemySelect';
import AlchemyNotification from '../../components/form/AlchemyNotification';
import '../style.scss';
import * as validation from '../../helpers/validation';

const Content = styled.div`
  overflow-x: hidden;
  background: #f3f3f3;
`;

const TitleModule = styled.h3`
  padding: 1rem 10rem;
  background: #f3f3f3;
  font-size: 1.75rem;
  @media (max-width: 1100px) {
    padding: 1rem;
  }
`;

const FooterButtons = styled.div`
  padding-top: 3rem;
  padding-bottom: 3rem;
`;

// eslint-disable-next-line react/prop-types
class SetStatusForm extends React.PureComponent {
  state = {};

  componentDidMount() {
    this.myRef = React.forwardRef();
    this.form = React.forwardRef();
  }

  validateStatus = () => {
    const { valid, reduxFormData, changeCertificateStatus } = this.props;
    if (valid) {
      changeCertificateStatus(
        get(reduxFormData, 'certificateId', null),
        get(reduxFormData, 'statusId', null),
      );
    }
  };

  render() {
    const { taxonomies, handleSubmit } = this.props;

    /* Test src prod */

    return (
      <React.Fragment>
        <Content>
          <TitleModule>Setare statut certificat</TitleModule>
          <AlchemyNotification />
          {
            <Form
              className="box"
              onSubmit={handleSubmit(() => {
                return this.validateStatus();
              })}
            >
              <div className="box__container">
                <div className="box__triple-block">
                  <Field
                    name="certificateId"
                    type="number"
                    component={AlchemyTextInput}
                    label="Identificator certificat (ID)"
                    validate={validation.required}
                  />
                  <Field
                    name="statusId"
                    component={AlchemySelect}
                    labelText="Statut certificat"
                    options={taxonomies && taxonomies.NmCertificateStatuses}
                    validate={validation.required}
                  />
                </div>
                <FooterButtons>
                  <div className="box__triple-block">
                    <div className="box__double-block">
                      <Button type="submit">Modifica statut</Button>
                    </div>
                  </div>
                </FooterButtons>
              </div>
            </Form>
          }
        </Content>
      </React.Fragment>
    );
  }
}

const ReduxForm = reduxForm({
  form: 'concediuForm',
  enableReinitialize: true,
})(SetStatusForm);

export default connect(
  state => {
    const { taxonomies, concedii, form } = state;
    const { requestedConcediu, totalDays, loading } = concedii;
    const { concediuForm } = form;
    const { values, valid } = concediuForm || {};
    const tokens = decode(sessionStorage.getItem('token'));

    return {
      taxonomies: taxonomies.taxonomies,
      serverDate: taxonomies.serverDate,
      initialValues: {
        certificationPlace: { id: 1 },
        ...values,
        ...requestedConcediu,
        isChild:
          (requestedConcediu && requestedConcediu.childDetails) ||
          (values && values.isChild),
        username: `${tokens.lastname} ${tokens.firstname}`,
        institutionName: `${tokens.institutionName}`,
        phoneNumberToken: `${tokens.phoneNumber}`,
        totalDays: totalDays + (values && values.grantedDays),
      },
      reduxFormData: values,
      formValid: valid,
      loading,
    };
  },
  {
    changeCertificateStatus: concediiActions.changeCertificateStatus,
    getServerDate: taxonomyActions.getServerDate,
  },
)(ReduxForm);
