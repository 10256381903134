import changeCase from 'change-case';
import jwt from 'jsonwebtoken';

const buildSearchPredicate = (value, fields) =>
  value
    ? fields.map(field => ({
        orPredicate: true,
        key: field,
        operation: ':',
        value,
      }))
    : undefined;

const buildHeaders = headersArray => {
  return headersArray.map(header => ({
    key: header.code ? header.code : header,
    header: changeCase.sentenceCase(
      header.description ? header.description : header,
    ),
  }));
};

const getGroupRolesFromCell = (cellId, groups) =>
  // eslint-disable-next-line eqeqeq
  groups.filter(g => g.id == cellId.match(/\d+/g)[0])[0].authorities;
export { buildSearchPredicate, buildHeaders, getGroupRolesFromCell };

const getUserGroupFromCell = (cellId, users) =>
  // eslint-disable-next-line eqeqeq
  users.filter(u => u.id == cellId.match(/\d+/g)[0])[0].roles;

const hasRole = role => {
  const token = sessionStorage.getItem('token');
  const user = jwt.decode(token);
  return user && user.role && user.role.split(',').includes(role);
};

const isTheSameUser = userId => {
  const token = sessionStorage.getItem('token');
  const user = jwt.decode(token);
  return user && user.id === userId;
};

const isSubordinate = (recorders, initiatorUserId) => {
  return recorders && recorders.find(el => el.id === initiatorUserId);
};

const getUserFromToken = () => jwt.decode(sessionStorage.getItem('token'));

export {
  getUserGroupFromCell,
  hasRole,
  getUserFromToken,
  isTheSameUser,
  isSubordinate,
};
