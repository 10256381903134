import { UserActions } from '../types';

const user = JSON.parse(sessionStorage.getItem('user'));
const initialState = user ? { loggedIn: true, user } : {};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case UserActions.LOGIN_REQUEST:
      return {
        loggingIn: true,
      };
    case UserActions.LOGIN_SUCCESS:
      return {
        user: action.user,
      };
    case UserActions.LOGIN_FAILURE:
      return {};
    case UserActions.LOGOUT:
      return {};
    default:
      return state;
  }
}
