import styled from 'styled-components';

const Grid = styled.div`
  display: grid;
  grid-template-rows: 3rem minmax(calc(100vh - 3rem), min-content);
  height: 100vh;
  overflow: hidden;
`;

export default Grid;
