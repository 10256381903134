import jwt from 'jsonwebtoken';
import { UserActions } from '../types';

import { alertActions } from './alert.actions';
import { history, get, post, put, postNoToken } from '../../helpers';

import { taxonomyActions } from './taxonomy.actions';
import { hasRole } from '../../helpers/utils';
import { concediiActions } from './concedii.actions';

const login = (username, password) => async dispatch => {
  try {
    dispatch({ type: UserActions.LOGIN_REQUEST, username });
    const response = await postNoToken(username, password);
    const token = response.headers.get('Authorization').split(' ')[1];
    const user = jwt.decode(token);
    if (user.dateExpiration && new Date(user.dateExpiration) <= new Date()) {
      throw new Error(
        'Parola este expirata. Este necesar de modificat parola.',
      );
    }
    sessionStorage.setItem('token', token);
    dispatch({ type: UserActions.LOGIN_SUCCESS, user });

    taxonomyActions.getAllTaxonomies()(dispatch);

    if (hasRole('ROLE_AUDIT')) {
      history.push('/audit');
    } else if (hasRole('ROLE_REG')) {
      history.push('/forma-concediu-medical');
    } else {
      history.push('/');
    }
  } catch (error) {
    dispatch({
      type: UserActions.LOGIN_FAILURE,
      error:
        error.message ||
        'Numele de utilizator sau parola a fost introdusa gresit',
    });
    dispatch(
      alertActions.error(
        error.message ||
          'Numele de utilizator sau parola a fost introdusa gresit',
      ),
    );
  }
};

const logout = () => dispatch => {
  sessionStorage.removeItem('token');
  history.push('/login');
  dispatch({ type: UserActions.LOGOUT });
  dispatch(concediiActions.CLEAR_STATE);
};

const logoutSAML = () => {
  sessionStorage.removeItem('token');
  // history.push('/login');
  setTimeout(() => {
    window.location.href = `${process.env.REACT_APP_API_BASE_URL}/saml/logout`;
  }, 10);
};

const getPage = pageDetails => async dispatch => {
  dispatch({ type: UserActions.GETPAGE_REQUEST });
  try {
    const response = await post(`/users/search`, pageDetails);
    const page = await response.json();
    dispatch({ type: UserActions.GETPAGE_SUCCESS, page });
  } catch (error) {
    dispatch({ type: UserActions.GETPAGE_FAILURE, error });
  }
};

const editUserReduxForm = userId => async (dispatch, getState) => {
  const { form } = getState();
  const { editUserForm } = form;
  const { values } = editUserForm;

  let user = {
    ...values,
    roles: values.roles.map(roleId => ({ id: roleId })),
  };

  dispatch({ type: UserActions.EDIT_REQUEST });
  try {
    // eslint-disable-next-line
    if (user.recorders && user.recorders.find(r => r.id == userId)) {
      throw new Error(
        'Utilizatorul curent nu trebuie sa fie inclus in lista registratorilor.',
      );
    }

    // Registratorii existenti sunt prezenti in lista fara id, metoda de mai jos le seteaza id si ii sterge pe cei fara
    if (user.recorders) {
      user.recorders.forEach(element => {
        if (!element.id) {
          user.recorders.push({ id: element });
        }
      });
      user.recorders = user.recorders.filter(function removeItem(item) {
        return item.id;
      });
    }

    // Cind apesi pe x-ul de la registrator si numai ramine nici unul recorders vine ca string nu ca lista
    if (!user.recorders || user.recorders.length === 0) {
      user = { ...user, recorders: [] };
    }

    await put(`/users/${user.id}`, user);
    dispatch({ type: UserActions.EDIT_SUCCESS });
    dispatch(alertActions.clear());
    history.push('/');
    history.push('/access-management');
  } catch (error) {
    dispatch({ type: UserActions.EDIT_FAILURE, error });
    dispatch(
      alertActions.error(
        error.message || 'Nu a putut fi actualizat utilizatorul',
      ),
    );
  }
};

const createUserReduxForm = () => async (dispatch, getState) => {
  const { form } = getState();
  const { createUserForm } = form;
  const { values } = createUserForm;

  const user = {
    ...values,
    roles: values.roles ? values.roles.map(roleId => ({ id: roleId })) : null,
    type: {
      id: 1,
    },
  };

  try {
    const response = await post(`/users`, user);
    const userId = await response.json();
    const createdUser = { ...user, id: userId };
    dispatch({ type: UserActions.CREATE_SUCCESS, createdUser });
    dispatch(alertActions.success('Utilizatorul a fost creat cu succes'));
    dispatch(alertActions.clear());
    setTimeout(() => {
      history.push(`/`);
      history.push(`/access-management`);
    }, 1000);
  } catch (error) {
    dispatch({ type: UserActions.CREATE_FAILURE, error });
    dispatch(alertActions.error('Nu a putut fi creat utilizatorul'));
  }
};

const getUser = userId => async dispatch => {
  try {
    dispatch({ type: UserActions.GET_AFTER_SEARCH_REQUEST });
    const response = await get(`/users/${userId}`);
    const userData = await response.json();
    dispatch({ type: UserActions.GET_AFTER_SEARCH_SUCCESS, userData });
    dispatch(alertActions.clear());
  } catch (error) {
    dispatch({ type: UserActions.GET_AFTER_SEARCH_FAILURE, error });
    dispatch(
      alertActions.error('Nu a putut fi exstrase detaliile utilizatorului'),
    );
  }
};

const getChangePasswordForm = getState => {
  const { form } = getState();
  const { changePasswordForm } = form;
  const { values } = changePasswordForm;
  return values;
};
const changePassword = () => async (dispatch, getState) => {
  const password = getChangePasswordForm(getState);
  try {
    if (password.newPassword !== password.confirmNewPassword) {
      throw new Error('Parola noua nu coincide cu cea confirmata.');
    }
    if (password.newPassword === password.oldPassword) {
      throw new Error('Parola noua nu trebuie sa coincida cu cea veche.');
    }
    dispatch({ type: UserActions.USERS_CHANGE_PWD_REQUEST });
    await post(`/users/change-password`, password);
    dispatch({ type: UserActions.USERS_CHANGE_PWD_SUCCESS });
    dispatch(alertActions.clear());
    sessionStorage.removeItem('token');
    history.push('/login');
    dispatch(alertActions.success('Parola a fost modificata cu succes.'));
  } catch (error) {
    dispatch({ type: UserActions.USERS_CHANGE_PWD_FAILURE, error });
    dispatch(
      alertActions.error(error.message || 'Parola nu a putut fi modificata'),
    );
  }
};

const getChangeExpiredPasswordForm = getState => {
  const { form } = getState();
  const { changeExpiredPasswordForm } = form;
  return changeExpiredPasswordForm.values;
};

const changeExpiredPassword = () => async (dispatch, getState) => {
  const changeExpiredPasswordForm = getChangeExpiredPasswordForm(getState);
  try {
    if (
      changeExpiredPasswordForm.newPassword !==
      changeExpiredPasswordForm.confirmNewPassword
    ) {
      throw new Error('Parola noua nu coincide cu cea confirmata.');
    }
    if (
      changeExpiredPasswordForm.newPassword ===
      changeExpiredPasswordForm.oldPassword
    ) {
      throw new Error('Parola noua nu trebuie sa coincida cu cea veche.');
    }

    try {
      const { username } = changeExpiredPasswordForm;
      dispatch({ type: UserActions.LOGIN_REQUEST, username });
      const response = await postNoToken(
        changeExpiredPasswordForm.username,
        changeExpiredPasswordForm.oldPassword,
      );
      const token = response.headers.get('Authorization').split(' ')[1];
      sessionStorage.setItem('token', token);
    } catch (error) {
      throw new Error(
        'Numele de utilizator sau parola veche a fost introdusa gresit',
      );
    }
    dispatch({ type: UserActions.USERS_CHANGE_EXPIRED_PWD_REQUEST });
    await post(`/users/change-password`, changeExpiredPasswordForm);
    dispatch({ type: UserActions.USERS_CHANGE_EXPIRED_PWD_SUCCESS });
    dispatch(alertActions.clear());
    sessionStorage.removeItem('token');
    history.push('/login');
    dispatch(alertActions.success('Parola a fost modificata cu succes.'));
  } catch (error) {
    sessionStorage.removeItem('token');
    dispatch({
      type: UserActions.USERS_CHANGE_EXPIRED_PWD_FAILURE,
      error: error.message || 'Parola nu a putut fi modificata',
    });
    dispatch(
      alertActions.error(error.message || 'Parola nu a putut fi modificata'),
    );
  }
};

const getAllUsers = () => async dispatch => {
  dispatch({ type: UserActions.GETALL_REQUEST });
  try {
    const response = await get(`/users`);
    const usersAll = await response.json();
    dispatch({ type: UserActions.GETALL_SUCCESS, usersAll });
  } catch (error) {
    dispatch({ type: UserActions.GETALL_FAILURE, error });
    dispatch(alertActions.error('Nu au putut fi extrasi utilizatorii'));
  }
};

const getRecordersUsersByInstitutionId = institutionId => async dispatch => {
  dispatch({ type: UserActions.GET_RECORDERS_BY_INSTITUTION_REQUEST });
  try {
    const response = await get(`/users/recorders/${institutionId}`);
    const users = await response.json();
    dispatch({
      type: UserActions.GET_RECORDERS_BY_INSTITUTION_SUCCESS,
      users,
    });
  } catch (error) {
    dispatch({ type: UserActions.GET_RECORDERS_BY_INSTITUTION_FAILURE, error });
    dispatch(alertActions.error('Nu au putut fi extrasi registratorii'));
  }
};

const getUsersForSearchCertificates = userId => async dispatch => {
  dispatch({ type: UserActions.GET_USERS_FOR_SEARCH_REQUEST });
  try {
    const response = await get(
      `/certificate/users-for-search-certificates/${userId}`,
    );
    const users = await response.json();
    dispatch({ type: UserActions.GET_USERS_FOR_SEARCH_SUCCESS, users });
  } catch (error) {
    dispatch({ type: UserActions.GET_USERS_FOR_SEARCH_FAILURE, error });
    dispatch(alertActions.error('Nu au putut fi extrasi utilizatorii'));
  }
};

const getUsersByInstitutionId = institutionId => async dispatch => {
  dispatch({ type: UserActions.GET_USERS_BY_INSTITUTION_ID_REQUEST });
  try {
    const response = await get(
      `/certificate/users-by-institution-id/${institutionId}`,
    );
    const users = await response.json();
    dispatch({ type: UserActions.GET_USERS_BY_INSTITUTION_ID_SUCCESS, users });
  } catch (error) {
    dispatch({ type: UserActions.GET_USERS_BY_INSTITUTION_ID_FAILURE, error });
    dispatch(alertActions.error('Nu au putut fi extrasi utilizatorii'));
  }
};

const getAvailablerRecorders = userId => async dispatch => {
  dispatch({ type: UserActions.GET_AVAILABLE_RECORDERS_REQUEST });
  try {
    const response = await get(`/users/available-recorders/${userId}`);
    const recorders = await response.json();
    dispatch({ type: UserActions.GET_AVAILABLE_RECORDERS_SUCCESS, recorders });
  } catch (error) {
    dispatch({ type: UserActions.GET_AVAILABLE_RECORDERS_FAILURE, error });
    dispatch(alertActions.error('Nu au putut fi extrasi registratorii'));
  }
};

const resetPassword = (userId, password) => async dispatch => {
  dispatch({ type: UserActions.RESET_PASSWORD_REQUEST });
  const passwordDTO = { userId, newPassword: password };
  try {
    const response = await put(`/users/${userId}/reset-password`, passwordDTO);
    await response.json();
    dispatch({ type: UserActions.RESET_PASSWORD_SUCCESS });
    dispatch(alertActions.success('Parola a fost resetata cu succes'));
  } catch (error) {
    dispatch({ type: UserActions.RESET_PASSWORD_FAILURE, error });
    dispatch(alertActions.error('Nu a putut fi resetata parola'));
  }
};

export const userActions = {
  login,
  logout,
  editUserReduxForm,
  createUserReduxForm,
  getPage,
  getUser,
  changePassword,
  changeExpiredPassword,
  getAllUsers,
  getUsersForSearchCertificates,
  getUsersByInstitutionId,
  getRecordersUsersByInstitutionId,
  getAvailablerRecorders,
  resetPassword,
  logoutSAML,
};
