import React from 'react';
import styled from 'styled-components';
// import XAxis20 from '@carbon/icons-react/es/x-axis/20';
// import { NavLink } from 'react-router-dom';

const Content = styled.div`
  padding: 3rem;
  background: #f3f3f3;
`;

const Title = styled.h3`
  font-size: 2.25rem;
`;

const Text = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
`;

// const SubText = styled.p`
//   font-size: 1.125rem;
//   padding-bottom: 2rem;
// `;

// const Link = styled(NavLink)`
//   font-size: 1.25rem;
//   text-decoration: none;
//   color: #333333;
//   padding-left: 0.5rem;
// `;

// const Li = styled.li`
//   padding-bottom: 0.75rem;
// `;

const UserManagementTabs = () => (
  <Content>
    <Title>Lucrurile nu au mers bine!</Title>
    <Text>
      {/* <SubText>
        Poate că unele dintre aceste linkuri cele mai vizitate vă vor ajuta?
      </SubText>
      <ul>
        <Li>
          <XAxis20 />
          <Link to="/forma-concediu-medical">Crearea certificat</Link>
        </Li>
        <Li>
          <XAxis20 />
          <Link to="/">Linka certificate</Link>
        </Li>
        <Li>
          <XAxis20 />
          <Link to="/access-management">Administrare utilizatori</Link>
        </Li>
        <Li>
          <XAxis20 />
          <Link to="/taxonomy-management">Administrare nomenclatoare</Link>
        </Li>
        <Li>
          <XAxis20 />
          <Link to="/audit">Audit</Link>
        </Li>
        <Li>
          <XAxis20 />
          <Link to="/profil-utilizator">Profil utilizator</Link>
        </Li>
      </ul> */}
    </Text>
  </Content>
);

export default UserManagementTabs;
