/* eslint-disable react/prop-types */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import { connect } from 'react-redux';
import { ToastNotification } from 'carbon-components-react';
import { AlertActions } from '../../redux/types';
import { alertActions } from '../../redux/actions';
// import { alertActions } from '../../redux/actions';

class AlchemyNotification extends React.Component {
  state = {
    notifications: [],
  };

  static getDerivedStateFromProps(notif, prevState) {
    /* eslint-disable eqeqeq */
    const { notifications, removedElements } = prevState;
    if (
      notif &&
      notif.message &&
      !removedElements &&
      !notifications.some(n => n.message == notif.message)
    ) {
      const currentTime = new Date();
      const key = currentTime.getUTCMilliseconds();
      const notification = {
        ...notif,
        key,
      };
      notifications.push(notification);

      return {
        notifications,
        removedElements: false,
      };
    }
    return { removedElements: false };
  }

  render() {
    const { notifications } = this.state;
    const self = this;
    const { clear } = this.props;

    return notifications ? (
      <div className="alchemy-notification">
        {notifications.map(el => {
          let title;
          if (el.type === AlertActions.SUCCESS) {
            title = 'Succes';
          } else if (el.type === AlertActions.WARNING) {
            title = 'Atentionare';
          } else {
            title = 'Eroare';
          }
          return (
            <ToastNotification
              key={el.key}
              caption=""
              lowContrast={false}
              className="notification"
              iconDescription="Inchide"
              subtitle={el.message}
              kind={el.type || 'error'}
              hideCloseButton={el.type === AlertActions.SUCCESS}
              title={title}
              onCloseButtonClick={() => {
                const notifNew = notifications.filter(n => n.key != el.key);
                self.setState({
                  notifications: notifNew,
                  removedElements: true,
                });

                clear();
              }}
              ref={element => {
                if (
                  element &&
                  (el.type === AlertActions.SUCCESS ||
                    el.type === AlertActions.ERROR)
                ) {
                  setTimeout(() => {
                    element.handleCloseButtonClick();
                  }, 3000);
                }
              }}
            />
          );
        })}
      </div>
    ) : null;
  }
}

export default connect(
  state => {
    const { alert } = state;
    return {
      ...alert,
      random: Math.random(),
    };
  },
  {
    clear: alertActions.clear,
  },
)(AlchemyNotification);
