export const TaxonomyActions = {
  CREATE_REQUEST: 'TAXONOMY_CREATE_REQUEST',
  CREATE_SUCCESS: 'TAXONOMY_CREATE_SUCCESS',
  CREATE_FAILURE: 'TAXONOMY_CREATE_FAILURE',
  EDIT_REQUEST: 'TAXONOMY_EDIT_REQUEST',
  EDIT_SUCCESS: 'TAXONOMY_EDIT_SUCCESS',
  EDIT_FAILURE: 'TAXONOMY_EDIT_FAILURE',
  DELETE_REQUEST: 'TAXONOMY_DELETE_REQUEST',
  DELETE_SUCCESS: 'TAXONOMY_DELETE_SUCCESS',
  DELETE_FAILURE: 'TAXONOMY_DELETE_FAILURE',
  TAXONOMY_GET_BY_INDEX_REQUEST: 'TAXONOMY_GET_BY_INDEX_REQUEST',
  TAXONOMY_GET_BY_INDEX_SUCCESS: 'TAXONOMY_GET_BY_INDEX_SUCCESS',
  TAXONOMY_GET_BY_INDEX_ERROR: 'TAXONOMY_GET_BY_INDEX_ERROR',
  TAXONOMY_GET_ALL_REQUEST: 'TAXONOMY_GET_ALL_REQUEST',
  TAXONOMY_GET_ALL_SUCCESS: 'TAXONOMY_GET_ALL_SUCCESS',
  TAXONOMY_GET_ALL_ERROR: 'TAXONOMY_GET_ALL_ERROR',
  TAXONOMY_GET_ALL_WITHOUT_CONTENT_REQUEST:
    'TAXONOMY_GET_ALL_WITHOUT_CONTENT_REQUEST',
  TAXONOMY_GET_ALL_WITHOUT_CONTENT_SUCCESS:
    'TAXONOMY_GET_ALL_WITHOUT_CONTENT_SUCCESS',
  TAXONOMY_GET_ALL_WITHOUT_CONTENT_ERROR:
    'TAXONOMY_GET_ALL_WITHOUT_CONTENT_ERROR',
  TAXONOMY_GET_ENTITY_REQUEST: 'TAXONOMY_GET_ENTITY_REQUEST',
  TAXONOMY_GET_ENTITY_SUCCESS: 'TAXONOMY_GET_ENTITY_SUCCESS',
  TAXONOMY_GET_ENTITY_ERROR: 'TAXONOMY_GET_ENTITY_ERROR',
  TAXONOMY_GET_BY_ID_REQUEST: 'TAXONOMY_GET_BY_ID_REQUEST',
  TAXONOMY_GET_BY_ID_SUCCESS: 'TAXONOMY_GET_BY_ID_SUCCESS',
  TAXONOMY_GET_BY_ID_ERROR: 'TAXONOMY_GET_BY_ID_ERROR',
  TAXONOMY_GET_COLUMN_DETAILS_REQUEST: 'TAXONOMY_GET_COLUMN_DETAILS_REQUEST',
  TAXONOMY_GET_COLUMN_DETAILS_SUCCESS: 'TAXONOMY_GET_COLUMN_DETAILS_SUCCESS',
  TAXONOMY_GET_COLUMN_DETAILS_ERROR: 'TAXONOMY_GET_COLUMN_DETAILS_ERROR',
  CLEAR_REQUESTED_TAXONOMY: 'CLEAR_REQUESTED_TAXONOMY',
  GET_SERVER_DATE_REQUEST: 'GET_SERVER_DATE_REQUEST',
  GET_SERVER_DATE_SUCCESS: 'GET_SERVER_DATE_SUCCESS',
  GET_SERVER_DATE_FAILURE: 'GET_SERVER_DATE_FAILURE',
};
