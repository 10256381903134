import { TaxonomyActions } from '../types';
import { alertActions } from './alert.actions';
import { get, post, put /* del */ } from '../../helpers';
import { history } from '../../helpers/history';

const getAllTaxonomies = () => async dispatch => {
  try {
    dispatch({ type: TaxonomyActions.TAXONOMY_GET_ALL_REQUEST });
    const response = await get(`/taxonomy2/all`);
    const taxonomies = await response.json();
    sessionStorage.setItem('taxonomies', JSON.stringify(taxonomies));
    dispatch({
      type: TaxonomyActions.TAXONOMY_GET_ALL_SUCCESS,
      taxonomies,
    });
    dispatch(alertActions.clear());
  } catch (error) {
    dispatch({ type: TaxonomyActions.TAXONOMY_GET_ALL_ERROR, error });
  }
};

const getAllTaxonomiesWithoutContent = () => async dispatch => {
  try {
    dispatch({
      type: TaxonomyActions.TAXONOMY_GET_ALL_WITHOUT_CONTENT_REQUEST,
    });
    const response = await get(`/taxonomy2/list`);
    const taxonomiesWithoutContent = await response.json();
    dispatch({
      type: TaxonomyActions.TAXONOMY_GET_ALL_WITHOUT_CONTENT_SUCCESS,
      taxonomiesWithoutContent,
    });
    dispatch(alertActions.clear());
  } catch (error) {
    dispatch({
      type: TaxonomyActions.TAXONOMY_GET_ALL_WITHOUT_CONTENT_ERROR,
      error,
    });
    dispatch(
      alertActions.error('Nu au putut fi exstrase detaliile nomenclatoarelor'),
    );
  }
};

const getPage = pageDetails => async dispatch => {
  try {
    dispatch({ type: TaxonomyActions.TAXONOMY_GET_ENTITY_REQUEST });
    const response = await post(`/taxonomy2/search`, pageDetails);
    const page = await response.json();
    dispatch({ type: TaxonomyActions.TAXONOMY_GET_ENTITY_SUCCESS, page });
    dispatch(alertActions.clear());
  } catch (error) {
    dispatch({ type: TaxonomyActions.TAXONOMY_GET_ENTITY_ERROR, error });
    dispatch(
      alertActions.error('Nu au putut fi exstrase detaliile nomenclatoarelor'),
    );
  }
};

const getTaxonomyForm = getState => {
  const { form } = getState();
  const { createTaxonomyForm } = form;
  return createTaxonomyForm.values;
};

const createTaxonomyReduxForm = entityName => async (dispatch, getState) => {
  const taxonomyForm = getTaxonomyForm(getState);
  dispatch({ type: TaxonomyActions.CREATE_REQUEST });
  try {
    const createTaxonomy = { name: entityName, record: taxonomyForm };
    const response = await post(`/taxonomy2`, createTaxonomy);
    dispatch({ type: TaxonomyActions.CREATE_SUCCESS, response });
    history.push(`/taxonomy-management/${entityName}`);
    dispatch(alertActions.clear());
  } catch (error) {
    dispatch({ type: TaxonomyActions.CREATE_FAILURE, error });
    dispatch(alertActions.error('Datele nu au putut fi salvate.'));
  }
};

const getTaxonomyById = (id, entityName) => async dispatch => {
  try {
    dispatch({ type: TaxonomyActions.TAXONOMY_GET_BY_ID_REQUEST });
    const response = await get(`/taxonomy2/${id}/${entityName}`);
    const taxonomy = await response.json();
    dispatch({ type: TaxonomyActions.TAXONOMY_GET_BY_ID_SUCCESS, taxonomy });
    dispatch(alertActions.clear());
  } catch (error) {
    dispatch({ type: TaxonomyActions.TAXONOMY_GET_BY_ID_ERROR, error });
    dispatch(alertActions.error('Nu a fost gasit nici un nomenclator'));
  }
};

const getColumnDetails = entityName => async dispatch => {
  try {
    dispatch({ type: TaxonomyActions.TAXONOMY_GET_COLUMN_DETAILS_REQUEST });
    const response = await get(`/taxonomy2/column-details/${entityName}`);
    const columnDetails = await response.json();
    dispatch({
      type: TaxonomyActions.TAXONOMY_GET_COLUMN_DETAILS_SUCCESS,
      columnDetails,
    });
    dispatch(alertActions.clear());
  } catch (error) {
    dispatch({
      type: TaxonomyActions.TAXONOMY_GET_COLUMN_DETAILS_ERROR,
      error,
    });
    dispatch(
      alertActions.error('Nu au putut fi extrase detaliile nomenclatorului'),
    );
  }
};

const getServerDate = () => async dispatch => {
  try {
    dispatch({ type: TaxonomyActions.GET_SERVER_DATE_REQUEST });
    const response = await get(`/taxonomy2/get-server-date`);
    const serverDate = await response.json();
    sessionStorage.setItem('serverDate', serverDate);
    dispatch({
      type: TaxonomyActions.GET_SERVER_DATE_SUCCESS,
      serverDate,
    });
    dispatch(alertActions.clear());
  } catch (error) {
    dispatch({
      type: TaxonomyActions.GET_SERVER_DATE_FAILURE,
      error,
    });
    dispatch(alertActions.error('Nu au putut fi extrasa data de pe server'));
  }
};

const editTaxonomy = (id, entityName) => async (dispatch, getState) => {
  const taxonomyForm = getTaxonomyForm(getState);

  dispatch({ type: TaxonomyActions.EDIT_REQUEST });
  try {
    await put(`/taxonomy2/${id}`, { name: entityName, record: taxonomyForm });
    history.push(`/taxonomy-management/${entityName}`);
    dispatch(alertActions.clear());
  } catch (error) {
    dispatch({ type: TaxonomyActions.EDIT_FAILURE, error });
    dispatch(alertActions.error('Datele nu au putut fi salvate.'));
  }
};

// const deleteTaxonomy = (id, entityName) => async dispatch => {
//   dispatch({ type: TaxonomyActions.DELETE_REQUEST });
//   try {
//     await del(`/taxonomy2/${id}`, { name: entityName });
//     dispatch({ type: TaxonomyActions.DELETE_SUCCESS });
//     history.push(`/taxonomy-management/`);
//     dispatch(alertActions.clear());
//   } catch (error) {
//     dispatch({ type: TaxonomyActions.DELETE_FAILURE, error });
//     dispatch(alertActions.error('Datele nu au putut fi anulate.'));
//   }
// };

const clearRequestedTaxonomy = () => async dispatch => {
  dispatch({ type: TaxonomyActions.CLEAR_REQUESTED_TAXONOMY });
  dispatch(alertActions.clear());
};

export const taxonomyActions = {
  getAllTaxonomies,
  getAllTaxonomiesWithoutContent,
  getPage,
  createTaxonomyReduxForm,
  getTaxonomyById,
  editTaxonomy,
  // deleteTaxonomy,
  clearRequestedTaxonomy,
  getColumnDetails,
  getServerDate,
};
