/* eslint-disable react/prop-types */
import React from 'react';

import './alchemy-text-input.scss';
// eslint-disable-next-line react/prefer-stateless-function
class AlchemyTextInput extends React.Component {
  render() {
    const {
      input,
      label,
      type,
      disabled,
      meta: { touched, error, warning },
      className,
    } = this.props;
    const { onChange } = input;
    return (
      <div className={className}>
        <label className="bx--label">{label}</label>

        <input
          label={label}
          type={type}
          {...input}
          // placeholder={label}
          invalid={error || warning}
          onChange={onChange}
          className={
            touched && error
              ? 'alchemy-input react-datepicker-invalid'
              : 'alchemy-input'
          }
          disabled={disabled}
        />
        {(touched &&
          (error && (
            <div className="bx--form-requirement bx--form-requirement--show-always">
              {error}
            </div>
          ))) ||
          (touched &&
            (warning && (
              <div className="bx--form-requirement bx--form-requirement--show-always">
                {warning}
              </div>
            )))}
      </div>
    );
  }
}

export default AlchemyTextInput;
