/* eslint-disable eqeqeq */
/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Button, Form } from 'carbon-components-react';
import { Field, reduxForm } from 'redux-form';
import { faStethoscope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { history } from '../helpers/history';
import AlchemyTextInput from '../components/form/AlchemyTextInput';
import AlchemyNotification from '../components/form/AlchemyNotification';
import * as validation from '../helpers/validation';
import { userActions } from '../redux/actions';
import '../modules/style.scss';

const Content = styled.div`
  overflow-x: hidden;
  background: #f3f3f3;
`;

const FooterButtons = styled.div`
  padding-top: 3rem;
  padding-bottom: 3rem;
`;

const Container = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const Contents = styled.div`
  display: block;
  width: 37.5rem;
  padding: 1rem 2rem;
  background: #f3f3f3;
  margin: 0 auto;
`;

const Headline = styled.h1`
  font-size: 2.625rem;
  position: relative;
  display: block;
  margin-bottom: 4rem;
  font-weight: 500;
`;

// eslint-disable-next-line react/prop-types
class ChangeExpiredPasswordForm extends React.PureComponent {
  state = {};

  componentDidMount() {}

  changePassword = () => {
    const { changeExpiredPassword } = this.props;
    changeExpiredPassword();
  };

  backToLogin = () => {
    history.push('/login');
  };

  render() {
    const { handleSubmit } = this.props;

    return (
      <React.Fragment>
        <Content>
          <AlchemyNotification />
          {
            <Form
              className="box"
              onSubmit={handleSubmit(() => {
                return this.changePassword();
              })}
            >
              <Container>
                <Headline>
                  <FontAwesomeIcon icon={faStethoscope} />
                  Portalul certificatelor de concediu medical
                </Headline>
                <Contents>
                  <h1 className="box__title">Modificare parola</h1>
                  <div className="box__single-block">
                    <Field
                      className="reset-password"
                      name="username"
                      type="text"
                      component={AlchemyTextInput}
                      label="Utilizator"
                      validate={validation.required}
                    />
                    <Field
                      className="reset-password"
                      name="oldPassword"
                      type="password"
                      component={AlchemyTextInput}
                      label="Parola veche"
                      validate={validation.required}
                    />
                    <Field
                      className="reset-password"
                      name="newPassword"
                      type="password"
                      component={AlchemyTextInput}
                      label="Parola noua"
                      validate={[
                        validation.required,
                        validation.passwordNumber,
                        validation.passwordStandart,
                        validation.passwordUpperCase,
                        validation.minValue8,
                      ]}
                    />
                    <Field
                      className="reset-password"
                      name="confirmNewPassword"
                      type="password"
                      component={AlchemyTextInput}
                      label="Confirma parola noua"
                      validate={validation.required}
                    />
                  </div>
                  <FooterButtons>
                    <div className="box__triple-block">
                      <div className="box__double-block">
                        <Button type="submit">Salveaza</Button>
                        <Button onClick={this.backToLogin} kind="danger">
                          Inapoi
                        </Button>
                      </div>
                    </div>
                  </FooterButtons>
                </Contents>
              </Container>
            </Form>
          }
        </Content>
      </React.Fragment>
    );
  }
}

const ReduxForm = reduxForm({
  form: 'changeExpiredPasswordForm',
  enableReinitialize: true,
})(ChangeExpiredPasswordForm);

export default connect(
  state => {
    const { form } = state;
    const { changeExpiredPasswordForm } = form;
    const { values, valid } = changeExpiredPasswordForm || {};
    return {
      reduxFormData: values,
      formValid: valid,
    };
  },
  {
    changeExpiredPassword: userActions.changeExpiredPassword,
  },
)(ReduxForm);
