/* eslint-disable react/prop-types */
import React from 'react';
import { FormItem } from 'carbon-components-react';

import './alchemy-checkbox.scss';
// eslint-disable-next-line react/prefer-stateless-function
class AlchemyCheckbox extends React.PureComponent {
  render() {
    const {
      input,
      label,
      type,
      meta: { error, warning },
    } = this.props;
    return (
      <FormItem>
        <label className="bx--label">{label}</label>
        <label className="check-box-container">
          <input
            {...input}
            label={label}
            className="alchemy-checkbox"
            type={type}
            invalid={error || warning}
          />
          <span className="checked" />
          {(error && (
            <span className="bx--form-requirement bx--form-requirement--show-always">
              {error}
            </span>
          )) ||
            (warning && (
              <span className="bx--form-requirement bx--form-requirement--show-always">
                {warning}
              </span>
            ))}
        </label>
      </FormItem>
    );
  }
}

export default AlchemyCheckbox;
