import React from 'react';
import Header from '../components/header/Header';
import Grid from '../components/Grid';
import Sidebar from '../components/Sidebar';

const menuWidgetOpen = {
  display: 'grid',
  gridTemplateColumns:
    '[sidebar-start full-start] 14rem [sidebar-end content-start] 1fr [content-end full-end]',
};

const menuWidgetClosed = {
  display: 'grid',
  gridTemplateColumns:
    '[sidebar-start full-start] 2.75rem [sidebar-end content-start] 1fr [content-end full-end]',
};

const MainLayout = WrappedComponent =>
  class extends React.Component {
    state = {
      statusMenu: false,
    };

    changeStatus = () => {
      this.setState(state => ({
        statusMenu: !state.statusMenu,
      }));
    };

    render() {
      const { statusMenu } = this.state;
      return (
        <React.Fragment>
          <Grid style={statusMenu ? menuWidgetOpen : menuWidgetClosed}>
            <Header />
            <Sidebar menuStatus={this.changeStatus} statusMenu={statusMenu} />
            <WrappedComponent {...this.props} />
          </Grid>
        </React.Fragment>
      );
    }
  };

export default MainLayout;
