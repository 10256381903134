import React from 'react';
import { PropTypes } from 'prop-types';
import { NavLink } from 'react-router-dom';
import { faStethoscope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const Logo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.25rem;
  padding: 0 0.5rem;
`;

const Links = styled(NavLink)`
  color: #fff;
  font-size: 1.25rem;
  text-decoration: none;
`;

const Icon = ({ className }) => (
  <FontAwesomeIcon icon={faStethoscope} className={className} />
);
Icon.propTypes = {
  className: PropTypes.string.isRequired,
};

const StyledIcon = styled(Icon)`
  margin-right: 0.5rem;
`;

const LogoComponent = () => (
  <Logo>
    <StyledIcon />
    <Links to="/">Portalul certificatelor de concediu medical</Links>
    {/* <span>Concedii Medicale</span> */}
  </Logo>
);

export default LogoComponent;
