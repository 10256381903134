import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Form, DataTable, Button } from 'carbon-components-react';
import Add24 from '@carbon/icons-react/es/add/24';
import Edit16 from '@carbon/icons-react/es/edit/16';
// import Delete16 from '@carbon/icons-react/es/delete/16';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { history } from '../../helpers/history';
import SearcheableTable from '../../components/table/SearcheableTable';
import AlchemySelect from '../../components/form/AlchemySelect';
import { taxonomyActions } from '../../redux/actions';
import '../style.scss';
import AlchemyNotification from '../../components/form/AlchemyNotification';

const Content = styled.div`
  overflow-x: hidden;
`;

const TitleModule = styled.h3`
  margin: 1rem 3rem;
  font-size: 1.5rem;
  font-weight: bold;
`;

const ContentModule = styled.div`
  padding: 1rem 3rem;
`;

const IconTable = styled.div`
  display: flex;
  justify-content: flex-start;
`;

// const Delete16Icon = styled(Delete16)`
//   cursor: pointer;
// `;

const Edit16Icon = styled(Edit16)`
  margin-right: 16px;
`;

class TaxonomyManagementForm extends React.PureComponent {
  static propTypes = {
    getAllTaxonomiesWithoutContent: PropTypes.instanceOf(Function).isRequired,
    taxonomiesWithoutContent: PropTypes.instanceOf(Function).isRequired,
    getPage: PropTypes.instanceOf(Function).isRequired,
    // deleteTaxonomy: PropTypes.instanceOf(Function).isRequired,
    pageDetails: PropTypes.instanceOf(Object).isRequired,
    columnDetails: PropTypes.instanceOf(Object).isRequired,
    taxonomyEntity: PropTypes.instanceOf(Object).isRequired,
    match: PropTypes.instanceOf(Object).isRequired,
    getColumnDetails: PropTypes.instanceOf(Function).isRequired,
  };

  state = {
    selectedTaxonomy: null,
    loadTaxonomyValues: true,
  };

  componentDidMount() {
    const { getAllTaxonomiesWithoutContent } = this.props;
    getAllTaxonomiesWithoutContent();
  }

  findArrayElementById = (array, id) => {
    return array.find(element => {
      return element.id === id;
    });
  };

  getTaxonomyValues = e => {
    const { taxonomiesWithoutContent, getPage, getColumnDetails } = this.props;
    const taxonomyObj = this.findArrayElementById(taxonomiesWithoutContent, e);
    this.setState({ selectedTaxonomy: e });
    getColumnDetails(taxonomyObj.name);
    getPage({
      sortDirection: null,
      page: 1,
      pageSize: 10,
      orderBy: null,
      searchCriteria: null,
      entityName: taxonomyObj.name,
    });
  };

  // deleteTaxonomy = (id, entityName) => {
  //   const { deleteTaxonomy } = this.props;
  //   deleteTaxonomy(id, entityName);
  // };

  createTableHeader = data => {
    const newData = [];
    if (data) {
      data.forEach(element => {
        if (element.isUsedAsTableHeader) {
          newData.push({
            code: element.columnName,
            description: element.columnDescription,
          });
        }
      });
      newData.push({ code: 'actions', description: 'Actiuni' });
    }
    return newData;
  };

  getColumnType = columnName => {
    const { columnDetails } = this.props;
    const detail = columnDetails.find(c => c.columnName === columnName);
    return detail ? detail.dataType : null;
  };

  render() {
    const {
      taxonomiesWithoutContent,
      pageDetails,
      columnDetails,
      taxonomyEntity,
      getPage,
      match,
    } = this.props;
    const { selectedTaxonomy, loadTaxonomyValues } = this.state;

    // initialize table values
    const taxonomyObj =
      taxonomyEntity && selectedTaxonomy
        ? this.findArrayElementById(taxonomiesWithoutContent, selectedTaxonomy)
        : null;
    const headers = taxonomyEntity ? this.createTableHeader(columnDetails) : [];
    const rows = taxonomyEntity || [];

    // in case of back need to repopulate table
    const { params } = match;
    const { entityName } = params;
    if (
      entityName &&
      taxonomiesWithoutContent &&
      taxonomiesWithoutContent.length > 0 &&
      loadTaxonomyValues
    ) {
      this.getTaxonomyValues(entityName);
      this.setState({ loadTaxonomyValues: false });
    }

    return (
      <Content>
        <TitleModule>Administrare nomenclatoare</TitleModule>
        <AlchemyNotification />
        <React.Fragment>
          <ContentModule className="taxonomy-width">
            <Form className="box">
              <div className="box__quarter-block">
                <Field
                  name="id"
                  labelText="Lista nomenclatoare"
                  component={AlchemySelect}
                  options={taxonomiesWithoutContent}
                  onChange={event => this.getTaxonomyValues(event)}
                />
              </div>
            </Form>
          </ContentModule>
          {taxonomyEntity && taxonomyObj && columnDetails ? (
            <SearcheableTable
              title={taxonomyObj.description}
              headers={headers}
              rows={rows}
              getPage={getPage}
              pageDetails={pageDetails}
              searchFields={['description']}
              entityName={taxonomyObj.name}
              cellRendering={cell => {
                if (cell.id.includes(':actions')) {
                  const id = cell.id.match(/\d+/g)[0];
                  return (
                    <DataTable.TableCell key={cell.id}>
                      <IconTable>
                        <NavLink
                          to={`/edit-taxonomy/${id}/${taxonomyObj.name}/${
                            taxonomyObj.description
                          }`}
                        >
                          <Edit16Icon />
                        </NavLink>
                        {/* <Delete16Icon
                          onClick={() =>
                            this.deleteTaxonomy(id, taxonomyObj.name)
                          }
                        /> */}
                      </IconTable>
                    </DataTable.TableCell>
                  );
                }
                if (
                  cell.value &&
                  this.getColumnType(cell.info.header) === 'date'
                ) {
                  return (
                    <DataTable.TableCell key={cell.id}>
                      {new Date(cell.value).toLocaleDateString()}
                    </DataTable.TableCell>
                  );
                }
                return (
                  <DataTable.TableCell key={cell.id}>
                    {cell.value}
                  </DataTable.TableCell>
                );
              }}
            >
              <Button
                component="topButton"
                kind="primary"
                onClick={() =>
                  history.push(
                    `/new-taxonomy/${taxonomyObj.name}/${
                      taxonomyObj.description
                    }`,
                  )
                }
              >
                Adauga
                <Add24 className="bx--btn__icon" />
              </Button>
            </SearcheableTable>
          ) : null}
        </React.Fragment>
      </Content>
    );
  }
}

const ReduxForm = reduxForm({
  form: 'taxonomiesWithoutContentForm',
})(TaxonomyManagementForm);

export default connect(
  state => {
    const { taxonomies } = state;
    return {
      taxonomiesWithoutContent:
        taxonomies && taxonomies.taxonomiesWithoutContent
          ? taxonomies.taxonomiesWithoutContent.map(el => {
              return { ...el, id: el.name };
            })
          : [],
      columnDetails: taxonomies.columnDetails,
      taxonomyEntity:
        taxonomies && taxonomies.taxonomyEntity
          ? taxonomies.taxonomyEntity.content.map(u => {
              return {
                ...u,
                id: u.id.toString(),
              };
            })
          : null,
      pageDetails:
        taxonomies && taxonomies.taxonomyEntity
          ? {
              page: taxonomies.taxonomyEntity.number
                ? taxonomies.taxonomyEntity.number + 1
                : 1,
              totalItems: taxonomies.taxonomyEntity.totalElements
                ? taxonomies.taxonomyEntity.totalElements
                : 0,
              isLastPage: taxonomies.taxonomyEntity.last,
              pageSize: taxonomies.taxonomyEntity.size
                ? taxonomies.taxonomyEntity.size
                : 0,
            }
          : { page: 0, totalItems: 0, pageSize: 0 },
    };
  },
  {
    getAllTaxonomiesWithoutContent:
      taxonomyActions.getAllTaxonomiesWithoutContent,
    getPage: taxonomyActions.getPage,
    // deleteTaxonomy: taxonomyActions.deleteTaxonomy,
    getColumnDetails: taxonomyActions.getColumnDetails,
  },
)(ReduxForm);
