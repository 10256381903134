import { AlertActions } from '../types';

function success(message) {
  return { type: AlertActions.SUCCESS, message };
}

function error(message) {
  return {
    type: AlertActions.ERROR,
    message,
  };
}

function warning(message) {
  return { type: AlertActions.WARNING, message };
}

function clear() {
  return { type: AlertActions.CLEAR };
}

export const alertActions = {
  success,
  error,
  clear,
  warning,
};
