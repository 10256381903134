/* eslint-disable eqeqeq */
/* eslint-disable react/prop-types */
import decode from 'jwt-decode';
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Button, Form, Modal, DataTable } from 'carbon-components-react';
import Search16 from '@carbon/icons-react/es/search/16';
import WarningFilled20 from '@carbon/icons-react/es/warning--filled/20';
import { Field, reduxForm } from 'redux-form';
import get from 'lodash/get';
import Loading from 'carbon-components-react/lib/components/Loading';
import AlchemyAdvancedSelect from '../../components/form/AlchemyAdvancedSelect';
import BaseTable from '../../components/table/BaseTable';
import {
  concediiActions,
  alertActions,
  taxonomyActions,
} from '../../redux/actions';

import { history } from '../../helpers/history';
import AlchemyTextInput from '../../components/form/AlchemyTextInput';
import AlchemySelect from '../../components/form/AlchemySelect';
import '../style.scss';
import AlchemyDateTimePicker from '../../components/form/AlchemyDateTimePicker';
import AlchemyNotification from '../../components/form/AlchemyNotification';
import * as validation from '../../helpers/validation';
import { $brand01, $ui01, $ui05 } from '../../themeVariables';
import ConcediiList from './ConcediiList';
import ConcediiHead from './ConcediiHead';

const MaternitatePersonStatuses = [
  {
    id: 1,
    code: '1',
    description: 'Asigurat',
    startDate: 1642636800000,
    endDate: null,
  },
  {
    id: 2,
    code: '2',
    description: 'Neasigurat',
    startDate: 1642636800000,
    endDate: null,
  },
  {
    id: 3,
    code: '3',
    description: 'Șomer',
    startDate: 1642636800000,
    endDate: null,
  },
];

const headers = [
  { code: 'nr', description: 'Nr. certificat' },
  { code: 'institution', description: 'Institutie emitentă' },
  { code: 'sendDate', description: 'Data emiterii' },
  { code: 'startDate', description: 'De la' },
  { code: 'endDate', description: 'Pina la' },
  { code: 'statut', description: 'Statut' },
];

const headersConsecutives = [
  { code: 'nrCertificate', description: 'Nr. certificat' },
  { code: 'type', description: 'Tip certificat' },
  { code: 'institution', description: 'Institutie emitentă' },
  { code: 'startDate', description: 'De la' },
  { code: 'endDate', description: 'Pina la' },
  { code: 'category', description: 'Categorie certificat' },
  { code: 'statut', description: 'Statut certificat' },
  { code: 'totalDays', description: 'Numar de zile' },
];

const headersHistory = [
  { code: 'nrCertificate', description: 'Seria și numarul certificatului' },
  { code: 'dateCompletion', description: 'Data completării' },
  { code: 'startDate', description: 'De la' },
  { code: 'endDate', description: 'Pina la' },
  { code: 'type', description: 'Tip certificat' },
  { code: 'category', description: 'Categorie certificat' },
  { code: 'statut', description: 'Statut certificat' },
  { code: 'registrator', description: 'Registrator' },
  { code: 'validator', description: 'Validator' },
  { code: 'institution', description: 'Institutie' },
  { code: 'phoneNumberValidator', description: 'Date de contact' },
];

const Content = styled.div`
  overflow-x: hidden;
  background: #f3f3f3;
`;

const PTButton = styled.div`
  padding-top: 1rem;
`;

const TitleModule = styled.h3`
  padding: 1rem 10rem;
  background: #f3f3f3;
  font-size: 1.75rem;
  @media (max-width: 1100px) {
    padding: 1rem;
  }
`;

const FooterButtons = styled.div`
  padding-top: 3rem;
  padding-bottom: 3rem;
`;

// const AlertRight = styled.div`
//   display: grid;
//   grid-template-columns: 8fr 4fr;
//   @media (max-width: 1100px) {
//     grid-template-columns: 6fr 6fr;
//   }
// `;

// const AlertRightDouble = styled.div`
//   display: grid;
//   grid-column-gap: 15px;
//   grid-template-columns: 4fr 8fr;
// `;

const AlertConcediu = styled.div`
  border-left: 2.5px solid #da1e28;
  padding-bottom: 5px;
  padding-top: 5px;
  display: grid;
  grid-template-columns: 2rem 1fr;
  max-width: 920px;
  column-gap: 1rem;
  background: #f3f3f3;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
`;

const AlertIcon = styled.div`
  padding-left: 1rem;
`;

const AlertMessage = styled.p`
  color: #da1e28;
  font-size: 1rem;
`;

// const AlertRightMessage = styled.p`
//   color: #da1e28;
//   font-size: 0.75rem;
// `;

const WarningFilled20Icon = styled(WarningFilled20)`
  fill: #da1e28;
`;

const Label = styled.label`
  font-size: 0.75rem;
`;

const Input = styled.input`
  font-size: 0.875rem;
  font-weight: 400;
  width: 100%;
  background: ${$ui01};
  padding: 0 1rem;
  margin: 8px 0;
  box-sizing: border-box;
  border: 0.2rem solid transparent;
  -webkit-transition: 0.1s;
  transition: 0.1s;
  outline: 2px solid #e0e0e0;
  outline-offset: -2px;
  background: #fff;
  height: 2.5rem;

  &::placeholder {
    color: #8c8c8c;
  }
  &:focus {
    color: ${$ui05};
    outline: 2px solid ${$brand01};
  }
`;

// eslint-disable-next-line react/prop-types
class ConcediuForm extends React.PureComponent {
  state = {
    saveModal: false,
    backModal: false,
    validateModal: false,
    totalDaysExtracted: false,
    disabledSave: false,
    changeTypeModal: false,
    changeTypeApproved: false,
    changeTypeReasonId: null,
  };

  componentDidMount() {
    const { match, getConcediu, getServerDate } = this.props;
    const { params } = match;
    const { id } = params;
    if (id) {
      getConcediu(id);
    } else {
      getServerDate();
    }
    this.myRef = React.forwardRef();
    this.form = React.forwardRef();
  }

  getDateWithoutTime = value => {
    if (value) {
      const valueDate = new Date(value);
      valueDate.setHours(0, 0, 0, 0);
      return valueDate.toLocaleString().substring(0, 10);
    }
    return '';
  };

  setChangeTypeApproved = () => {
    this.setState(state => ({
      changeTypeApproved: !state.changeTypeApproved,
    }));
  };

  setChangeTypeReason = reasonId => {
    this.setState(() => ({
      changeTypeReasonId: reasonId,
    }));
  };

  getMaxBreachStartDate() {
    const { reduxFormData } = this.props;

    if (
      get(reduxFormData, 'endDate', null) &&
      get(reduxFormData, 'dateEndBreach', null)
    ) {
      const endDate = get(reduxFormData, 'endDate', null);
      const dateEndBreach = get(reduxFormData, 'dateEndBreach', null);
      if (endDate < dateEndBreach) {
        return get(reduxFormData, 'endDate', null);
      }
      return get(reduxFormData, 'dateEndBreach', null);
    }
    if (get(reduxFormData, 'endDate', null)) {
      return get(reduxFormData, 'endDate', null);
    }
    return get(reduxFormData, 'dateEndBreach', null);
  }

  getMinBreachEndDate() {
    const { reduxFormData } = this.props;

    if (
      get(reduxFormData, 'startDate', null) &&
      get(reduxFormData, 'dateStartBreach', null)
    ) {
      const startDate = get(reduxFormData, 'startDate', null);
      const dateStartBreach = get(reduxFormData, 'dateStartBreach', null);
      if (startDate > dateStartBreach) {
        return get(reduxFormData, 'startDate', null);
      }
      return get(reduxFormData, 'dateStartBreach', null);
    }
    if (get(reduxFormData, 'startDate', null)) {
      return get(reduxFormData, 'startDate', null);
    }
    return get(reduxFormData, 'dateStartBreach', null);
  }

  getMinMaternitateStartDate() {
    const { reduxFormData } = this.props;
    if (get(reduxFormData, 'incapacityCauseCategory.id', null) == '8') {
      const d = new Date();
      d.setFullYear(d.getFullYear() - 1);
      return d;
    }
    return new Date(Date.UTC(2019, 6, 1, 0, 0, 0));
  }

  getMaxEndDate() {
    const { reduxFormData } = this.props;
    if (get(reduxFormData, 'incapacityCauseCategory.id', null) == '8') {
      return new Date(Date.UTC(2100, 1, 1, 0, 0, 0));
    }
    return new Date();
  }

  handleChangeIncapacityCauseCategory = () => {
    const { reduxFormData } = this.props;
    reduxFormData.incapacityCauseSubcategory = null;
    if (reduxFormData.personStatus) reduxFormData.personStatus.id = null;
    if (reduxFormData.childDetails) reduxFormData.childDetails = null;
    if (reduxFormData.relativeDegree) reduxFormData.relativeDegree = null;
    if (reduxFormData.birthCertificateNumber)
      reduxFormData.birthCertificateNumber = null;
    if (reduxFormData.birthCertificateSeries)
      reduxFormData.birthCertificateSeries = null;
    if (reduxFormData.husbandDetails) reduxFormData.husbandDetails = null;
  };

  handleMin3DaysPreviousCertificateEnDate = value => {
    const {
      reduxFormData,
      checkMin3DaysPreviousCertificateEnDate,
      match,
    } = this.props;
    const { params } = match;
    const { id } = params;

    if (
      value &&
      get(reduxFormData, 'type.id', null) == '2' &&
      reduxFormData.patientDetails &&
      reduxFormData.patientDetails.cpas
    ) {
      checkMin3DaysPreviousCertificateEnDate(
        value,
        get(reduxFormData, 'patientDetails.cpas', null),
        id || -1,
      );
    }
  };

  handlePlus3DaysNextCertificateStartDate = value => {
    const {
      reduxFormData,
      checkPlus3DaysNextCertificateStartDate,
      match,
    } = this.props;
    const { params } = match;
    const { id } = params;

    if (
      value &&
      reduxFormData.patientDetails &&
      reduxFormData.patientDetails.cpas
    ) {
      checkPlus3DaysNextCertificateStartDate(
        value,
        get(reduxFormData, 'patientDetails.cpas', null),
        id || -1,
      );
    }
    // checkMaternitatePeriod(
    //   get(reduxFormData, 'startDate', null),
    //   value,
    //   get(reduxFormData, 'incapacityCauseCategory.id', null),
    //   get(reduxFormData, 'incapacityCauseSubcategory.id', null),
    // );
  };

  saveStatus = () => {
    this.setState(state => ({
      saveModal: !state.saveModal,
    }));
  };

  backStatus = () => {
    this.setState(state => ({
      backModal: !state.backModal,
    }));
  };

  changeTypeStatus = () => {
    this.setState(state => ({
      changeTypeModal: !state.changeTypeModal,
    }));
  };

  validateStatus = () => {
    const { valid } = this.props;
    if (valid) {
      this.setState(state => ({
        validateModal: !state.validateModal,
      }));
    }
  };

  changeType = () => {
    const { match, changeTypeBE } = this.props;
    const { changeTypeReasonId } = this.state;
    const { params } = match;
    const { id } = params;
    this.setState({ changeTypeModal: false });
    changeTypeBE(id, changeTypeReasonId);
  };

  goToHomepage = () => {
    history.push('/');
  };

  getCurrentType = () => {
    const { reduxFormData, taxonomies } = this.props;
    const typeId = get(reduxFormData, 'type.id', null);
    if (taxonomies.NmCertificateTypes && typeId) {
      const some = taxonomies.NmCertificateTypes.find(el => el.id === typeId);
      if (some) {
        return some.description;
      }
    }
    return '';
  };

  getNextType = () => {
    const { reduxFormData, taxonomies } = this.props;
    const typeId = get(reduxFormData, 'type.id', null);
    if (taxonomies.NmCertificateTypes && typeId) {
      const some = taxonomies.NmCertificateTypes.find(el => el.id !== typeId);
      if (some) {
        return some.description;
      }
    }
    return '';
  };

  // checkPrimaryCertificate(event) {
  //   this.setState({ totalDaysExtracted: false });
  //   const { getPrimaryCertificateByCpas, reduxFormData, match } = this.props;
  //   const { params } = match;
  //   const { id } = params;
  //   const cpas =
  //     reduxFormData &&
  //     reduxFormData.patientDetails &&
  //     reduxFormData.patientDetails.cpas;
  //   if (event == '2') {
  //     if (cpas) {
  //       getPrimaryCertificateByCpas(cpas, id);
  //     }
  //   }
  // }

  handleDateCompletation() {
    const { match, serverDate, getServerDate } = this.props;
    const { params } = match;
    const { id } = params;
    // eslint-disable-next-line
    const reduxFormData = this.props.reduxFormData || {};
    if (!id && serverDate) {
      reduxFormData.dateCompletion = new Date(serverDate);
    }
    if (!serverDate) {
      getServerDate();
    }
  }

  handleTime() {
    const {
      getTotalDays,
      match,
      checkCertificatePeriodOverlap,
      checkMaternitatePeriod2,
    } = this.props;
    const { totalDaysExtracted } = this.state;
    const { params } = match;
    const { id } = params;

    // eslint-disable-next-line react/destructuring-assignment
    const reduxFormData = this.props.reduxFormData || {};
    let start = new Date(reduxFormData.startDate);
    start = new Date(
      Date.UTC(start.getFullYear(), start.getMonth(), start.getDate(), 0, 0, 0),
    );
    let end = new Date(reduxFormData.endDate);
    end = new Date(
      Date.UTC(end.getFullYear(), end.getMonth(), end.getDate(), 0, 0, 0),
    );
    const diffTime = Math.abs(end.getTime() - start.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    // Prev Certificat date
    const prevCertStart = new Date(reduxFormData.prevCertStartDate);
    const prevCertEnd = new Date(reduxFormData.prevCertEndDate);
    const prevdiffTime = Math.abs(
      prevCertEnd.getTime() - prevCertStart.getTime(),
    );
    const prevdiffDays = Math.ceil(prevdiffTime / (1000 * 60 * 60 * 24));
    reduxFormData.prevTakenDateCert = prevdiffDays;
    if (reduxFormData.startDate && reduxFormData.endDate) {
      reduxFormData.grantedDays = diffDays + 1;

      checkMaternitatePeriod2(
        diffDays + 1,
        get(reduxFormData, 'incapacityCauseCategory.id', null),
        get(reduxFormData, 'incapacityCauseSubcategory.id', null),
      );

      if (
        reduxFormData.patientDetails &&
        reduxFormData.patientDetails.cpas &&
        !totalDaysExtracted
      ) {
        const startDate =
          reduxFormData &&
          reduxFormData.startDate &&
          new Date(reduxFormData.startDate);
        startDate.setHours(3);
        const startDateISO = startDate.toISOString().split('T')[0];
        const endDate =
          reduxFormData &&
          reduxFormData.endDate &&
          new Date(reduxFormData.endDate);
        endDate.setHours(3);
        const endDateISO = endDate.toISOString().split('T')[0];
        getTotalDays(
          reduxFormData.patientDetails.cpas,
          id,
          startDateISO,
          endDateISO,
          reduxFormData.incapacityCauseCategory,
          reduxFormData.type,
        );
        this.setState({ totalDaysExtracted: true });
        checkCertificatePeriodOverlap(id);
      }
    }
    if (reduxFormData && reduxFormData.endDate) {
      const dateExitToWorksTemp = new Date(reduxFormData.endDate);
      dateExitToWorksTemp.setDate(dateExitToWorksTemp.getDate() + 1);
      reduxFormData.dateExitToWork = dateExitToWorksTemp;
    }
  }

  render() {
    const {
      match,
      taxonomies,
      createConcediu,
      editConcediu,
      handleSubmit,
      submitting,
      reduxFormData,
      getPatientByFilter,
      getChildByFilter,
      getHusbandDetailsByFilter,
      loading,
      showError,
      submitFailed,
      valid,
      consecutivesDetails,
    } = this.props;
    const { params } = match;
    const { id } = params;
    const {
      saveModal,
      backModal,
      validateModal,
      disabledSave,
      changeTypeModal,
      changeTypeApproved,
      changeTypeReasonId,
    } = this.state;

    const timeMachine = () => {
      setTimeout(() => this.setState({ disabledSave: false }), 1500);
    };

    if (submitFailed && !valid) {
      showError(`Exista cimpuri obligatorii necompletate`);
    }

    return (
      <React.Fragment>
        <Content>
          <TitleModule>
            Înregistrarea datelor din certificatele de concediu medical
          </TitleModule>
          <AlchemyNotification />
          {
            <Form
              className="box"
              onSubmit={handleSubmit(() => {
                return this.validateStatus();
              })}
            >
              <div className="box__container">
                <h1 className="box__title">Informatii generale</h1>
                <div className="box__triple-block">
                  <Field
                    name="username"
                    type="text"
                    component={AlchemyTextInput}
                    label="Persoana autorizata"
                    disabled
                    validate={validation.required}
                  />
                  <Field
                    name="institutionName"
                    type="text"
                    component={AlchemyTextInput}
                    label="Institutie"
                    disabled
                    validate={validation.required}
                  />
                  <Field
                    name="certificationPlace.id"
                    component={AlchemySelect}
                    labelText="Locul eliberarii"
                    options={taxonomies.NmCertificationPlaces}
                    validate={validation.required}
                  />
                </div>
                <div className="box__triple-block">
                  <Field
                    name="dateCompletion"
                    labelText="Data completarii"
                    component={AlchemyDateTimePicker}
                    showTimeSelect={false}
                    disabled
                    validate={validation.required}
                    maxDate={new Date()}
                  />
                  <Field
                    name="phoneNumberToken"
                    type="text"
                    component={AlchemyTextInput}
                    label="Numarul de contact"
                    disabled
                    validate={validation.required}
                  />
                </div>
                <h1 className="box__title">Certificat</h1>
                <div className="box__triple-block">
                  <Field
                    name="series"
                    type="text"
                    component={AlchemyTextInput}
                    label="Seria certificatului"
                    disabled={
                      get(reduxFormData, 'status.id', null) == '3' ||
                      get(reduxFormData, 'status.id', null) == '4' ||
                      get(reduxFormData, 'status.id', null) == '5' ||
                      get(reduxFormData, 'status.id', null) == '6' ||
                      get(reduxFormData, 'status.id', null) == '7' ||
                      get(reduxFormData, 'status.id', null) == '10' ||
                      get(reduxFormData, 'status.id', null) == '12'
                    }
                    validate={[
                      validation.required,
                      validation.maxLength2,
                      validation.minLength2,
                    ]}
                  />
                  <Field
                    name="number"
                    type="text"
                    component={AlchemyTextInput}
                    label="Numarul certificatului"
                    disabled={
                      get(reduxFormData, 'status.id', null) == '3' ||
                      get(reduxFormData, 'status.id', null) == '4' ||
                      get(reduxFormData, 'status.id', null) == '5' ||
                      get(reduxFormData, 'status.id', null) == '6' ||
                      get(reduxFormData, 'status.id', null) == '7' ||
                      get(reduxFormData, 'status.id', null) == '10' ||
                      get(reduxFormData, 'status.id', null) == '12'
                    }
                    validate={[validation.required, validation.maxLength8]}
                  />
                </div>
                <div className="box__triple-block">
                  <Field
                    name="type.id"
                    labelText="Tipul certificatului"
                    component={AlchemySelect}
                    options={taxonomies.NmCertificateTypes}
                    validate={validation.required}
                    disabled={
                      get(reduxFormData, 'status.id', null) == '3' ||
                      get(reduxFormData, 'status.id', null) == '4' ||
                      get(reduxFormData, 'status.id', null) == '5' ||
                      get(reduxFormData, 'status.id', null) == '6' ||
                      get(reduxFormData, 'status.id', null) == '7' ||
                      get(reduxFormData, 'status.id', null) == '10' ||
                      get(reduxFormData, 'status.id', null) == '12' ||
                      get(reduxFormData, 'status.id', null) == '13'
                    }
                    // onChange={event => this.checkPrimaryCertificate(event)}
                  />
                  <PTButton>
                    <button
                      onClick={() => this.changeTypeStatus()}
                      tabIndex={0}
                      className="some-class bx--btn bx--btn--tertiary bx--btn--sm bx--btn--sm-2"
                      disabled={
                        get(reduxFormData, 'status.id', null) == '1' ||
                        get(reduxFormData, 'status.id', null) == '2' ||
                        get(reduxFormData, 'status.id', null) == '12' ||
                        get(reduxFormData, 'status.id', null) == '15' ||
                        !get(reduxFormData, 'status.id', null)
                      }
                      type="button"
                    >
                      Modifică tip
                    </button>
                  </PTButton>
                </div>
                <h1 className="box__title">Cauza incapacitatii de munca</h1>
                <div className="box__triple-block">
                  <Field
                    name="incapacityCauseCategory.id"
                    labelText="Categoria incapacitatii de munca"
                    component={AlchemySelect}
                    options={taxonomies.NmIncapacityCauseCategory}
                    validate={validation.required}
                    onChange={() => this.handleChangeIncapacityCauseCategory()}
                  />
                  <Field
                    name="incapacityCauseSubcategory.id"
                    labelText="Subcategorie incapacitatii de munca"
                    component={AlchemySelect}
                    options={taxonomies.NmIncapacityCauseSubcategory}
                    parentTaxonomy={taxonomies.NmIncapacityCauseCategory}
                    parentIdField="nmIncapacityCauseCategory"
                    parentId={get(
                      reduxFormData,
                      'incapacityCauseCategory.id',
                      null,
                    )}
                  />
                  <Field
                    name="regimen.id"
                    labelText="Regim"
                    component={AlchemySelect}
                    options={taxonomies.NmRegimens}
                    validate={validation.required}
                  />
                </div>
                <h1 className="box__title">Pacientul</h1>
                <AlertConcediu>
                  <AlertIcon>
                    <WarningFilled20Icon />
                  </AlertIcon>
                  <div>
                    <AlertMessage>
                      Dumneavoastră purtați responsabilitate administrativă,
                      civilă și penală de corectitudinea datelor introduse în
                      sistem
                    </AlertMessage>
                  </div>
                </AlertConcediu>
                {!loading ? (
                  <React.Fragment>
                    <div className="box__quarter-block">
                      <Field
                        name="patientDetails.idnp"
                        type="text"
                        component={AlchemyTextInput}
                        disabled={
                          get(reduxFormData, 'status.id', null) == '5' ||
                          get(reduxFormData, 'status.id', null) == '6' ||
                          get(reduxFormData, 'status.id', null) == '7' ||
                          get(reduxFormData, 'status.id', null) == '12'
                        }
                        label="IDNP sau CPAS"
                        validate={[
                          validation.required,
                          validation.number,
                          validation.minLength11,
                          validation.maxLength13,
                        ]}
                      />
                      {get(reduxFormData, 'incapacityCauseCategory.id', null) !=
                        '8' && (
                        <Field
                          name="personStatus.id"
                          labelText="Statutul pacientului"
                          component={AlchemySelect}
                          options={taxonomies.NmPersonStatuses}
                          validate={validation.required}
                        />
                      )}
                      {get(reduxFormData, 'incapacityCauseCategory.id', null) ==
                        '8' && (
                        <Field
                          name="personStatusMaternitateId"
                          labelText="Statutul pacientului"
                          component={AlchemySelect}
                          options={MaternitatePersonStatuses}
                          validate={validation.required}
                        />
                      )}
                      <PTButton>
                        <button
                          onClick={() => {
                            this.setState({ totalDaysExtracted: false });
                            reduxFormData.certificateConsecutives = [];
                            getPatientByFilter(reduxFormData.patientDetails);
                          }}
                          tabIndex={0}
                          className="some-class bx--btn bx--btn--tertiary bx--btn--sm bx--btn--sm-2"
                          disabled={false}
                          type="button"
                        >
                          Cauta
                          <Search16
                            aria-label=""
                            className="bx--btn__icon"
                            aria-hidden
                          />
                        </button>
                      </PTButton>
                    </div>
                    <div className="box__quarter-block">
                      <Field
                        name="patientDetails.lastName"
                        type="text"
                        component={AlchemyTextInput}
                        label="Nume"
                        disabled
                        validate={[validation.required, validation.maxLength40]}
                      />
                      <Field
                        name="patientDetails.firstName"
                        type="text"
                        component={AlchemyTextInput}
                        label="Prenume"
                        disabled
                        validate={validation.required}
                      />
                      <Field
                        name="patientDetails.dateBirth"
                        labelText="Data nasterii"
                        component={AlchemyDateTimePicker}
                        showTimeSelect={false}
                        disabled
                        validate={validation.required}
                      />
                    </div>
                  </React.Fragment>
                ) : (
                  <Loading />
                )}
                {reduxFormData &&
                  reduxFormData.patientDetails &&
                  reduxFormData.patientDetails.certificateHistory && (
                    <React.Fragment>
                      <div>
                        <h1 className="box__title">Date istorice</h1>
                        <table className="history__data-table">
                          <tbody>
                            <tr>
                              {headersHistory.map(item => (
                                <ConcediiHead item={item} />
                              ))}
                            </tr>

                            {reduxFormData.patientDetails.certificateHistory.map(
                              item => (
                                <ConcediiList
                                  item={item}
                                  getDateWithoutTime={this.getDateWithoutTime}
                                />
                              ),
                            )}
                          </tbody>
                        </table>
                        {/* <BaseTable
                          height={400}
                          headers={headersHistory}
                          rows={reduxFormData.patientDetails.certificateHistory.map(
                            el => ({
                              ...el,
                              id: el.id.toString(),
                            }),
                          )}
                          cellRendering={cell => {
                            if (
                              cell.id.includes(':dateCompletion') ||
                              cell.id.includes(':startDate') ||
                              cell.id.includes(':endDate')
                            ) {
                              return (
                                <DataTable.TableCell key={cell.id}>
                                  {this.getDateWithoutTime(cell.value)}
                                </DataTable.TableCell>
                              );
                            }
                            return (
                              <DataTable.TableCell key={cell.id}>
                                {cell.value}
                              </DataTable.TableCell>
                            );
                          }}
                        /> */}
                      </div>
                    </React.Fragment>
                  )}
                {get(reduxFormData, 'incapacityCauseCategory.id', null) ==
                  '2' && (
                  <React.Fragment>
                    <h1 className="box__title">Copil</h1>
                    <AlertConcediu>
                      <AlertIcon>
                        <WarningFilled20Icon />
                      </AlertIcon>
                      <div>
                        <AlertMessage>
                          Dumneavoastră purtați responsabilitate administrativă,
                          civilă și penală de corectitudinea datelor introduse
                          în sistem
                        </AlertMessage>
                      </div>
                    </AlertConcediu>
                    {!loading ? (
                      <React.Fragment>
                        <div className="box__quarter-block">
                          <Field
                            className="mt-16"
                            name="childDetails.idnp"
                            type="text"
                            component={AlchemyTextInput}
                            label="IDNP"
                            validate={[
                              validation.required,
                              validation.number,
                              validation.minLength11,
                              validation.maxLength13,
                            ]}
                          />
                          <div className="box__double-block mt-15">
                            <Field
                              type="text"
                              name="birthCertificateSeries"
                              label="Seria certificatului de nastere"
                              component={AlchemyTextInput}
                              validate={validation.required}
                            />
                            <Field
                              name="birthCertificateNumber"
                              label="Numarul certificat de nastere"
                              component={AlchemyTextInput}
                              validate={validation.required}
                            />
                          </div>
                          <Field
                            className="mt-16"
                            name="relativeDegree.id"
                            labelText="Grad de afinitate"
                            component={AlchemySelect}
                            options={taxonomies.NmRelativeDegree}
                            validate={validation.required}
                          />
                          <PTButton className="mt-2r">
                            <button
                              onClick={() =>
                                getChildByFilter(reduxFormData.childDetails)
                              }
                              tabIndex={0}
                              className="some-class bx--btn bx--btn--tertiary bx--btn--sm bx--btn--sm-2"
                              disabled={false}
                              type="button"
                            >
                              Cauta
                              <Search16
                                aria-label=""
                                className="bx--btn__icon"
                                aria-hidden
                              />
                            </button>
                          </PTButton>
                        </div>
                        <div className="box__quarter-block">
                          <Field
                            name="childDetails.lastName"
                            type="text"
                            component={AlchemyTextInput}
                            label="Nume copil"
                            disabled
                            validate={[
                              validation.required,
                              validation.maxLength40,
                            ]}
                          />
                          <Field
                            name="childDetails.firstName"
                            type="text"
                            component={AlchemyTextInput}
                            label="Prenume copil"
                            validate={[
                              validation.required,
                              validation.maxLength40,
                            ]}
                            disabled
                          />
                          <Field
                            name="childDetails.fatherName"
                            type="text"
                            component={AlchemyTextInput}
                            label="Patronimicul copil"
                            disabled
                            validate={[validation.maxLength40]}
                          />
                        </div>
                        <div className="box__quarter-block">
                          <Field
                            name="childDetails.birthDate"
                            labelText="Data nasterii copil"
                            component={AlchemyDateTimePicker}
                            showTimeSelect={false}
                            validate={validation.required}
                            disabled
                          />
                        </div>
                      </React.Fragment>
                    ) : (
                      <Loading />
                    )}
                  </React.Fragment>
                )}
                {get(reduxFormData, 'incapacityCauseCategory.id', null) ==
                  '8' &&
                  get(reduxFormData, 'incapacityCauseSubcategory.id', null) ==
                    '10' && (
                    <React.Fragment>
                      <h1 className="box__title">Soț</h1>
                      <AlertConcediu>
                        <AlertIcon>
                          <WarningFilled20Icon />
                        </AlertIcon>
                        <div>
                          <AlertMessage>
                            Dumneavoastră purtați responsabilitate
                            administrativă, civilă și penală de corectitudinea
                            datelor introduse în sistem
                          </AlertMessage>
                        </div>
                      </AlertConcediu>
                      {!loading ? (
                        <React.Fragment>
                          <div className="box__quarter-block">
                            <Field
                              name="husbandDetails.idnp"
                              type="text"
                              component={AlchemyTextInput}
                              label="IDNP sau CPAS"
                              validate={[
                                validation.required,
                                validation.number,
                                validation.minLength11,
                                validation.maxLength13,
                              ]}
                            />
                            <Field
                              name="husbandDetails.marriageDate"
                              labelText="Data căsătorie"
                              component={AlchemyDateTimePicker}
                              showTimeSelect={false}
                              validate={validation.required}
                            />
                            <PTButton>
                              <button
                                onClick={() =>
                                  getHusbandDetailsByFilter(
                                    reduxFormData.husbandDetails,
                                  )
                                }
                                tabIndex={0}
                                className="some-class bx--btn bx--btn--tertiary bx--btn--sm bx--btn--sm-2"
                                disabled={false}
                                type="button"
                              >
                                Cauta
                                <Search16
                                  aria-label=""
                                  className="bx--btn__icon"
                                  aria-hidden
                                />
                              </button>
                            </PTButton>
                          </div>
                          <div className="box__quarter-block">
                            <Field
                              name="husbandDetails.lastName"
                              type="text"
                              component={AlchemyTextInput}
                              label="Nume"
                              disabled
                              validate={[
                                validation.required,
                                validation.maxLength40,
                              ]}
                            />
                            <Field
                              name="husbandDetails.firstName"
                              type="text"
                              component={AlchemyTextInput}
                              label="Prenume"
                              disabled
                              validate={validation.required}
                            />
                            <Field
                              name="husbandDetails.dateBirth"
                              labelText="Data nasterii"
                              component={AlchemyDateTimePicker}
                              showTimeSelect={false}
                              disabled
                              validate={validation.required}
                            />
                          </div>
                        </React.Fragment>
                      ) : (
                        <Loading />
                      )}
                    </React.Fragment>
                  )}
                {reduxFormData && reduxFormData.NmInvolvedPersons ? (
                  <React.Fragment>
                    <div className="box__quarter-block">
                      <Field
                        name="childDetails.idnp"
                        type="text"
                        component={AlchemyTextInput}
                        label="IDNP sau CPAS copil"
                        validate={[
                          validation.required,
                          validation.number,
                          validation.minLength11,
                          validation.maxLength13,
                        ]}
                      />
                      <div className="box__double-block">
                        <Field
                          name="childDetails.birthCertificateSeries"
                          label="Serie certificat"
                          component={AlchemyTextInput}
                          validate={[
                            validation.required,
                            validation.maxLength25,
                          ]}
                        />
                        <Field
                          name="childDetails.birthCertificateNumber"
                          label="Numar certificat"
                          component={AlchemyTextInput}
                          validate={[
                            validation.required,
                            validation.maxLength25,
                          ]}
                        />
                      </div>
                      <Field
                        name="childDetails.relativeDegree.id"
                        labelText="Grad de afinitate"
                        component={AlchemySelect}
                        options={taxonomies.NmRelativeDegree}
                        validate={validation.required}
                      />
                      <PTButton>
                        <button
                          onClick={() =>
                            reduxFormData &&
                            reduxFormData.childDetails &&
                            getChildByFilter(reduxFormData.childDetails)
                          }
                          tabIndex={0}
                          className="some-class bx--btn bx--btn--tertiary bx--btn--sm bx--btn--sm-2"
                          disabled={false}
                          type="button"
                        >
                          Cauta
                          <Search16
                            aria-label=""
                            className="bx--btn__icon"
                            aria-hidden
                          />
                        </button>
                      </PTButton>
                    </div>
                    <div className="box__quarter-block">
                      <Field
                        name="childDetails.lastName"
                        type="text"
                        component={AlchemyTextInput}
                        label="Nume copil"
                        disabled
                        validate={[validation.required, validation.maxLength20]}
                      />
                      <Field
                        name="childDetails.firstName"
                        type="text"
                        component={AlchemyTextInput}
                        label="Prenume copil"
                        validate={[validation.required, validation.maxLength20]}
                      />
                      <Field
                        name="childDetails.firstName"
                        type="text"
                        component={AlchemyTextInput}
                        label="Patronimicul copil"
                        disabled
                        validate={[
                          validation.tooYoung,
                          validation.tooOldChild,
                          validation.maxLength40,
                        ]}
                      />
                      <Field
                        name="childDetails.birthDate"
                        labelText="Data nasterii copil"
                        component={AlchemyDateTimePicker}
                        showTimeSelect={false}
                        validate={validation.required}
                        maxDate={new Date()}
                      />
                    </div>
                  </React.Fragment>
                ) : null}
                {/* {get(reduxFormData, 'type.id', null) == '2' && (
                  <React.Fragment>
                    <h1 className="box__title">Certificatul precedent</h1>
                    <div className="box__triple-block">
                      <div className="box__double-block">
                        <Field
                          name="primaryCertificate.series"
                          type="text"
                          component={AlchemyTextInput}
                          label="Seria certificatului"
                          disabled
                        />
                        <Field
                          name="primaryCertificate.number"
                          type="text"
                          component={AlchemyTextInput}
                          label="Numarul certificatului"
                          disabled
                        />
                      </div>
                      <div className="box__double-block">
                        <Field
                          name="primaryCertificate.startDate"
                          labelText="De la"
                          component={AlchemyDateTimePicker}
                          showTimeSelect={false}
                          disabled
                        />
                        <Field
                          name="primaryCertificate.endDate"
                          labelText="Pina la"
                          component={AlchemyDateTimePicker}
                          showTimeSelect={false}
                          disabled
                        />
                      </div>
                      <Field
                        name="primaryCertificate.grantedDays"
                        label="Numar de zile"
                        component={AlchemyTextInput}
                        type="number"
                        disabled
                      />
                    </div>
                  </React.Fragment>
                )} */}
                <h1 className="box__title">Eliberat de la serviciu</h1>
                {/* <AlertRight>
                  <div />
                  <AlertRightDouble>
                    <div />
                    <AlertConcediu>
                      <AlertIcon>
                        <WarningFilled20Icon />
                      </AlertIcon>
                      <div>
                        <AlertRightMessage>
                          Total zile in anul calendaristic se calculează
                          începând cu data de 01.07.2019
                        </AlertRightMessage>
                      </div>
                    </AlertConcediu>
                  </AlertRightDouble>
                </AlertRight> */}
                {this.handleTime()}
                {this.handleDateCompletation()}
                <div className="box__triple-block">
                  <div className="margin-field">
                    <Field
                      name="startDate"
                      labelText="De la"
                      component={AlchemyDateTimePicker}
                      validate={validation.required}
                      maxDate={get(reduxFormData, 'endDate', null)}
                      minDate={this.getMinMaternitateStartDate()}
                      // disabled={
                      //   get(
                      //     reduxFormData,
                      //     'primaryCertificate.endDate',
                      //     null,
                      //   ) && get(reduxFormData, 'type.id', null) == 2
                      // }
                      onChange={value => {
                        this.setState({ totalDaysExtracted: false });
                        this.handleMin3DaysPreviousCertificateEnDate(value);
                      }}
                    />
                  </div>
                  <div className="margin-field">
                    <Field
                      name="endDate"
                      labelText="Pina la"
                      component={AlchemyDateTimePicker}
                      validate={validation.required}
                      minDate={get(reduxFormData, 'startDate', null)}
                      maxDate={this.getMaxEndDate()}
                      onChange={value => {
                        this.setState({ totalDaysExtracted: false });
                        this.handlePlus3DaysNextCertificateStartDate(value);
                      }}
                    />
                  </div>
                  <div className="box__double-block">
                    <div className="margin-field">
                      <Field
                        name="grantedDays"
                        label="Numar de zile"
                        component={AlchemyTextInput}
                        type="number"
                        disabled
                      />
                    </div>
                    <Field
                      name="totalDays"
                      label="Total zile in anul calendaristic"
                      component={AlchemyTextInput}
                      type="number"
                      disabled
                    />
                    <Field
                      name="continuousPeriod"
                      label="Perioadă continuă"
                      component={AlchemyTextInput}
                      type="number"
                      disabled
                    />
                  </div>
                </div>
                <div className="box__triple-block">
                  <Field
                    name="prescribed.id"
                    labelText="Inchiderea certificatului"
                    component={AlchemySelect}
                    options={taxonomies.NmPrescribed}
                    validate={validation.required}
                  />
                  {get(reduxFormData, 'prescribed.id', null) == '1' && (
                    <Field
                      name="dateExitToWork"
                      labelText="A începe lucrul de la"
                      component={AlchemyDateTimePicker}
                      showTimeSelect={false}
                      validate={validation.required}
                      disabled
                      minDate={get(reduxFormData, 'startDate', null)}
                    />
                  )}
                </div>
                <h1 className="box__title">Incalcare regim</h1>
                <div className="box__triple-block">
                  <Field
                    name="dateStartBreach"
                    labelText="Incalcat regim de la"
                    component={AlchemyDateTimePicker}
                    showTimeSelect={false}
                    maxDate={this.getMaxBreachStartDate()}
                    minDate={get(reduxFormData, 'startDate', null)}
                  />
                  <Field
                    name="dateEndBreach"
                    labelText="Incalcat regim pina la"
                    component={AlchemyDateTimePicker}
                    showTimeSelect={false}
                    maxDate={get(reduxFormData, 'endDate', null)}
                    minDate={this.getMinBreachEndDate()}
                  />
                  <Field
                    name="breachCause.id"
                    labelText="Motivul incalcarii"
                    component={AlchemySelect}
                    options={taxonomies.NmBreachCauses}
                  />
                </div>
                <FooterButtons>
                  {/* <Button
                    ref={this.simulateClick}
                    type="submit"
                    // className="invisible"
                    onPress={this.validateSubmit}
                  /> */}
                  <div className="box__triple-block">
                    <div className="box__double-block">
                      <Button
                        onClick={() => {
                          if (id) {
                            this.setState({
                              disabledSave: true,
                            });
                            timeMachine();
                            if (
                              get(reduxFormData, 'status.id', null) == '5' ||
                              get(reduxFormData, 'status.id', null) == '6' ||
                              get(reduxFormData, 'status.id', null) == '3' ||               
                              get(reduxFormData, 'status.id', null) == '10' ||
                              get(reduxFormData, 'status.id', null) == '14' ||
                              get(reduxFormData, 'status.id', null) == '7'
                            ) {
                              editConcediu(id, 12, 'SAVE');
                            } else {
                              editConcediu(id, null, 'SAVE');
                            }
                          } else {
                            this.setState({ disabledSave: true });
                            timeMachine();
                            createConcediu(1, 'SAVE');
                          }
                        }}
                        disabled={disabledSave}
                        kind="secondary"
                      >
                        Salveaza
                      </Button>
                      <Button onClick={this.backStatus} kind="danger">
                        Inapoi
                      </Button>
                    </div>
                    {!get(reduxFormData, 'status.id', null) ||
                    get(reduxFormData, 'status.id', null) == '1' ? (
                      <React.Fragment>
                        <div className="box__single-block" />
                        <div className="box__double-block">
                          <div className="box__single-block" />
                          <Button type="submit">Spre validare</Button>
                        </div>
                      </React.Fragment>
                    ) : null}
                  </div>
                </FooterButtons>
                {((consecutivesDetails && consecutivesDetails.length > 0) ||
                  (reduxFormData &&
                    reduxFormData.certificateConsecutives &&
                    reduxFormData.certificateConsecutives.length > 0)) && (
                  <React.Fragment>
                    <div>
                      <h1 className="box__title">Certificate consecutive</h1>
                      <BaseTable
                        headers={headersConsecutives}
                        rows={(
                          consecutivesDetails ||
                          (reduxFormData &&
                            reduxFormData.certificateConsecutives)
                        ).map(el => ({
                          ...el,
                          id: el.id.toString(),
                        }))}
                        cellRendering={cell => {
                          if (
                            cell.id.includes(':sendDate') ||
                            cell.id.includes(':startDate') ||
                            cell.id.includes(':endDate')
                          ) {
                            return (
                              <DataTable.TableCell
                                style={{
                                  backgroundColor:
                                    cell.id.split(':')[0] === id
                                      ? 'rgba(231, 76, 60, 0.5)'
                                      : 'inherit',
                                }}
                                key={cell.id}
                              >
                                {this.getDateWithoutTime(cell.value)}
                              </DataTable.TableCell>
                            );
                          }
                          return (
                            <DataTable.TableCell
                              style={{
                                backgroundColor:
                                  cell.id.split(':')[0] === id
                                    ? 'rgba(231, 76, 60, 0.5)'
                                    : 'inherit',
                              }}
                              key={cell.id}
                            >
                              {cell.value}
                            </DataTable.TableCell>
                          );
                        }}
                      />
                    </div>
                  </React.Fragment>
                )}
              </div>
            </Form>
          }
        </Content>
        <>
          <Modal
            className="other-modals"
            open={saveModal}
            onRequestClose={this.saveStatus}
            modalHeading="Salveaza"
            primaryButtonText="Da"
            secondaryButtonText="Nu"
            onSecondarySubmit={this.saveStatus}
            disabled={submitting}
          >
            <p>Sunteti sigur?</p>
          </Modal>
          <Modal
            className="other-modals"
            open={backModal}
            onRequestClose={this.backStatus}
            modalHeading="Inapoi"
            primaryButtonText="Da"
            secondaryButtonText="Nu"
            shouldSubmitOnEnter
            onSecondarySubmit={this.backStatus}
            onRequestSubmit={this.goToHomepage}
            disabled={submitting}
          >
            <p>Sunteti sigur?</p>
          </Modal>
          <Modal
            className="other-modals"
            open={validateModal}
            onRequestClose={this.validateStatus}
            modalHeading="Spre validare"
            primaryButtonText="Da"
            // size="xs"
            secondaryButtonText="Nu"
            shouldSubmitOnEnter
            onSecondarySubmit={this.validateStatus}
            onRequestSubmit={handleSubmit(() => {
              return id
                ? editConcediu(id, 2, 'VALIDATION')
                : createConcediu(2, 'VALIDATION');
            })}
            disabled={submitting}
          >
            <p>Sunteti sigur?</p>
          </Modal>
          <Modal
            size="lg"
            open={changeTypeModal}
            onRequestClose={this.changeTypeStatus}
            modalHeading="Modifică tip"
            primaryButtonText="Modifică și salvează"
            secondaryButtonText="Nu"
            onRequestSubmit={() => this.changeType()}
            onSecondarySubmit={this.changeTypeStatus}
            primaryButtonDisabled={
              (reduxFormData &&
                reduxFormData.certificatesChained &&
                reduxFormData.certificatesChained.length > 0 &&
                (!changeTypeApproved || !changeTypeReasonId)) ||
              !changeTypeReasonId
            }
          >
            <div className="large-modal">
              {get(reduxFormData, 'status.id', null) == 7 && (
                <AlertConcediu>
                  <AlertIcon>
                    <WarningFilled20Icon />
                  </AlertIcon>
                  <AlertMessage>
                    Certificatul medical selectat este transmis spre plată
                  </AlertMessage>
                </AlertConcediu>
              )}
              <>
                <div className="certificat-type">
                  <div>
                    <Label>Tip certificat curent</Label>
                    <Input
                      name="typeCurrent"
                      type="text"
                      disabled
                      value={this.getCurrentType()}
                    />
                    <Field
                      className="pidar"
                      name="changeTypeReason"
                      component={AlchemyAdvancedSelect}
                      options={
                        taxonomies && taxonomies.NmCertificateChangeTypeReason
                      }
                      onChange={value => this.setChangeTypeReason(value)}
                      labelText="Motivul modificarii"
                    />
                  </div>
                  <div>
                    <Label>Tip certificat dupa modificare</Label>
                    <Input
                      name="typeNext"
                      type="text"
                      disabled
                      value={this.getNextType()}
                    />
                  </div>
                </div>

                {reduxFormData &&
                  reduxFormData.certificatesChained &&
                  reduxFormData.certificatesChained.length > 0 && (
                    <>
                      <BaseTable
                        title="Certificate dependente"
                        headers={headers}
                        rows={reduxFormData.certificatesChained.map(el => ({
                          ...el,
                          id: el.id.toString(),
                        }))}
                        cellRendering={cell => {
                          if (
                            cell.id.includes(':sendDate') ||
                            cell.id.includes(':startDate') ||
                            cell.id.includes(':endDate')
                          ) {
                            return (
                              <DataTable.TableCell key={cell.id}>
                                {this.getDateWithoutTime(cell.value)}
                              </DataTable.TableCell>
                            );
                          }
                          return (
                            <DataTable.TableCell key={cell.id}>
                              {cell.value}
                            </DataTable.TableCell>
                          );
                        }}
                      />
                      <input
                        style={{ marginTop: '1rem' }}
                        name="confirmChangeType"
                        type="checkbox"
                        onClick={() => this.setChangeTypeApproved()}
                      />
                      <Label>
                        Înțeleg că modificarea tipului de certificat efectuată
                        de mine ar putea influența perioada de procesare și
                        plată pentru certificatele indicate mai sus
                      </Label>
                    </>
                  )}
              </>
            </div>
          </Modal>
        </>
      </React.Fragment>
    );
  }
}

const ReduxForm = reduxForm({
  form: 'concediuForm',
  enableReinitialize: true,
})(ConcediuForm);

export default connect(
  state => {
    const { taxonomies, concedii, form } = state;
    const {
      requestedConcediu,
      totalDays,
      continuousPeriod,
      loading,
    } = concedii;
    const { concediuForm } = form;
    const { values, valid } = concediuForm || {};
    const tokens = decode(sessionStorage.getItem('token'));

    return {
      taxonomies: taxonomies.taxonomies,
      serverDate: taxonomies.serverDate,
      initialValues: {
        certificationPlace: { id: 1 },
        ...values,
        ...requestedConcediu,
        isChild:
          (requestedConcediu && requestedConcediu.childDetails) ||
          (values && values.isChild),
        username: `${tokens.lastname} ${tokens.firstname}`,
        institutionName: `${tokens.institutionName}`,
        phoneNumberToken: `${tokens.phoneNumber}`,
        totalDays: totalDays + (values && values.grantedDays),
        continuousPeriod,
      },
      reduxFormData: values,
      consecutivesDetails:
        requestedConcediu && requestedConcediu.consecutivesDetails,
      formValid: valid,
      loading,
    };
  },
  {
    createConcediu: concediiActions.createConcediuReduxForm,
    editConcediu: concediiActions.editConcediuReduxForm,
    getConcediu: concediiActions.getConcediu,
    getPatientByFilter: concediiActions.getPatientByFilter,
    getChildByFilter: concediiActions.getChildByFilter,
    getHusbandDetailsByFilter: concediiActions.getHusbandDetailsByFilter,
    changeTypeBE: concediiActions.changeTypeBE,
    // getPrimaryCertificateByCpas: concediiActions.getPrimaryCertificateByCpas,
    showError: alertActions.error,
    clearPeriod: concediiActions.clearPeriod,
    getTotalDays: concediiActions.getTotalDays,
    getServerDate: taxonomyActions.getServerDate,
    checkCertificatePeriodOverlap:
      concediiActions.checkCertificatePeriodOverlap,
    checkMin3DaysPreviousCertificateEnDate:
      concediiActions.checkMin3DaysPreviousCertificateEnDate,
    checkPlus3DaysNextCertificateStartDate:
      concediiActions.checkPlus3DaysNextCertificateStartDate,
    // checkMaternitatePeriod: concediiActions.checkMaternitatePeriod,
    checkMaternitatePeriod2: concediiActions.checkMaternitatePeriod2,
  },
)(ReduxForm);
