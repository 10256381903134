import getLodash from 'lodash/get';
import { ConcediiActions } from '../types';
import { alertActions } from './alert.actions';
import { post, get, put, getNoToken } from '../../helpers';
import { history } from '../../helpers/history';

const isStringOrNumber = str => {
  if (typeof str === 'string' || typeof str === 'number') {
    return true;
  }
  return false;
};

const getPage = pageDetails => async dispatch => {
  if (!pageDetails.search || pageDetails.search.length < 1) {
    return;
  }
  dispatch({ type: ConcediiActions.CERTIFICATE_GETPAGE_REQUEST });
  let page = {
    search: pageDetails.search.filter(search => search.isPrecondition),
  };

  try {
    const response = await post(`/certificate/search`, pageDetails);
    const pageResp = await response.json();

    page = {
      ...page,
      ...pageResp,
    };

    dispatch({ type: ConcediiActions.CERTIFICATE_GETPAGE_SUCCESS, page });
  } catch (err) {
    page = {
      ...page,
      content: [],
    };
    dispatch({ type: ConcediiActions.CERTIFICATE_GETPAGE_FILTER_FAILED, page });
  }
};

const getPageNotifications = pageDetails => async dispatch => {
  // if (!pageDetails.search || pageDetails.search.length < 1) {
  //   return;
  // }
  dispatch({ type: ConcediiActions.CERTIFICATE_GET_NOTIFICATIONS_REQUEST });
  let page = {
    search: pageDetails.search.filter(search => search.isPrecondition),
  };

  try {
    const response = await post(
      `/certificate/notifications/search`,
      pageDetails,
    );
    const pageResp = await response.json();

    page = {
      ...page,
      ...pageResp,
    };

    dispatch({
      type: ConcediiActions.CERTIFICATE_GET_NOTIFICATIONS_SUCCESS,
      page,
    });
  } catch (err) {
    page = {
      ...page,
      content: [],
    };
    dispatch({
      type: ConcediiActions.CERTIFICATE_GET_NOTIFICATIONS_FAILED,
      page,
    });
  }
};

const getConcediuForm = getState => {
  const { form } = getState();
  const { concediuForm } = form;
  return concediuForm.values;
};

const checkIdOfSelectedFields = concediu => {
  const concediuCopy = concediu;
  if (!getLodash(concediuCopy, 'incapacityCauseCategory.id', null)) {
    concediuCopy.incapacityCauseCategory = null;
  }
  if (!getLodash(concediuCopy, 'incapacityCauseSubcategory.id', null)) {
    concediuCopy.incapacityCauseSubcategory = null;
  }
  if (!getLodash(concediuCopy, 'breachCause.id', null)) {
    concediuCopy.breachCause = null;
  }
  if (!getLodash(concediuCopy, 'certificationPlace.id', null)) {
    concediuCopy.certificationPlace = null;
  }
  if (!getLodash(concediuCopy, 'type.id', null)) {
    concediuCopy.type = null;
  }
  if (!getLodash(concediuCopy, 'regimen.id', null)) {
    concediuCopy.regimen = null;
  }
  if (!getLodash(concediuCopy, 'personStatus.id', null)) {
    concediuCopy.personStatus = null;
  }
  if (!getLodash(concediuCopy, 'prescribed.id', null)) {
    concediuCopy.prescribed = null;
  }
  if (!getLodash(concediuCopy, 'relativeDegree.id', null)) {
    concediuCopy.relativeDegree = null;
  }
  return concediuCopy;
};

const resetConcediuReduxForm = () => async dispatch => {
  dispatch({ type: ConcediiActions.CLEAR_REQUESTED_CONCEDIU });
  dispatch({
    type: ConcediiActions.RESET_THE_ENTIRE_FORM,
  });
};

const createConcediuReduxForm = (statusId, btnType) => async (
  dispatch,
  getState,
) => {
  let concediu = getConcediuForm(getState);
  concediu.id = null;
  dispatch({ type: ConcediiActions.CREATE_REQUEST });
  try {
    if (concediu.startDate && concediu.startDate < new Date('2019-06-30')) {
      throw new Error(
        'Data acordarii certificatului nu trebue sa fie mai mica de 01.07.2019',
      );
    }

    if (
      concediu.incapacityCauseCategory &&
      concediu.incapacityCauseSubcategory &&
      concediu.type &&
      // eslint-disable-next-line
      concediu.incapacityCauseCategory.id == 8 &&
      // eslint-disable-next-line
      concediu.incapacityCauseSubcategory.id == 11 &&
      // eslint-disable-next-line
      concediu.type.id == 1
    ) {
      throw new Error(
        'Nu se accepta certificat primar pentru maternitate prelungita pe 14 zile',
      );
    }

    if (
      concediu.startDate &&
      concediu.endDate &&
      concediu.startDate > concediu.endDate
    ) {
      throw new Error(
        'Data acordarii certificatului este mai mare ca data inchiderii certificatului',
      );
    }
    // eslint-disable-next-line
    if (
      (concediu.primaryCertificate && !concediu.primaryCertificate.id) ||
      // eslint-disable-next-line
      getLodash(concediu, 'type.id', null) != 2
    ) {
      concediu.primaryCertificate = null;
    }
    concediu = checkIdOfSelectedFields(concediu);
    // Work around
    if (concediu.startDate && !isStringOrNumber(concediu.startDate)) {
      concediu.startDate.setHours(3);
    }
    if (concediu.endDate && !isStringOrNumber(concediu.endDate)) {
      concediu.endDate.setHours(3);
    }
    if (concediu.dateExitToWork && !isStringOrNumber(concediu.dateExitToWork)) {
      concediu.dateExitToWork.setHours(3);
    }
    if (
      concediu.dateStartBreach &&
      !isStringOrNumber(concediu.dateStartBreach)
    ) {
      concediu.dateStartBreach.setHours(3);
    }
    if (concediu.dateEndBreach && !isStringOrNumber(concediu.dateEndBreach)) {
      concediu.dateEndBreach.setHours(3);
    }
    if (
      concediu.patientDetails &&
      concediu.patientDetails.dateBirth &&
      !isStringOrNumber(concediu.patientDetails.dateBirth)
    ) {
      concediu.patientDetails.dateBirth.setHours(3);
    }
    if (
      concediu.childDetails &&
      concediu.childDetails.birthDate &&
      !isStringOrNumber(concediu.childDetails.birthDate)
    ) {
      concediu.childDetails.birthDate.setHours(3);
    }
    if (
      concediu.husbandDetails &&
      concediu.husbandDetails.dateBirth &&
      !isStringOrNumber(concediu.husbandDetails.dateBirth)
    ) {
      concediu.husbandDetails.dateBirth.setHours(3);
    }
    if (
      concediu.husbandDetails &&
      concediu.husbandDetails.marriageDate &&
      !isStringOrNumber(concediu.husbandDetails.marriageDate)
    ) {
      concediu.husbandDetails.marriageDate.setHours(3);
    }
    if (concediu.series && concediu.number) {
      const response = await post(
        `/certificate/check-certificates-with-same-number`,
        concediu,
      );
      const existOtherCertificates = await response.json();
      if (existOtherCertificates) {
        throw new Error(
          'Exista deja certificate create cu acest numar si serie.',
        );
      }
    }
    concediu.status = { id: statusId };
    const response = await post(`/certificate`, concediu);
    const id = await response.json();
    const createdConcediu = { ...concediu, id };
    dispatch({ type: ConcediiActions.CREATE_SUCCESS, createdConcediu });
    dispatch(alertActions.clear());
    if (btnType === 'SAVE') {
      dispatch(alertActions.success('Datele au fost salvate cu succes'));
      setTimeout(() => history.push(`/concediu/${id}`), 1000);
    } else {
      history.push(`/`);
      history.push('/forma-concediu-medical');
    }
  } catch (error) {
    dispatch({ type: ConcediiActions.CREATE_FAILURE, error });
    dispatch(
      alertActions.error(error.message || 'Nu a putut fi salvat certificatul'),
    );
  }
};

const checkCertificatePeriodOverlap = id => async (dispatch, getState) => {
  const concediu = getConcediuForm(getState);
  if (id) {
    concediu.id = id;
  } else if (concediu.startDate && concediu.endDate) {
    concediu.startDate.setHours(3);
    concediu.endDate.setHours(3);
  }
  const response = await post(
    `/certificate/check-certificate-period-overlap`,
    concediu,
  );
  const overlap = await response.json();
  if (overlap) {
    dispatch(
      alertActions.warning(
        'Perioada certificatului se suprapune cu perioada altui certificat',
      ),
    );
  }
};

const checkMaternitatePeriod = (
  startDate,
  endDate,
  incapacityCauseCategoryId,
  incapacityCauseSubcategoryId,
) => async dispatch => {
  if (
    startDate &&
    endDate &&
    incapacityCauseCategoryId &&
    incapacityCauseSubcategoryId
  ) {
    let end = new Date(endDate);
    let start = new Date(startDate);
    start = new Date(
      Date.UTC(start.getFullYear(), start.getMonth(), start.getDate(), 0, 0, 0),
    );
    end = new Date(
      Date.UTC(end.getFullYear(), end.getMonth(), end.getDate(), 0, 0, 0),
    );
    const diffTime = Math.abs(end.getTime() - start.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
    if (
      // eslint-disable-next-line eqeqeq
      incapacityCauseCategoryId == 8 &&
      // eslint-disable-next-line eqeqeq
      (incapacityCauseSubcategoryId == 9 ||
        // eslint-disable-next-line eqeqeq
        incapacityCauseSubcategoryId == 10) &&
      diffDays !== 126 &&
      diffDays !== 140 &&
      diffDays !== 182
    ) {
      dispatch(
        alertActions.warning(
          'Numărul de zile este diferit de 126, 140 sau 182. Certificatul ar putea sa nu fie acceptat pentru plată',
        ),
      );
    } else if (
      // eslint-disable-next-line eqeqeq
      incapacityCauseCategoryId == 8 &&
      // eslint-disable-next-line eqeqeq
      incapacityCauseSubcategoryId == 11 &&
      diffDays !== 14
    ) {
      dispatch(
        alertActions.warning(
          'Numărul de zile este diferit de 14. Certificatul ar putea sa nu fie acceptat pentru plată',
        ),
      );
    } else if (
      // eslint-disable-next-line eqeqeq
      incapacityCauseCategoryId == 8 &&
      // eslint-disable-next-line eqeqeq
      incapacityCauseSubcategoryId == 12 &&
      diffDays > 70
    ) {
      dispatch(
        alertActions.warning(
          ' Numărul de zile este mai mare de 70. Certificatul ar putea sa nu fie acceptat pentru plată',
        ),
      );
    }
  }
};

const checkMaternitatePeriod2 = (
  grantedDays,
  incapacityCauseCategoryId,
  incapacityCauseSubcategoryId,
) => async dispatch => {
  if (
    grantedDays &&
    incapacityCauseCategoryId &&
    incapacityCauseSubcategoryId
  ) {
    if (
      // eslint-disable-next-line eqeqeq
      incapacityCauseCategoryId == 8 &&
      // eslint-disable-next-line eqeqeq
      (incapacityCauseSubcategoryId == 9 ||
        // eslint-disable-next-line eqeqeq
        incapacityCauseSubcategoryId == 10) &&
      grantedDays !== 126 &&
      grantedDays !== 140 &&
      grantedDays !== 182
    ) {
      dispatch(
        alertActions.warning(
          'Numărul de zile este diferit de 126, 140 sau 182. Certificatul ar putea sa nu fie acceptat pentru plată',
        ),
      );
    } else if (
      // eslint-disable-next-line eqeqeq
      incapacityCauseCategoryId == 8 &&
      // eslint-disable-next-line eqeqeq
      incapacityCauseSubcategoryId == 11 &&
      grantedDays !== 14
    ) {
      dispatch(
        alertActions.warning(
          'Numărul de zile este diferit de 14. Certificatul ar putea sa nu fie acceptat pentru plată',
        ),
      );
    } else if (
      // eslint-disable-next-line eqeqeq
      incapacityCauseCategoryId == 8 &&
      // eslint-disable-next-line eqeqeq
      incapacityCauseSubcategoryId == 12 &&
      grantedDays > 70
    ) {
      dispatch(
        alertActions.warning(
          ' Numărul de zile este mai mare de 70. Certificatul ar putea sa nu fie acceptat pentru plată',
        ),
      );
    }
  }
};

const checkMin3DaysPreviousCertificateEnDate = (
  startDate,
  cpas,
  certificateId,
) => async dispatch => {
  const startDateDate = new Date(startDate);
  startDateDate.setHours(3);
  const startDateISO = startDateDate.toISOString().split('T')[0];
  const response = await post(
    `/certificate/check-min-3days-previous-certificate-end-date/${startDateISO}/${cpas}/${certificateId}`,
  );
  const someResp = await response.json();

  if (someResp.result) {
    dispatch(alertActions.warning(someResp.result));
  }
};

const checkPlus3DaysNextCertificateStartDate = (
  endDate,
  cpas,
  certificateId,
) => async dispatch => {
  const endDateDate = new Date(endDate);
  endDateDate.setHours(3);
  const endDateISO = endDateDate.toISOString().split('T')[0];
  const response = await post(
    `/certificate/check-plus-3days-next-certificate-start-date/${endDateISO}/${cpas}/${certificateId}`,
  );
  const someResp = await response.json();

  if (someResp.result) {
    dispatch(alertActions.warning(someResp.result));
  }
};

const changeCertificateStatus = (certificateId, statusId) => async dispatch => {
  try {
    const response = await post(
      `/certificate/set-status/${certificateId}/${statusId}`,
    );
    const resp = await response.json();
    if (resp) {
      dispatch(
        alertActions.success('Statutul certificatului a fost modificat.'),
      );
    }
  } catch (error) {
    dispatch(
      alertActions.error(error.message || 'Nu a putut fi modificat statutul'),
    );
  }
};

const checkStatusCNAS = () => async dispatch => {
  try {
    await get(`/cnas/check-certificate-statuses-on-cnas`);
    dispatch(
      alertActions.success(
        'A fost lansat procesul de verificare a satutului certificatelor la CNAS.',
      ),
    );
  } catch (error) {
    dispatch(
      alertActions.error(
        'Nu a putut fi lansat procesul de verificare a satutului certificatelor la CNAS',
      ),
    );
  }
};

const sendCNAS = () => async dispatch => {
  try {
    await get(`/cnas/check-certificate-for-sending`);
    dispatch(
      alertActions.success(
        'A fost lansata procedura de expediere a certificatelor la CNAS.',
      ),
    );
  } catch (error) {
    dispatch(
      alertActions.error(
        'Nu a putut fi lansata procedura de expediere a certificatelor la CNAS',
      ),
    );
  }
};

const editConcediuReduxForm = (id, statusId, btnType) => async (
  dispatch,
  getState,
) => {
  let concediu = getConcediuForm(getState);

  // Work around
  if (concediu.startDate && !isStringOrNumber(concediu.startDate)) {
    concediu.startDate.setHours(3);
  }
  if (concediu.endDate && !isStringOrNumber(concediu.endDate)) {
    concediu.endDate.setHours(3);
  }
  if (concediu.dateExitToWork && !isStringOrNumber(concediu.dateExitToWork)) {
    concediu.dateExitToWork.setHours(3);
  }
  if (concediu.dateStartBreach && !isStringOrNumber(concediu.dateStartBreach)) {
    concediu.dateStartBreach.setHours(3);
  }
  if (concediu.dateEndBreach && !isStringOrNumber(concediu.dateEndBreach)) {
    concediu.dateEndBreach.setHours(3);
  }
  if (
    concediu.patientDetails &&
    concediu.patientDetails.dateBirth &&
    !isStringOrNumber(concediu.patientDetails.dateBirth)
  ) {
    concediu.patientDetails.dateBirth.setHours(3);
  }
  if (
    concediu.childDetails &&
    concediu.childDetails.birthDate &&
    !isStringOrNumber(concediu.childDetails.birthDate)
  ) {
    concediu.childDetails.birthDate.setHours(3);
  }
  if (
    concediu.husbandDetails &&
    concediu.husbandDetails.dateBirth &&
    !isStringOrNumber(concediu.husbandDetails.dateBirth)
  ) {
    concediu.husbandDetails.dateBirth.setHours(3);
  }
  if (
    concediu.husbandDetails &&
    concediu.husbandDetails.marriageDate &&
    !isStringOrNumber(concediu.husbandDetails.marriageDate)
  ) {
    concediu.husbandDetails.marriageDate.setHours(3);
  }
  if (id) {
    concediu.id = id;
  }
  if (
    (concediu.primaryCertificate && !concediu.primaryCertificate.id) ||
    // eslint-disable-next-line
    getLodash(concediu, 'type.id', null) != 2
  ) {
    concediu.primaryCertificate = null;
  }

  concediu = checkIdOfSelectedFields(concediu);
  dispatch({ type: ConcediiActions.EDIT_REQUEST });
  try {
    if (concediu.startDate && concediu.startDate < new Date('2019-06-30')) {
      throw new Error(
        'Data acordarii certificatului nu trebue sa fie mai mica de 01.07.2019',
      );
    }
    if (
      concediu.startDate &&
      concediu.endDate &&
      concediu.startDate > concediu.endDate
    ) {
      throw new Error(
        'Data acordarii certificatului este mai mare ca data inchiderii certificatului',
      );
    }

    if (
      concediu.incapacityCauseCategory &&
      concediu.incapacityCauseSubcategory &&
      concediu.type &&
      // eslint-disable-next-line
      concediu.incapacityCauseCategory.id == 8 &&
      // eslint-disable-next-line
      concediu.incapacityCauseSubcategory.id == 11 &&
      // eslint-disable-next-line
      concediu.type.id == 1
    ) {
      throw new Error(
        'Nu se accepta certificat primar pentru maternitate prelungita pe 14 zile',
      );
    }
    if (concediu.series && concediu.number) {
      const response = await post(
        `/certificate/check-certificates-with-same-number`,
        concediu,
      );
      const existOtherCertificates = await response.json();
      if (existOtherCertificates) {
        throw new Error(
          'Exista deja certificate create cu acest numar si serie.',
        );
      }
    }
    // update certificate
    if (statusId) {
      concediu.status = { id: statusId };
    }
    await put(`/certificate/${id}`, concediu);
    dispatch({ type: ConcediiActions.EDIT_SUCCESS, concediu });
    dispatch(alertActions.clear());
    if (btnType === 'SAVE') {
      dispatch(alertActions.success('Datele au fost salvate cu succes'));
      let consecutivesList = [];
      const responseConsecutives = await get(
        `/certificate/consecutives/${id}/${concediu.patientDetails.cpas}`,
      );
      consecutivesList = await responseConsecutives.json();
      dispatch({
        type: ConcediiActions.GET_CONSECUTIVES_SUCCESS,
        consecutivesList,
      });
      // setTimeout(() => history.push(`/`), 1000);
    } else {
      history.push(`/`);
    }
  } catch (error) {
    dispatch({ type: ConcediiActions.EDIT_FAILURE, error });
    dispatch(
      alertActions.error(error.message || 'Nu a putut fi salvat certificatul'),
    );
  }
};

const updateCetificate = certificate => async dispatch => {
  try {
    dispatch({ type: ConcediiActions.EDIT_CERTIFICATE_REQUEST });
    await put(`/certificate/${certificate.id}`, certificate);
    dispatch({ type: ConcediiActions.EDIT_CERTIFICATE_SUCCESS, certificate });
    dispatch(alertActions.clear());
  } catch (error) {
    dispatch({ type: ConcediiActions.EDIT_CERTIFICATE_FAILURE, error });
    dispatch(alertActions.error('Certificatul nu a putut fi modificat'));
  }
};

const getConcediu = id => async dispatch => {
  try {
    dispatch({ type: ConcediiActions.GET_REQUEST });
    const response = await get(`/certificate/${id}`);
    const semnalare = await response.json();
    const respChained = await get(`/certificate/chained-certificates/${id}`);
    const certificatesChained = await respChained.json();
    semnalare.certificatesChained = certificatesChained;
    dispatch({ type: ConcediiActions.GET_SUCCESS, semnalare });
    dispatch(alertActions.clear());
  } catch (error) {
    dispatch({ type: ConcediiActions.GET_FAILURE, error });
    dispatch(alertActions.error('Datele nu au putut fi extrase'));
  }
};

const getPatientByFilter = patientDetails => async dispatch => {
  try {
    dispatch({ type: ConcediiActions.GET_PATIENT_REQUEST }, patientDetails);

    if (!patientDetails || !patientDetails.idnp) {
      throw new Error('Introduceti IDNP-ul sau CPAS-ul pacientului');
    }

    if (
      patientDetails.idnp.length < 11 ||
      patientDetails.idnp.length === 12 ||
      patientDetails.idnp.length > 13
    ) {
      throw new Error('IDNP-ul sau CPAS-ul pacientului este invalid');
    }

    let link = '';
    if (patientDetails.idnp.length === 13) {
      link = `/mconnect/by-idnp/${patientDetails.idnp}`;
    } else {
      link = `/mconnect/by-cpas/${patientDetails.idnp}`;
    }

    const response = await get(link);
    const respPatient = await response.json();

    if (!respPatient.surname || respPatient.surname.length === 0) {
      throw new Error(respPatient.cpas);
    }

    if (respPatient.cpas === 'CPAS NOT FOUND !' || respPatient.cpas === 'Nu este CPAS pentru acest IDNP') {
      dispatch(
        alertActions.warning(
          'Pacientul nu detine CPAS. Certificatul nu va fi acceptat de CNAS.',
        ),
      );
    }

     let patient = {
      ...patientDetails,
      lastName: respPatient.surname,
      firstName: respPatient.name,
      dateBirth: new Date(respPatient.birthdate),
      cpas: respPatient.cpas,
      certificateHistory: [],
    };

    try{
      const responseHistory = await get(
        `/certificate/history/${respPatient.cpas}`,
      );
      const historyList = await responseHistory.json();

      patient = {
        ...patientDetails,
        lastName: respPatient.surname,
        firstName: respPatient.name,
        dateBirth: new Date(respPatient.birthdate),
        cpas: respPatient.cpas,
        certificateHistory: historyList,
      };
    } catch (error) {
      console.log('error la extragerea istoriei',error);
    }

    dispatch({
      type: ConcediiActions.GET_PATIENT_SUCCESS,
      patient,
    });

    // eslint-disable-next-line
    // if (type && type.id == 2) {
    //   let respCpas = null;
    //   try {
    //     const responsePrimary = await get(
    //       `/certificate/primary-certificate/${patient.cpas}`,
    //     );
    //     respCpas = await responsePrimary.json();

    //     dispatch({
    //       type: ConcediiActions.GET_PRIMARY_CERTIFICATE_CPAS_SUCCESS,
    //       respCpas,
    //     });
    //   } catch (error) {
    //     throw new Error('Nu a putut fi extras certificatul primar');
    //   }
    //   // if (!respCpas || !respCpas.series) {
    //   //   throw new Error('Nu a fost gasit nici un certificat primar');
    //   // }
    // }
  } catch (error) {
    dispatch({ type: ConcediiActions.GET_PATIENT_FAILURE, error });
    dispatch(
      alertActions.error(
        error.message ||
          'Nu au putut fi extrase datele pacientului prin MConnect',
      ),
    );
  }
};

const getChildByFilter = childDetails => async dispatch => {
  try {
    dispatch({ type: ConcediiActions.GET_CHILD_REQUEST }, childDetails);

    if (!childDetails || !childDetails.idnp) {
      throw new Error('Introduceti IDNP-ul copilului');
    }

    let respChild = null;
    if (childDetails.idnp) {
      // if( childDetails.idnp.length !== 13 && !validateIDNP(childDetails.idnp))
      // {
      //   throw new Error('IDNP invalid');
      // }
      const response = await get(`/mconnect/by-idnp/${childDetails.idnp}`);
      respChild = await response.json();
      //      respChild = {
      //   surname : 'Admin',
      //   name:'Admin',
      //   cpas: '54666565',
      //   birthdate: '01.01.1980'
      // }
    }

    if (!respChild.surname || respChild.surname.length === 0) {
      throw new Error(respChild.cpas);
    }

    const child = {
      ...childDetails,
      lastName: respChild.surname,
      firstName: respChild.name,
      fatherName: respChild.fathername,
      birthDate: new Date(respChild.birthdate),
      cpas: respChild.cpas,
      relativeDegree: childDetails.relativeDegree,
    };
    dispatch({
      type: ConcediiActions.GET_CHILD_SUCCESS,
      child,
    });
    dispatch(alertActions.clear());
  } catch (error) {
    dispatch({ type: ConcediiActions.GET_CHILD_FAILURE, error });
    dispatch(
      alertActions.error(
        error.message ||
          'Nu au putut fi extrase datele copilului prin MConnect',
      ),
    );
  }
};

const getHusbandDetailsByFilter = husbandDetails => async dispatch => {
  try {
    dispatch({ type: ConcediiActions.GET_HUSBAND_REQUEST }, husbandDetails);

    if (!husbandDetails || !husbandDetails.idnp) {
      throw new Error('Introduceti IDNP-ul sau CPAS-ul sotului');
    }

    if (
      husbandDetails.idnp.length < 11 ||
      husbandDetails.idnp.length === 12 ||
      husbandDetails.idnp.length > 13
    ) {
      throw new Error('IDNP-ul sau CPAS-ul sotului este invalid');
    }

    let link = '';
    if (husbandDetails.idnp.length === 13) {
      link = `/mconnect/by-idnp/${husbandDetails.idnp}`;
    } else {
      link = `/mconnect/by-cpas/${husbandDetails.idnp}`;
    }

    const response = await get(link);
    const respHusband = await response.json();

    if (!respHusband.surname || respHusband.surname.length === 0) {
      throw new Error(respHusband.cpas);
    }

    const husband = {
      ...husbandDetails,
      lastName: respHusband.surname,
      firstName: respHusband.name,
      fatherName: respHusband.fathername,
      dateBirth: new Date(respHusband.birthdate),
      cpas: respHusband.cpas,
    };
    dispatch({
      type: ConcediiActions.GET_HUSBAND_SUCCESS,
      husband,
    });
    dispatch(alertActions.clear());
  } catch (error) {
    dispatch({ type: ConcediiActions.GET_HUSBAND_FAILURE, error });
    dispatch(
      alertActions.error(
        error.message || 'Nu au putut fi extrase datele sotului prin MConnect',
      ),
    );
  }
};

const getSemnalariConexe = id => async dispatch => {
  try {
    dispatch({ type: ConcediiActions.GET_CONEXE_REQUEST });
    const response = await post(`/alert/related-by-alertId/${id}`, {
      page: 1,
      pageSize: 10,
    });
    const semnalariConexe = await response.json();
    dispatch({ type: ConcediiActions.GET_CONEXE_SUCCESS, semnalariConexe });
    dispatch(alertActions.clear());
  } catch (error) {
    dispatch({ type: ConcediiActions.GET_CONEXE_FAILURE, error });
    dispatch(alertActions.error('Unable to get semnalari conexe'));
  }
};

const getTotalDays = (
  cpas,
  certificateId,
  startDate,
  endDate,
  incapacityCauseCategory,
  type,
) => async dispatch => {
  try {
    dispatch({ type: ConcediiActions.GET_TOTAL_DAYS_REQUEST });
    let link = '';
    if (certificateId) {
      link = `/certificate/total-days/${cpas}/${certificateId}/${startDate}/${endDate}/${
        incapacityCauseCategory && incapacityCauseCategory.id
          ? incapacityCauseCategory.id
          : -1
      }/${type && type.id ? type.id : -1}`;
    } else {
      link = `/certificate/total-days/${cpas}/${startDate}/${endDate}/${
        incapacityCauseCategory && incapacityCauseCategory.id
          ? incapacityCauseCategory.id
          : -1
      }/${type && type.id ? type.id : -1}`;
    }
    const response = await get(link);
    const respTotal = await response.json();
    dispatch({
      type: ConcediiActions.GET_TOTAL_DAYS_SUCCESS,
      respTotal,
    });
    dispatch(alertActions.clear());
  } catch (error) {
    dispatch({
      type: ConcediiActions.GET_TOTAL_DAYS_FAILURE,
      error,
    });
    dispatch(alertActions.error('Nu a putut fi calculat totalul zilelor'));
  }
};

// const getContinuousPeriod = (
//   cpas,
//   startDate,
//   endDate,
//   incapacityCauseCategoryId,
// ) => async dispatch => {
//   try {
//     dispatch({ type: ConcediiActions.GET_CONTINUOUS_PERIOD_REQUEST });

//     const link = `/certificate/continuous-period/${cpas}/${startDate}/${endDate}/${incapacityCauseCategoryId}`;

//     const response = await get(link);
//     const respTotal = await response.json();
//     dispatch({
//       type: ConcediiActions.GET_CONTINUOUS_PERIOD_SUCCESS,
//       respTotal,
//     });
//     dispatch(alertActions.clear());
//   } catch (error) {
//     dispatch({
//       type: ConcediiActions.GET_CONTINUOUS_PERIOD_FAILURE,
//       error,
//     });
//     dispatch(alertActions.error('Nu a putut fi calculată perioada continuă'));
//   }
// };

const getPublicHistory = searchValue => async dispatch => {
  try {
    dispatch({ type: ConcediiActions.GET_PUBLIC_HISTORY_REQUEST });

    let link = '';
    if (searchValue.length === 13) {
      link = `/certificate/public-history/by-idnp/${searchValue}`;
    } else {
      link = `/certificate/public-history/by-cpas/${searchValue}`;
    }

    const response = await getNoToken(link);
    const respHistory = await response.json();

    dispatch({
      type: ConcediiActions.GET_PUBLIC_HISTORY_SUCCESS,
      respHistory,
    });
    dispatch(alertActions.clear());
  } catch (error) {
    dispatch({
      type: ConcediiActions.GET_PUBLIC_HISTORY_FAILURE,
      error,
    });
    dispatch(
      alertActions.error(
        'Verificarea statutelor certificatelor de concedii medicale a esuat.',
      ),
    );
  }
};

const getFisaBeneficiar = (idnp, startDate, endDate) => async dispatch => {
  try {
    dispatch({ type: ConcediiActions.GET_FISA_BENEFICIAR_REQUEST });

    const response = await post('/report/get-fisa-beneficiar', {
      idnp,
      startDate,
      endDate,
    });

    const fisaBeneficiarDetails = await response.json();

    dispatch({
      type: ConcediiActions.GET_FISA_BENEFICIAR_SUCCESS,
      fisaBeneficiarDetails,
    });
    dispatch(alertActions.clear());
  } catch (error) {
    dispatch({
      type: ConcediiActions.GET_FISA_BENEFICIAR_FAILURE,
      error,
    });
    dispatch(alertActions.error('Nu a putut fi extrasa fisa beneficiarului.'));
  }
};

const sendConcediuToCNAS = certificateId => async dispatch => {
  try {
    dispatch({ type: ConcediiActions.SEND_CONCEDIU_TO_CNAS_REQUEST });
    const response = await put(`/mconnect/push-medical-data/${certificateId}`);
    const respCertificate = await response.json();
    dispatch({
      type: ConcediiActions.SEND_CONCEDIU_TO_CNAS_SUCCESS,
      respCertificate,
    });
    dispatch(alertActions.clear());
  } catch (error) {
    dispatch({
      type: ConcediiActions.SEND_CONCEDIU_TO_CNAS_FAILURE,
      error,
    });
    dispatch(alertActions.error('Nu au putut fi transmise datele la CNAS'));
  }
};

const sendCancelToCNAS = (
  certificate,
  cancelationCauseId,
) => async dispatch => {
  try {
    dispatch({ type: ConcediiActions.SEND_CANCEL_TO_CNAS_REQUEST });
    const response = await put(
      `/mconnect/cancel-certificate/${certificate.id}/${cancelationCauseId}`,
    );
    const respCertificate = await response.json();
    dispatch({
      type: ConcediiActions.SEND_CANCEL_TO_CNAS_SUCCESS,
      respCertificate,
    });
    dispatch(alertActions.clear());
  } catch (error) {
    dispatch({
      type: ConcediiActions.SEND_CANCEL_TO_CNAS_FAILURE,
      error,
    });
    dispatch(alertActions.error('Nu au putut fi transmise datele la CNAS'));
  }
};

const rejectCertificate = certificateId => async dispatch => {
  try {
    dispatch({ type: ConcediiActions.REJECT_CERTIFICATE_REQUEST });
    const response = await put(
      `/certificate/reject-certificate/${certificateId}`,
    );
    const respCertificate = await response.json();
    dispatch({
      type: ConcediiActions.REJECT_CERTIFICATE_SUCCESS,
      respCertificate,
    });
    dispatch(alertActions.clear());
  } catch (error) {
    dispatch({
      type: ConcediiActions.REJECT_CERTIFICATE_FAILURE,
      error,
    });
    dispatch(
      alertActions.error(
        'Nu au putut fi transmis certificatul catre registrator',
      ),
    );
  }
};

const verifyCertificateOnCNAS = certificateId => async dispatch => {
  try {
    dispatch({ type: ConcediiActions.VERIFY_ACTION_REQUEST });
    const response = await get(`/mconnect/verify-certificate/${certificateId}`);
    const respCertificate = await response.json();
    dispatch({
      type: ConcediiActions.VERIFY_ACTION_SUCCESS,
      respCertificate,
    });
    dispatch(alertActions.clear());
  } catch (error) {
    dispatch({
      type: ConcediiActions.VERIFY_ACTION_FAILURE,
      error,
    });
    dispatch(
      alertActions.error('Nu a putut fi verificat statutul certificatului'),
    );
  }
};

const zipCertificate = certificateId => async dispatch => {
  try {
    dispatch({ type: ConcediiActions.ZIP_ACTION_REQUEST });
    const response = await put(`/certificate/zip-certificate/${certificateId}`);
    const respCertificate = await response.json();
    dispatch({
      type: ConcediiActions.ZIP_ACTION_SUCCESS,
      respCertificate,
    });
    dispatch(alertActions.clear());
  } catch (error) {
    dispatch({
      type: ConcediiActions.ZIP_ACTION_FAILURE,
      error,
    });
    dispatch(alertActions.error('Nu a putut fi arhivat certificatul'));
  }
};

const changeTypeBE = (certificateId, changeTypeReasonId) => async dispatch => {
  try {
    dispatch({ type: ConcediiActions.CHANGE_TYPE_REQUEST });
    await put(
      `/certificate/change-type/${certificateId}/${changeTypeReasonId}`,
    );
    dispatch({ type: ConcediiActions.CHANGE_TYPE_SUCCESS });

    dispatch(alertActions.clear());
    dispatch(
      alertActions.success('Tipul certificatului a fost modificat cu succes'),
    );
    document.location.reload();
  } catch (error) {
    dispatch({ type: ConcediiActions.CHANGE_TYPE_FAILURE, error });
    dispatch(
      alertActions.error('Nu a putut fi modificat tipul certificatului'),
    );
  }
};

const unzipCertificate = certificateId => async dispatch => {
  try {
    dispatch({ type: ConcediiActions.UNZIP_ACTION_REQUEST });
    const response = await put(
      `/certificate/unzip-certificate/${certificateId}`,
    );
    const respCertificate = await response.json();
    dispatch({
      type: ConcediiActions.UNZIP_ACTION_SUCCESS,
      respCertificate,
    });
    dispatch(alertActions.clear());
  } catch (error) {
    dispatch({
      type: ConcediiActions.UNZIP_ACTION_FAILURE,
      error,
    });
    dispatch(alertActions.error('Nu a putut fi dezarhivat certificatul'));
  }
};

const clearPeriod = () => async dispatch => {
  dispatch({ type: ConcediiActions.CLEAR_PERIOD });
  dispatch(alertActions.clear());
};

const downloadFile = pageDetails => async dispatch => {
  try {
    const fileName = 'CONMED_Certificates.xlsx';
    const response = await post('/certificate/generate-xlsx-file', pageDetails);
    // const contentDisposition = response.headers.get('Content-Disposition');
    // if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
    //   const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    //   const matches = filenameRegex.exec(contentDisposition);
    //   if (matches != null && matches[1]) {
    //     fileName = matches[1].replace(/['"]/g, '');
    //   }
    // }
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    dispatch({ type: ConcediiActions.FILE_DOWNLOAD_FAILED, e });
    dispatch(alertActions.error('Unable to download file'));
  }
};

const downloadReportCertificatesNumber = (
  startDate,
  endDate,
  institutionId,
  recorderId,
) => async dispatch => {
  try {
    const fileName = 'RaportNumarCertificate.xlsx';
    const response = await post('/report/get-certificates-number', {
      startDate,
      endDate,
      institutionId,
      recorderId,
    });
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    dispatch(alertActions.success('Raportul a fost generat.'));
  } catch (e) {
    dispatch(alertActions.error('Raportul nu a putut fi generat.'));
  }
};

const generateFisaBeneficiar = (idnp, startDate, endDate) => async dispatch => {
  try {
    const fileName = 'FisaBeneficiar.xlsx';
    const response = await post('/report/generate-fisa-beneficiar', {
      idnp,
      startDate,
      endDate,
    });
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    dispatch(alertActions.success('Fisa a fost generata.'));
  } catch (e) {
    dispatch(alertActions.error('Fisa nu a putut fi generata.'));
  }
};

export const concediiActions = {
  createConcediuReduxForm,
  editConcediuReduxForm,
  getConcediu,
  getSemnalariConexe,
  getPage,
  getPatientByFilter,
  getChildByFilter,
  getHusbandDetailsByFilter,
  changeTypeBE,
  updateCetificate,
  getPageNotifications,
  clearPeriod,
  getTotalDays,
  sendConcediuToCNAS,
  downloadFile,
  downloadReportCertificatesNumber,
  generateFisaBeneficiar,
  getFisaBeneficiar,
  sendCancelToCNAS,
  rejectCertificate,
  zipCertificate,
  unzipCertificate,
  resetConcediuReduxForm,
  checkCertificatePeriodOverlap,
  changeCertificateStatus,
  verifyCertificateOnCNAS,
  checkStatusCNAS,
  getPublicHistory,
  checkMin3DaysPreviousCertificateEnDate,
  checkPlus3DaysNextCertificateStartDate,
  sendCNAS,
  checkMaternitatePeriod,
  checkMaternitatePeriod2,
};
