import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';

class MSignRedirectForm extends Component {
  static propTypes = {
    postSignResult: PropTypes.instanceOf(Function).isRequired,
  };

  componentDidMount() {
    setTimeout(() => document.forms[0].submit(), 1000);
  }

  render() {
    const { postSignResult } = this.props;
    return (
      <div>
        <h1>Redirecting to Msign...</h1>
        <form method="POST" action={postSignResult.action}>
          <input
            type="hidden"
            name="ReturnUrl"
            value={postSignResult.returnUrl}
          />
        </form>
      </div>
    );
  }
}

export default connect(
  state => {
    const { msign } = state;
    return {
      postSignResult: msign.postSignResult,
    };
  },
  {},
)(MSignRedirectForm);
