import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { history } from '../../helpers/history';

class MPassLoginResult extends Component {
  static propTypes = {
    match: PropTypes.instanceOf(Object).isRequired,
  };

  componentDidMount() {
    const { match } = this.props;
    const { params } = match;
    const { token } = params;

    if (token) {
      sessionStorage.setItem('token', token);
      history.push('/');
    }
  }

  render() {
    return <div>Login result</div>;
  }
}

export default MPassLoginResult;
