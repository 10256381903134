import { get, del, postFormData } from '../../helpers/rest';
import { ConcediiActions } from '../types';
import { alertActions } from './alert.actions';
// import { alertActions } from './concedii.actions';

const getFiles = () => async dispatch => {
  try {
    const response = await get(`/files`);
    const files = await response.json();
    dispatch({ type: ConcediiActions.ADD_FILES_TO_STATE, files });
  } catch (e) {
    dispatch({ type: ConcediiActions.FILE_UPLOAD_FAILED, e });
    dispatch(alertActions.error('Unable to get files'));
  }
};

const uploadFiles = files => async dispatch => {
  try {
    if (files) {
      const formData = new FormData();
      Array.from(files).forEach(file => formData.append('files', file));
      const response = await postFormData(
        `/files/uploadMultipleFiles`,
        formData,
      );
      const documents = await response.json();
      dispatch({ type: ConcediiActions.ADD_DOCUMENTS_TO_STATE, documents });
      dispatch(alertActions.success());
    }
  } catch (e) {
    dispatch({ type: ConcediiActions.FILE_UPLOAD_FAILED, e });
    dispatch(alertActions.error('Unable to upload files'));
  }
};

const downloadFile = path => async dispatch => {
  try {
    let fileName = 'gostFileName';
    const response = await get(path);
    const contentDisposition = response.headers.get('Content-Disposition');
    if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '');
      }
    }
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    dispatch({ type: ConcediiActions.FILE_DOWNLOAD_FAILED, e });
    dispatch(alertActions.error('Unable to download file'));
  }
};

const deleteFile = (path, id) => async dispatch => {
  try {
    await del(path + id);
    dispatch({ type: ConcediiActions.FILE_DELETE_SUCCESS, deletedFileId: id });
  } catch (e) {
    dispatch({ type: ConcediiActions.FILE_DELETE_FAILED, e });
    dispatch(alertActions.error('Unable to delete file'));
  }
};

export const fileActions = {
  downloadFile,
  deleteFile,
  uploadFiles,
  getFiles,
};
