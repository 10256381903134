export const ConcediiActions = {
  CREATE_REQUEST: 'SEMNALARI_CREATE_REQUEST',
  CREATE_SUCCESS: 'SEMNALARI_CREATE_SUCCESS',
  CREATE_FAILURE: 'SEMNALARI_CREATE_FAILURE',
  GET_REQUEST: 'SEMNALARI_GET_REQUEST',
  GET_FAILURE: 'SEMNALARI_GET_FAILURE',
  GET_SUCCESS: 'SEMNALARI_GET_SUCCESS',
  EDIT_REQUEST: 'SEMNALARI_EDIT_REQUEST',
  EDIT_FAILURE: 'SEMNALARI_EDIT_FAILURE',
  EDIT_SUCCESS: 'SEMNALARI_EDIT_SUCCESS',
  CHANGE_TYPE_REQUEST: 'SEMNALARI_CHANGE_TYPE_REQUEST',
  CHANGE_TYPE_FAILURE: 'SEMNALARI_CHANGE_TYPE_FAILURE',
  CHANGE_TYPE_SUCCESS: 'SEMNALARI_CHANGE_TYPE_SUCCESS',
  EDIT_CERTIFICATE_REQUEST: 'EDIT_CERTIFICATE_REQUEST',
  EDIT_CERTIFICATE_FAILURE: 'EDIT_CERTIFICATE_FAILURE',
  EDIT_CERTIFICATE_SUCCESS: 'EDIT_CERTIFICATE_SUCCESS',
  GET_CONEXE_REQUEST: 'SEMNALARI_GET_CONEXE_REQUEST',
  GET_CONEXE_FAILURE: 'SEMNALARI_GET_CONEXE_FAILURE',
  GET_CONEXE_SUCCESS: 'SEMNALARI_GET_CONEXE_SUCCESS',
  CERTIFICATE_GETPAGE_REQUEST: 'CERTIFICATE_GETPAGE_REQUEST',
  CERTIFICATE_GETPAGE_SUCCESS: 'CERTIFICATE_GETPAGE_SUCCESS',
  CERTIFICATE_GETPAGE_FILTER_FAILED: 'CERTIFICATE_GETPAGE_FILTER_FAILED',
  CERTIFICATE_GET_NOTIFICATIONS_REQUEST:
    'CERTIFICATE_GET_NOTIFICATIONS_REQUEST',
  CERTIFICATE_GET_NOTIFICATIONS_SUCCESS:
    'CERTIFICATE_GET_NOTIFICATIONS_SUCCESS',
  CERTIFICATE_GET_NOTIFICATIONS_FAILED: 'CERTIFICATE_GET_NOTIFICATIONS_FAILED',
  CERTIFICATE_GETPAGE_FAILURE: 'CERTIFICATE_GETPAGE_FAILURE',
  ADD_FILES_TO_STATE: 'ADD_FILES_TO_STATE',
  ADD_DOCUMENTS_TO_STATE: 'ADD_DOCUMENTS_TO_STATE',
  FILE_UPLOAD_FAILED: 'FILE_UPLOAD_FAILED',
  FILE_DOWNLOAD_FAILED: 'CONCEDII_FILE_DOWNLOAD_FAILED',
  FILE_DELETE_SUCCESS: 'SEMNALARI_FILE_DELETE_SUCCESS',
  FILE_DELETE_FAILED: 'SEMNALARI_FILE_DELETE_FAILED',
  GET_PATIENT_REQUEST: 'GET_PATIENT_REQUEST',
  GET_PATIENT_SUCCESS: 'GET_PATIENT_SUCCESS',
  GET_PATIENT_FAILURE: 'GET_PATIENT_FAILURE',
  GET_CHILD_REQUEST: 'GET_CHILD_REQUEST',
  GET_HUSBAND_REQUEST: 'GET_HUSBAND_REQUEST',
  GET_CHILD_SUCCESS: 'GET_CHILD_SUCCESS',
  GET_HUSBAND_SUCCESS: 'GET_HUSBAND_SUCCESS',
  GET_CHILD_FAILURE: 'GET_CHILD_FAILURE',
  GET_HUSBAND_FAILURE: 'GET_HUSBAND_FAILURE',
  CLEAR_PERIOD: 'CLEAR_PERIOD',
  CLEAR_REQUESTED_CONCEDIU: 'CONCEDII_CLEAR_REQUESTED_CONCEDIU',
  GET_PRIMARY_CERTIFICATE_CPAS_REQUEST: 'GET_PRIMARY_CERTIFICATE_CPAS_REQUEST',
  GET_PRIMARY_CERTIFICATE_CPAS_SUCCESS: 'GET_PRIMARY_CERTIFICATE_CPAS_SUCCESS',
  GET_PRIMARY_CERTIFICATE_CPAS_FAILURE: 'GET_PRIMARY_CERTIFICATE_CPAS_FAILURE',
  // GET_CONTINUOUS_PERIOD_REQUEST: 'GET_CONTINUOUS_PERIOD_REQUEST',
  // GET_CONTINUOUS_PERIOD_SUCCESS: 'GET_CONTINUOUS_PERIOD_SUCCESS',
  // GET_CONTINUOUS_PERIOD_FAILURE: 'GET_CONTINUOUS_PERIOD_FAILURE',
  GET_TOTAL_DAYS_REQUEST: 'GET_TOTAL_DAYS_REQUEST',
  GET_TOTAL_DAYS_SUCCESS: 'GET_TOTAL_DAYS_SUCCESS',
  GET_TOTAL_DAYS_FAILURE: 'GET_TOTAL_DAYS_FAILURE',
  GET_PUBLIC_HISTORY_REQUEST: 'GET_PUBLIC_HISTORY_REQUEST',
  GET_PUBLIC_HISTORY_SUCCESS: 'GET_PUBLIC_HISTORY_SUCCESS',
  GET_PUBLIC_HISTORY_FAILURE: 'GET_PUBLIC_HISTORY_FAILURE',
  GET_FISA_BENEFICIAR_REQUEST: 'GET_FISA_BENEFICIAR_REQUEST',
  GET_FISA_BENEFICIAR_SUCCESS: 'GET_FISA_BENEFICIAR_SUCCESS',
  GET_FISA_BENEFICIAR_FAILURE: 'GET_FISA_BENEFICIAR_FAILURE',
  SEND_CONCEDIU_TO_CNAS_REQUEST: 'SEND_CONCEDIU_TO_CNAS_REQUEST',
  SEND_CONCEDIU_TO_CNAS_SUCCESS: 'SEND_CONCEDIU_TO_CNAS_SUCCESS',
  SEND_CONCEDIU_TO_CNAS_FAILURE: 'SEND_CONCEDIU_TO_CNAS_FAILURE',
  SEND_CANCEL_TO_CNAS_REQUEST: 'SEND_CANCEL_TO_CNAS_REQUEST',
  SEND_CANCEL_TO_CNAS_SUCCESS: 'SEND_CANCEL_TO_CNAS_SUCCESS',
  SEND_CANCEL_TO_CNAS_FAILURE: 'SEND_CANCEL_TO_CNAS_FAILURE',
  REJECT_CERTIFICATE_REQUEST: 'REJECT_CERTIFICATE_REQUEST',
  REJECT_CERTIFICATE_SUCCESS: 'REJECT_CERTIFICATE_SUCCESS',
  REJECT_CERTIFICATE_FAILURE: 'REJECT_CERTIFICATE_FAILURE',
  ZIP_ACTION_REQUEST: 'ZIP_ACTION_REQUEST',
  ZIP_ACTION_SUCCESS: 'ZIP_ACTION_SUCCESS',
  ZIP_ACTION_FAILURE: 'ZIP_ACTION_FAILURE',
  VERIFY_ACTION_REQUEST: 'VERIFY_ACTION_REQUEST',
  VERIFY_ACTION_SUCCESS: 'VERIFY_ACTION_SUCCESS',
  VERIFY_ACTION_FAILURE: 'VERIFY_ACTION_FAILURE',
  UNZIP_ACTION_REQUEST: 'UNZIP_ACTION_REQUEST',
  UNZIP_ACTION_SUCCESS: 'UNZIP_ACTION_SUCCESS',
  UNZIP_ACTION_FAILURE: 'UNZIP_ACTION_FAILURE',
  CLEAR_STATE: 'CLEAR_STATE',
  RESET_THE_ENTIRE_FORM: 'RESET_THE_ENTIRE_FORM',
  SET_STATUS_SIGNED_REQUEST: 'SET_STATUS_SIGNED_REQUEST',
  SET_STATUS_SIGNED_SUCCESS: 'SET_STATUS_SIGNED_SUCCESS',
  SET_STATUS_SIGNED_FAILURE: 'SET_STATUS_SIGNED_FAILURE',
  GET_CONSECUTIVES_REQUEST: 'GET_CONSECUTIVES_REQUEST',
  GET_CONSECUTIVES_SUCCESS: 'GET_CONSECUTIVES_SUCCESS',
  GET_CONSECUTIVES_FAILURE: 'GET_CONSECUTIVES_FAILURE',
};
