/* eslint-disable eqeqeq */
/* eslint-disable react/prop-types */
import decode from 'jwt-decode';
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { Button, Form } from 'carbon-components-react';
import Download16 from '@carbon/icons-react/es/download/16';
import { Field, reduxForm } from 'redux-form';
import {
  alertActions,
  userActions,
  concediiActions,
} from '../../redux/actions';
import AlchemyDateTimePicker from '../../components/form/AlchemyDateTimePicker';
import AlchemySelect from '../../components/form/AlchemySelect';
import '../style.scss';
import AlchemyNotification from '../../components/form/AlchemyNotification';
import * as validation from '../../helpers/validation';
import { getUserFromToken, hasRole } from '../../helpers/utils';

const Content = styled.div`
  overflow-x: hidden;
  background: #f3f3f3;
`;

const TitleModule = styled.h3`
  padding: 1rem 10rem;
  background: #f3f3f3;
  font-size: 1.75rem;
  @media (max-width: 1100px) {
    padding: 1rem;
  }
`;

// eslint-disable-next-line react/prop-types
class ReportCertificatesNumberForm extends React.PureComponent {
  componentDidMount() {
    this.myRef = React.forwardRef();
    this.form = React.forwardRef();
  }

  validateStatus = () => {
    const { valid, reduxFormData } = this.props;
    if (valid) {
      const { downloadReportCertificatesNumber } = this.props;
      downloadReportCertificatesNumber(
        reduxFormData.startDate,
        reduxFormData.endDate,
        reduxFormData.institution.id,
        reduxFormData.initiatorUser,
      );
    }
  };

  getInstitutions = () => {
    const { taxonomies } = this.props;
    const user = getUserFromToken();
    const institutions = taxonomies && taxonomies.NmInstitutions;
    if (hasRole('ROLE_ADMIN')) {
      return institutions;
    }
    return (
      (institutions &&
        institutions.filter(el => el.id === user.institutionId)) ||
      []
    );
  };

  loadRecorders = event => {
    if (event) {
      const { getUsersByInstitutionId } = this.props;
      getUsersByInstitutionId(event);
    }
  };

  render() {
    const { handleSubmit, reduxFormData, users } = this.props;

    const institutions = this.getInstitutions();

    return (
      <React.Fragment>
        <Content>
          <TitleModule>Raport privind numarul de certificate</TitleModule>
          <AlchemyNotification />
          {
            <Form
              onSubmit={handleSubmit(() => {
                return this.validateStatus();
              })}
            >
              <div className="box__quarter-block">
                <React.Fragment>
                  <Field
                    name="startDate"
                    labelText="De la"
                    component={AlchemyDateTimePicker}
                    showTimeSelect={false}
                    validate={validation.required}
                    maxDate={get(reduxFormData, 'endDate', null)}
                  />
                  <Field
                    name="endDate"
                    labelText="Pina la"
                    component={AlchemyDateTimePicker}
                    showTimeSelect={false}
                    validate={validation.required}
                    minDate={get(reduxFormData, 'startDate', null)}
                  />
                  <Field
                    name="institution.id"
                    component={AlchemySelect}
                    labelText="Institutie"
                    options={institutions}
                    validate={validation.required}
                    onChange={event => this.loadRecorders(event)}
                  />
                  <Field
                    name="initiatorUser"
                    component={AlchemySelect}
                    options={
                      (users &&
                        users.map(usr => {
                          return {
                            id: usr.id,
                            description: `${usr.lastName} ${usr.firstName}`,
                          };
                        })) ||
                      []
                    }
                    labelText="Registrator"
                  />
                </React.Fragment>
              </div>
              <div className="box__quarter-block reset-container">
                <div className="box__triple-block">
                  <Button
                    component="topButton"
                    kind="primary"
                    onClick={this.downloadFile}
                    type="submit"
                  >
                    Descarca
                    <Download16 className="bx--btn__icon" />
                  </Button>
                </div>
              </div>
            </Form>
          }
        </Content>
      </React.Fragment>
    );
  }
}

const ReduxForm = reduxForm({
  form: 'concediuForm',
  enableReinitialize: true,
})(ReportCertificatesNumberForm);

export default connect(
  state => {
    const { users, taxonomies, concedii, form } = state;
    const {
      requestedConcediu,
      totalDays,
      continuousPeriod,
      loading,
    } = concedii;
    const { concediuForm } = form;
    const { values, valid } = concediuForm || {};
    const tokens = decode(sessionStorage.getItem('token'));

    return {
      taxonomies: taxonomies.taxonomies,
      serverDate: taxonomies.serverDate,
      initialValues: {
        certificationPlace: { id: 1 },
        ...values,
        ...requestedConcediu,
        isChild:
          (requestedConcediu && requestedConcediu.childDetails) ||
          (values && values.isChild),
        username: `${tokens.lastname} ${tokens.firstname}`,
        institutionName: `${tokens.institutionName}`,
        phoneNumberToken: `${tokens.phoneNumber}`,
        totalDays: totalDays + (values && values.grantedDays),
        continuousPeriod,
      },
      users: (users && users.usersByInstitutionId) || [],
      reduxFormData: values,
      formValid: valid,
      loading,
    };
  },
  {
    showError: alertActions.error,
    getUsersByInstitutionId: userActions.getUsersByInstitutionId,
    downloadReportCertificatesNumber:
      concediiActions.downloadReportCertificatesNumber,
  },
)(ReduxForm);
