import React from 'react';
import styled from 'styled-components';

const Content = styled.div`
  padding: 3rem;
  background: #f3f3f3;
`;

class LogoutRedirectPage extends React.Component {
  componentDidMount() {
    this.form.submit();
  }

  render() {
    // eslint-disable-next-line
    const { match } = this.props;
    const { params } = match;
    const { logoutResponse } = params;

    return (
      <Content>
        <div>
          <form
            method="POST"
            action="https://mpass.gov.md/logout/saml"
            // eslint-disable-next-line
            ref={el => (this.form = el)}
          >
            <input type="hidden" name="SAMLResponse" value={logoutResponse} />
            <input type="hidden" name="RelayState" value="RelayState" />
          </form>
        </div>
      </Content>
    );
  }
}

// const LogoutRedirectPage = () => {
//   // document.forms[0].submit();
//   return (
//   <Content>
//     <div onLoaded={onLoad}>
//       <form method="POST" action="http://testmpass.gov.md/logout/saml">
//         <input
//           type="hidden"
//           name="SAMLResponse"
//           value="<saml2p:LogoutResponse></saml2p:LogoutResponse>"
//         />
//         <input type="hidden" name="RelayState" value="RelayState" />
//       </form>
//       <div>Title</div>
//     </div>
//   </Content>
// );
// }

export default LogoutRedirectPage;
