import { UserActions } from '../types';

const initialState = {};

export function users(state = initialState, action) {
  switch (action.type) {
    case UserActions.LOGOUT:
      return {};
    case UserActions.GETPAGE_REQUEST:
    case UserActions.GETALL_REQUEST:
    case UserActions.GET_RECORDERS_BY_INSTITUTION_REQUEST:
    case UserActions.GET_AVAILABLE_RECORDERS_REQUEST:
    case UserActions.RESET_PASSWORD_REQUEST:
    case UserActions.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
      };
    case UserActions.GET_USERS_FOR_SEARCH_REQUEST:
    case UserActions.GET_USERS_BY_INSTITUTION_ID_REQUEST:
      return {
        ...state,
      };
    case UserActions.GETALL_SUCCESS:
      return {
        ...state,
        usersAll: action.usersAll,
      };
    case UserActions.GET_RECORDERS_BY_INSTITUTION_SUCCESS:
    case UserActions.GET_AVAILABLE_RECORDERS_SUCCESS:
      return {
        ...state,
        recorders: action.recorders,
      };
    case UserActions.GET_USERS_FOR_SEARCH_SUCCESS:
      return {
        ...state,
        usersSearch: action.users,
      };
    case UserActions.GET_USERS_BY_INSTITUTION_ID_SUCCESS:
      return {
        ...state,
        usersByInstitutionId: action.users,
      };
    case UserActions.GETPAGE_SUCCESS:
      return {
        ...state,
        page: action.page,
      };
    case UserActions.GETPAGE_FAILURE:
      return {
        error: action.error,
      };
    case UserActions.CREATE_REQUEST:
    case UserActions.EDIT_REQUEST:
      return {
        ...state,
      };
    case UserActions.CREATE_SUCCESS:
      return {
        ...state,
      };
    case UserActions.CREATE_FAILURE:
    case UserActions.EDIT_FAILURE:
    case UserActions.EDIT_ROLES_FAILURE:
    case UserActions.GET_AFTER_SEARCH_FAILURE:
    case UserActions.GET_RECORDERS_BY_INSTITUTION_FAILURE:
    case UserActions.GET_AVAILABLE_RECORDERS_FAILURE:
    case UserActions.GET_USERS_FOR_SEARCH_FAILURE:
    case UserActions.GET_USERS_BY_INSTITUTION_ID_FAILURE:
    case UserActions.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case UserActions.EDIT_SUCCESS:
      return {
        ...state,
      };
    case UserActions.EDIT_ROLES_SUCCESS:
      return {
        users: [
          ...state.users.filter(user => user.id !== action.user.id),
          action.user,
        ]
          .slice()
          .sort((a, b) => a.id - b.id),
      };
    case UserActions.GET_AFTER_SEARCH_REQUEST:
      return {
        ...state,
      };
    case UserActions.GET_AFTER_SEARCH_SUCCESS:
      return {
        ...state,
        page: {
          ...state.page,
          content: [
            ...state.page.content.filter(
              user => user.id !== action.userData.id,
            ),
            { ...action.userData, cached: true },
          ]
            .slice()
            .sort((a, b) => a.id - b.id),
        },
        lastRequestedUser: action.userData,
      };
    case UserActions.LOGIN_SAML_REQUEST:
    case UserActions.LOGIN_SAML_SUCCESS:
    case UserActions.LOGIN_SAML_FAILURE:
    default:
      return state;
  }
}
