import { AuthorityActions } from '../types';

const initialState = {
  authorities: [],
};

export function authorities(state = initialState, action) {
  switch (action.type) {
    case AuthorityActions.AUTHORITIES_GET_ALL_REQUEST:
      return {
        ...state,
      };
    case AuthorityActions.AUTHORITIES_GET_ALL_SUCCESS:
      return {
        authorities: action.authorities,
      };
    case AuthorityActions.AUTHORITIES_GET_ALL_ERROR:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}
