import { store } from '../redux/store';
import { history } from './history';
import { UserActions } from '../redux/types';

const API = process.env.REACT_APP_API_BASE_URL;

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
};

const logout = () => dispatch => {
  sessionStorage.removeItem('token');
  history.push('/login');
  dispatch({ type: UserActions.LOGOUT });
};

function handleHttpErrors(response) {
  if (!response.ok) {
    if (response.status === 401) {
      logout()(store.dispatch);
    }
    return Promise.reject(response);
  }
  return response;
}

export function get(url) {
  return fetch(`${API}${url}`, {
    headers: {
      ...headers,
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
  }).then(response => handleHttpErrors(response));
}

export function post(url, data) {
  return fetch(`${API}${url}`, {
    method: 'POST',
    headers: {
      ...headers,
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
    body: JSON.stringify(data),
  }).then(response => handleHttpErrors(response));
}

export function postFormData(url, formData) {
  return fetch(`${API}${url}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
    body: formData,
  }).then(response => handleHttpErrors(response));
}

export function put(url, data) {
  return fetch(`${API}${url}`, {
    method: 'PUT',
    headers: {
      ...headers,
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
    body: JSON.stringify(data),
  }).then(response => handleHttpErrors(response));
}

export function del(url, data) {
  return fetch(`${API}${url}`, {
    method: 'DELETE',
    headers: {
      ...headers,
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
    body: JSON.stringify(data || {}),
  }).then(response => handleHttpErrors(response));
}

export function delFile(url) {
  return fetch(`${API}${url}`, {
    method: 'DELETE',
    headers: {
      ...headers,
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
  }).then(response => handleHttpErrors(response));
}

export function postNoToken(username, password) {
  return fetch(`${API}/authenticate`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username, password }),
  }).then(response => handleHttpErrors(response));
}

export function getNoToken(url) {
  return fetch(`${API}${url}`, {
    headers: {
      ...headers,
    },
  }).then(response => handleHttpErrors(response));
}
