import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { DataTable, MultiSelect, Tag } from 'carbon-components-react';
import './groups-table.scss';
import { groupActions } from '../../redux/actions';
import BaseTable from '../../components/table/BaseTable';
import GroupUsersTable from './GroupUsersTable';
import CreateGroupForm from './forms/CreateGroupForm';
import { getGroupRolesFromCell } from '../../helpers/utils';

const headers = [
  { code: 'code', description: 'cod' },
  { code: 'description', description: 'descriere' },
  { code: 'users', description: 'numar utilizatori' },
];

const placeholder = 'Permissions';

class GroupsTable extends Component {
  static propTypes = {
    getGroups: PropTypes.instanceOf(Function).isRequired,
    groups: PropTypes.instanceOf(Array),
    editGroup: PropTypes.instanceOf(Function).isRequired,
    allAuthorities: PropTypes.instanceOf(Array).isRequired,
  };

  static defaultProps = {
    groups: [],
  };

  state = {
    showCreateGroupForm: false,
  };

  componentDidMount() {
    const { getGroups } = this.props;
    getGroups();
  }

  toggleCreateGroupForm = () => {
    const { showCreateGroupForm } = this.state;
    this.setState({ showCreateGroupForm: !showCreateGroupForm });
  };

  onPermissionsDropdownChange = ({ selectedItems }, cell) => {
    const groupId = cell.match(/\d+/g)[0];
    const { groups, editGroup } = this.props;
    // eslint-disable-next-line eqeqeq
    const changedGroup = groups.filter(group => group.id == groupId)[0];
    const changedGroupsWithNewPermissions = {
      ...changedGroup,
      authorities: selectedItems,
    };
    editGroup(changedGroupsWithNewPermissions);
  };

  render() {
    const { groups, allAuthorities } = this.props;
    const { showCreateGroupForm } = this.state;
    return (
      groups && (
        <div>
          <BaseTable
            title="Roluri utilizatori"
            headers={headers}
            rows={groups.map(group => ({ ...group, id: group.id.toString() }))}
            isExpanded
            cellRendering={cell => {
              const id = cell.id.match(/\d+/g)[0];
              const group = groups.find(g => g.id.toString() === id);
              if (cell.id.includes(':users')) {
                return (
                  <DataTable.TableCell className="bx--pt0">
                    <Tag className="bx--tag--black" type="red" disabled={false}>
                      {group && group.users ? group.users.length : 0}
                    </Tag>
                  </DataTable.TableCell>
                );
              }
              if (cell.id.includes(':permissions')) {
                const groupAuhorities = getGroupRolesFromCell(cell.id, groups);
                return (
                  <DataTable.TableCell key={cell.id} className="bx--pt0">
                    <MultiSelect.Filterable
                      id={cell.id}
                      className="data-input"
                      items={allAuthorities}
                      itemToString={item => (item ? item.code : '')}
                      placeholder={placeholder}
                      initialSelectedItems={groupAuhorities}
                      onChange={data =>
                        this.onPermissionsDropdownChange(data, cell.id)
                      }
                      downshiftProps={() => {}}
                    />
                  </DataTable.TableCell>
                );
              }
              if (cell.id.includes(':users')) {
                return (
                  <DataTable.TableCell key={cell.id} className="bx--pt0" />
                );
              }
              return (
                <DataTable.TableCell key={cell.id}>
                  {cell.value}
                </DataTable.TableCell>
              );
            }}
          >
            <GroupUsersTable component="editForm" />
          </BaseTable>
          <div className="bx--forms">
            <CreateGroupForm
              component="editForm"
              visible={showCreateGroupForm}
            />
          </div>
        </div>
      )
    );
  }
}

export default connect(
  state => {
    const { groups, authorities } = state;
    return {
      groups:
        groups && groups.groups
          ? groups.groups.sort((a, b) => a.code - b.code)
          : [],
      allAuthorities:
        authorities && authorities.authorities ? authorities.authorities : [],
    };
  },
  {
    getGroups: groupActions.getGroups,
    editGroup: groupActions.editGroup,
  },
)(GroupsTable);
