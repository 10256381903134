import { ConcediiActions } from '../types';

const initialState = {};

export function concedii(state = initialState, action) {
  switch (action.type) {
    case ConcediiActions.GET_PATIENT_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ConcediiActions.GET_FISA_BENEFICIAR_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ConcediiActions.GET_CHILD_REQUEST: {
      return {
        loading: true,
      };
    }
    case ConcediiActions.GET_HUSBAND_REQUEST: {
      return {
        loading: true,
      };
    }
    case ConcediiActions.CERTIFICATE_GET_NOTIFICATIONS_REQUEST:
    case ConcediiActions.CERTIFICATE_GETPAGE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ConcediiActions.SEND_CONCEDIU_TO_CNAS_REQUEST:
    case ConcediiActions.VERIFY_ACTION_REQUEST:
    case ConcediiActions.REJECT_CERTIFICATE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ConcediiActions.EDIT_CERTIFICATE_REQUEST:
    case ConcediiActions.CHANGE_TYPE_REQUEST:
    case ConcediiActions.CHANGE_TYPE_SUCCESS:
    case ConcediiActions.GET_REQUEST:
    case ConcediiActions.GET_TOTAL_DAYS_REQUEST:
    case ConcediiActions.GET_PUBLIC_HISTORY_REQUEST:
    case ConcediiActions.SEND_CANCEL_TO_CNAS_REQUEST:
    case ConcediiActions.ZIP_ACTION_REQUEST:
    case ConcediiActions.UNZIP_ACTION_REQUEST:
    case ConcediiActions.GET_PRIMARY_CERTIFICATE_CPAS_REQUEST:
    case ConcediiActions.SET_STATUS_SIGNED_REQUEST:
      return {
        ...state,
      };
    case ConcediiActions.CLEAR_REQUESTED_CONCEDIU: {
      return {
        ...state,
        requestedConcediu: null,
      };
    }
    case ConcediiActions.CERTIFICATE_GETPAGE_SUCCESS:
      return {
        page: action.page,
        loading: false,
      };
    case ConcediiActions.CERTIFICATE_GET_NOTIFICATIONS_SUCCESS:
      return {
        pageNotification: action.page,
        loading: false,
      };
    case ConcediiActions.EDIT_CERTIFICATE_SUCCESS: {
      const { page } = state;
      if (page.content) {
        page.content = page.content.map(c => {
          // eslint-disable-next-line
          if (c.id == action.certificate.id) {
            return action.certificate;
          }
          return c;
        });
      }
      return {
        ...state,
        page,
      };
    }
    case ConcediiActions.ZIP_ACTION_SUCCESS:
    case ConcediiActions.UNZIP_ACTION_SUCCESS: {
      const { page } = state;
      if (page.content) {
        page.content = page.content.map(c => {
          // eslint-disable-next-line
          if (c.id == action.respCertificate.id) {
            return action.respCertificate;
          }
          return c;
        });
      }
      return {
        ...state,
        page,
      };
    }
    case ConcediiActions.SEND_CANCEL_TO_CNAS_SUCCESS:
    case ConcediiActions.REJECT_CERTIFICATE_SUCCESS:
    case ConcediiActions.VERIFY_ACTION_SUCCESS:
    case ConcediiActions.SET_STATUS_SIGNED_SUCCESS:
    case ConcediiActions.SEND_CONCEDIU_TO_CNAS_SUCCESS: {
      const { page } = state;
      if (page.content) {
        page.content = page.content.map(c => {
          // eslint-disable-next-line
          if (c.id == action.respCertificate.id) {
            return { ...action.respCertificate, loading: false };
          }
          return { ...c, loading: false };
        });
      }
      return {
        ...state,
        page,
        loading: false,
      };
    }
    case ConcediiActions.GET_SUCCESS:
      return {
        ...state,
        requestedConcediu: action.semnalare,
      };
    case ConcediiActions.GET_PRIMARY_CERTIFICATE_CPAS_SUCCESS: {
      // let startDateCertificate = new Date();
      // if (action.respCpas && action.respCpas.endDate) {
      //   startDateCertificate = new Date(action.respCpas.endDate);
      //   startDateCertificate.setDate(startDateCertificate.getDate() + 1);
      // } else {
      //   startDateCertificate = null;
      // }
      return {
        ...state,
        requestedConcediu: {
          ...state.requestedConcediu,
          primaryCertificate: action.respCpas,
        },
      };
    }
    case ConcediiActions.GET_PATIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        requestedConcediu: {
          ...state.requestedConcediu,
          patientDetails: action.patient,
        },
      };
    case ConcediiActions.GET_CONSECUTIVES_SUCCESS:
      return {
        ...state,
        loading: false,
        requestedConcediu: {
          ...state.requestedConcediu,
          consecutivesDetails: action.consecutivesList,
        },
      };
    case ConcediiActions.GET_FISA_BENEFICIAR_SUCCESS:
      return {
        ...state,
        loading: false,
        fisaBeneficiarDetails: action.fisaBeneficiarDetails,
      };
    case ConcediiActions.GET_PATIENT_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case ConcediiActions.GET_PUBLIC_HISTORY_SUCCESS:
      return {
        ...state,
        respHistory: action.respHistory,
      };
    case ConcediiActions.GET_TOTAL_DAYS_SUCCESS:
      return {
        totalDays: action.respTotal.TOTAL_DAYS,
        continuousPeriod: action.respTotal.CONTINUOUS_PERIOD,
      };
    // case ConcediiActions.GET_CONTINUOUS_PERIOD_SUCCESS:
    //   return {
    //     continuousPeriod: action.respTotal,
    //   };
    case ConcediiActions.GET_CHILD_SUCCESS:
      return {
        ...state,
        loading: false,
        requestedConcediu: {
          ...state.requestedConcediu,
          childDetails: action.child,
        },
      };
    case ConcediiActions.GET_HUSBAND_SUCCESS:
      return {
        ...state,
        loading: false,
        requestedConcediu: {
          ...state.requestedConcediu,
          husbandDetails: action.husband,
        },
      };
    case ConcediiActions.GET_CHILD_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case ConcediiActions.GET_HUSBAND_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case ConcediiActions.GET_FISA_BENEFICIAR_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case ConcediiActions.ADD_FILES_TO_STATE:
      return {
        ...state,
        files: action.files,
      };
    case ConcediiActions.ADD_DOCUMENTS_TO_STATE:
      return {
        ...state,
        files: [...state.files, ...action.documents],
      };
    case ConcediiActions.FILE_DELETE_SUCCESS:
      return {
        ...state,
        files: state.files.filter(
          // eslint-disable-next-line eqeqeq
          files => files.id != action.deletedFileId,
        ),
      };

    case ConcediiActions.GET_CONEXE_SUCCESS:
      return {
        ...state,
        semnalariConexe: action.semnalariConexe,
      };

    case ConcediiActions.CLEAR_STATE:
      return {
        ...state,
        requestedConcediu: null,
      };
    case ConcediiActions.CLEAR_PERIOD:
      return {
        ...state,
        requestedConcediu: {
          ...state.requestedConcediu,
          startDate: null,
          endDate: null,
          grantedDays: null,
          totalDays: null,
          prescribed: null,
          dateExitToWork: null,
        },
      };
    case ConcediiActions.CERTIFICATE_GET_NOTIFICATIONS_FAILED:
    case ConcediiActions.CERTIFICATE_GETPAGE_FILTER_FAILED:
      return {
        page: action.page,
      };
    case ConcediiActions.SEND_CANCEL_TO_CNAS_FAILURE:
    case ConcediiActions.VERIFY_ACTION_FAILURE:
    case ConcediiActions.REJECT_CERTIFICATE_FAILURE:
    case ConcediiActions.ZIP_ACTION_FAILURE:
    case ConcediiActions.UNZIP_ACTION_FAILURE:
    case ConcediiActions.SET_STATUS_SIGNED_FAILURE:
    case ConcediiActions.SEND_CONCEDIU_TO_CNAS_FAILURE: {
      const { page } = state;
      if (page.content) {
        page.content = page.content.map(c => {
          // eslint-disable-next-line
          /* eslint-disable eqeqeq */
          if (
            c &&
            action.respCertificate &&
            c.id == action.respCertificate.id
          ) {
            return action.respCertificate;
          }
          return c;
        });
      }
      return {
        ...state,
        page,
        error: action.error,
        loading: false,
      };
    }
    case ConcediiActions.CERTIFICATE_GETPAGE_FAILURE:
      return {
        error: action.error,
        loading: false,
      };
    case ConcediiActions.GET_CONEXE_FAILURE:
    case ConcediiActions.GET_FAILURE:
    case ConcediiActions.GET_PRIMARY_CERTIFICATE_CPAS_FAILURE:
    case ConcediiActions.FILE_UPLOAD_FAILED:
    case ConcediiActions.EDIT_CERTIFICATE_FAILED:
    case ConcediiActions.CHANGE_TYPE_FAILURE:
    case ConcediiActions.FILE_DOWNLOAD_FAILED:
    case ConcediiActions.FILE_DELETE_FAILED:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}
