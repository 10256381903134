import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DataTable, Button, Form } from 'carbon-components-react';
import Search16 from '@carbon/icons-react/es/search/16';
import get from 'lodash/get';
import { Field, reduxForm } from 'redux-form';
import SearcheableTable from '../../components/table/SearcheableTable';
import AlchemyDateTimePicker from '../../components/form/AlchemyDateTimePicker';
import AlchemyAdvancedSelect from '../../components/form/AlchemyAdvancedSelect';
import '../style.scss';
import { auditActions } from '../../redux/actions/audit.actions';
import { userActions } from '../../redux/actions';
import { getUserFromToken } from '../../helpers/utils';

const headers = [
  { code: 'nr', description: 'Nr' },
  { code: 'id', description: 'ID' },
  { code: 'author', description: 'Utilizator' },
  { code: 'time', description: 'Timpul' },
  { code: 'ip', description: 'IP' },
  { code: 'userAgent', description: 'Dispozitiv' },
];

const Container = styled.div`
  padding: 3rem;
`;

class LoginTabs extends Component {
  static propTypes = {
    auditList: PropTypes.instanceOf(Array).isRequired,
    pageDetails: PropTypes.instanceOf(Object).isRequired,
    reduxFormData: PropTypes.instanceOf(Object).isRequired,
    getPage: PropTypes.instanceOf(Function).isRequired,
    getUsersForSearchCertificates: PropTypes.instanceOf(Function).isRequired,
    users: PropTypes.instanceOf(Array).isRequired,
  };

  componentDidMount() {
    const { getUsersForSearchCertificates } = this.props;
    const user = getUserFromToken();
    getUsersForSearchCertificates(user.id);
  }

  search = () => {
    const { getPage } = this.props;
    getPage({
      ...this.createSearchParams(),
      page: 1,
      pageSize: 10,
    });
  };

  createSearchParams() {
    const { reduxFormData } = this.props;
    const v = reduxFormData || {};
    return {
      author: v.author,
      fromDate: v.fromDate ? new Date(v.fromDate) : null,
      toDate: v.toDate ? new Date(v.toDate) : null,
      ip: v.ip,
    };
  }

  render() {
    const { auditList, pageDetails, getPage, users } = this.props;
    const { search } = pageDetails;
    return (
      <Container>
        <Form className="boxes">
          <div className="boxes__container">
            <div className="boxes__quarter-block">
              <Field
                name="author"
                component={AlchemyAdvancedSelect}
                labelText="Utilizator"
                options={users}
              />
              <Field
                name="fromDate"
                labelText="Perioada de la"
                component={AlchemyDateTimePicker}
                showTimeSelect
              />
              <Field
                name="toDate"
                labelText="Perioada pina la"
                component={AlchemyDateTimePicker}
                showTimeSelect
              />
            </div>
          </div>
        </Form>
        <div className="box boxs">
          <div className="box_container boxs_cotainers-table">
            <div className="box__single-block align-right">
              <Button
                component="topButton"
                kind="primary"
                onClick={this.search}
              >
                Cautare
                <Search16 className="bx--btn__icon" />
              </Button>
            </div>
          </div>
        </div>
        <div className="box__single-block">
          <SearcheableTable
            title="Autentificari"
            headers={headers}
            rows={auditList}
            getPage={getPage}
            direction="desc"
            pageDetails={pageDetails}
            searchPrecondition={search}
            auditSearch={this.createSearchParams()}
            searchFields={
              [
                // 'patientDetails.firstName',
                // 'patientDetails.lastName',
              ]
            }
            cellRendering={cell => {
              if (cell.info.header === 'time') {
                return (
                  <DataTable.TableCell key={cell.id}>
                    {new Date(cell.value).toLocaleString()}
                  </DataTable.TableCell>
                );
              }
              return (
                <DataTable.TableCell key={cell.id}>
                  {cell.value}
                </DataTable.TableCell>
              );
            }}
          />
        </div>
      </Container>
    );
  }
}

const ReduxForm = reduxForm({
  form: 'auditLoginsFilter',
  enableReinitialize: true,
})(LoginTabs);

export default connect(
  state => {
    const { audit, form, users } = state;
    const { auditLoginsFilter } = form;
    const allUsers = get(users, 'usersSearch', []).map(user => {
      return {
        id: user.userName,
        description: `${user.firstName} ${user.lastName} (${user.userName})`,
      };
    });

    const page = audit && audit.auditLoginsPage ? audit.auditLoginsPage : null;
    if (page && page.numberOfElements > 0) {
      let i = page.pageable.offset + 1;
      return {
        users: allUsers,
        reduxFormData: get(auditLoginsFilter, 'values', null),
        auditList: page.content.map(el => {
          return {
            ...el,
            // eslint-disable-next-line no-plusplus
            nr: i++,
            id: el.changedValues.id,
            ip: el.changedValues.ip,
            userAgent: el.changedValues.userAgent,
          };
        }),
        pageDetails: {
          page: page.number ? page.number + 1 : 1,
          pageSize: page.pageable.pageSize,
          totalItems: page.last
            ? page.pageable.offset + page.numberOfElements
            : page.pageable.offset + page.numberOfElements + 1,
          orderBy: page.orderBy ? page.orderBy : 'id',
          direction: page.direction ? page.direction : 'desc',
          search: page.search ? page.search : [],
        },
      };
    }
    return {
      users: allUsers,
      reduxFormData: get(auditLoginsFilter, 'values', null),
      auditList: [],
      pageDetails: {
        page: 1,
        pageSize: 10,
        totalItems: 0,
        orderBy: 'id',
        direction: 'desc',
      },
    };
  },
  {
    getPage: auditActions.getAuditLoginsPage,
    getUsersForSearchCertificates: userActions.getUsersForSearchCertificates,
  },
)(ReduxForm);
