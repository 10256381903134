export const $brand01 = '#0062ff';
export const $brand02 = '#054ada';
export const $brand03 = '#0062ff';
export const $inverse01 = '#ffffff';
export const $inverse02 = '#272d33';
export const $ui01 = '#f3f3f3';
export const $ui02 = '#ffffff';
export const $ui03 = '#dcdcdc';
export const $ui04 = '#8c8c8c';
export const $ui05 = '#171717';
export const $text01 = '#171717';
export const $text02 = '#565656';
export const $text03 = '#a4a4a4';
export const $field01 = '#f3f3f3';
export const $field02 = '#ffffff';
export const $support01 = '#da1e28';
export const $support02 = '#24a249';
export const $support03 = '#fdd13a';
export const $support04 = '#418cff';
export const $uselayer = 'true';
export const $inputborder = '1px solid transparent';
export const $inputlabelweight = '700';
export const $buttonfontweight = '700';
export const $buttonfontsize = '0.875rem';
export const $buttonborderradius = '0';
export const $buttonheight = '40px';
export const $buttonpadding = '0 1rem';
export const $buttonpaddingsm = '0 0.5rem';
export const $buttonborderwidth = '2px';
export const $accordionflexdirection = 'row';
export const $accordionjustifycontent = 'flexstart';
export const $accordionarrowmargin = '0 0 0 0.25rem';
export const $accordiontitlemargin = '0 0 0 1rem';
export const $accordioncontentpadding = '0 1rem 0 1.75rem';
export const $cardtextalign = 'center';
export const $cardflexalign = 'center';
export const $checkboxborderwidth = '2px';
export const $snippetbackgroundcolor = 'export const $field01';
export const $contentswitcherborderradius = '8px';
export const $contentswitcheroptionborder = '1px solid export const $brand01';
export const $datatableheadingtransform = 'uppercase';
export const $datatableheadingborderbottom = '1px solid export const $brand01';
export const $datatablerowheight = '2rem';
export const $modalbordertop = 'export const $brand01 4px solid';
export const $modalfooterbackgroundcolor = 'export const $ui03';
export const $progressindicatorbarwidth = '1px inset transparent';
export const $progressindicatorstrokewidth = '5';
export const $progressindicatorlineoffset = '0.625rem';
export const $radioborderwidth = '2px';
export const $structuredlistpadding = '2rem';
export const $structuredlisttexttransform = 'uppercase';
export const $nav01 = '#1b2834';
export const $nav02 = '#1c496d';
