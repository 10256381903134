import React from 'react';

const ConcediiList = ({ item, getDateWithoutTime }) => {
  return (
    <tr key={item.id}>
      <td>{item.nrCertificate}</td>
      <td>{getDateWithoutTime(item.dateCompletion)}</td>
      <td>{getDateWithoutTime(item.startDate)}</td>
      <td>{getDateWithoutTime(item.endDate)}</td>
      <td>{item.type}</td>
      <td>{item.category}</td>
      <td>{item.statut}</td>
      <td>{item.registrator}</td>
      <td>{item.validator}</td>
      <td>{item.institution}</td>
      <td>{item.phoneNumberValidator}</td>
    </tr>
  );
};

export default ConcediiList;
