import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DataTable, Button, Modal } from 'carbon-components-react';
import Edit16 from '@carbon/icons-react/es/edit/16';
import Close16 from '@carbon/icons-react/es/close/16';
import Search16 from '@carbon/icons-react/es/search/16';
import Launch16 from '@carbon/icons-react/es/launch/16';
import WatsonHealthSaveAnnotation20 from '@carbon/icons-react/es/watson-health/save--annotation/20';
import WatsonHealthSaveSeries20 from '@carbon/icons-react/es/watson-health/save--series/20';
import Loading from 'carbon-components-react/lib/components/Loading';
import Reset20 from '@carbon/icons-react/es/reset/20';
import Download16 from '@carbon/icons-react/es/download/16';
import Checkmark from '@carbon/icons-react/es/checkmark/16';
import { NavLink } from 'react-router-dom';
import get from 'lodash/get';
import { Field, reduxForm } from 'redux-form';
import {
  concediiActions,
  userActions,
  alertActions,
  msignActions,
} from '../../redux/actions';
import SearcheableTable from '../../components/table/SearcheableTable';
import AlchemyTextInput from '../../components/form/AlchemyTextInput';
import AlchemyAdvancedSelect from '../../components/form/AlchemyAdvancedSelect';
import AlchemyDateTimePicker from '../../components/form/AlchemyDateTimePicker';
import '../style.scss';
import AlchemyNotification from '../../components/form/AlchemyNotification';
import AlchemyCheckbox from '../../components/form/AlchemyCheckbox';
import {
  getUserFromToken,
  hasRole,
  isTheSameUser,
  isSubordinate,
} from '../../helpers/utils';

const headers = [
  { code: 'id', description: 'ID' },
  { code: 'actions', description: 'Actiuni' },
  { code: 'status', description: 'Statut' },
  { code: 'subcategoryStatus', description: 'Subcategorie statut' },
  { code: 'startDateStr', description: 'Data completarii' },
  { code: 'certificationPlace', description: 'Locul eliberarii' },
  { code: 'initiatorUser', description: 'Registrator' },
  { code: 'validatorUser', description: 'Validator nume prenume' },
  { code: 'validatorPhoneNumber', description: 'Validator numar de telefon' },
  { code: 'hasNotifications', description: 'Notificari' },
  { code: 'series', description: 'Seria certificatului' },
  { code: 'number', description: 'Numarul certificatului' },
  { code: 'type', description: 'Tipul certificatului' },
  { code: 'startDate', description: 'Data acordarii concediului' },
  { code: 'endDate', description: 'Data inchiderii concediului' },
  { code: 'grantedDays', description: 'Zile acordate' },
  {
    code: 'primaryCertificateSeries',
    description: 'Seria certificatului precedent',
  },
  {
    code: 'primaryCertificateNumber',
    description: 'Numarul certificatului precedent',
  },
  {
    code: 'primaryCertificateStartDateStr',
    description: 'Data acordarii concediului precedent',
  },
  {
    code: 'primaryCertificateEndDateStr',
    description: 'Data inchiderii concediului precedent',
  },
  {
    code: 'incapacityCauseCategory',
    description: 'Categoria incapacitatii de munca',
  },
  {
    code: 'incapacityCauseSubcategory',
    description: 'Subcategoria incapacitatii de munca',
  },
  {
    code: 'regimen',
    description: 'Regim',
  },
  { code: 'idnp', description: 'IDNP pacient' },
  {
    code: 'cpas',
    description: 'CPAS pacient',
  },
  { code: 'personStatus', description: 'Statut pacient' },
  { code: 'lastName', description: 'Nume pacient' },
  { code: 'firstName', description: 'Prenume pacient' },
  { code: 'dateBirth', description: 'Data nasterii pacient' },
  { code: 'idnpChild', description: 'Idnp copil' },
  {
    code: 'birthCertificateSeries',
    description: 'Seria certificatului de nastere copil',
  },
  {
    code: 'birthCertificateNumber',
    description: 'Seria certificatului de nastere copil',
  },
  {
    code: 'childLastName',
    description: 'Nume copil',
  },
  {
    code: 'childFirstName',
    description: 'Prenume copil',
  },
  {
    code: 'childFatherName',
    description: 'Patronimic copil',
  },
  {
    code: 'childBirthDate',
    description: 'Data nasterii copil',
  },
  { code: 'prescribed', description: 'Inchiderea certificatului' },
  { code: 'dateExitToWork', description: 'A începe lucrul de la' },
  { code: 'dateStartBreach', description: 'Incalcat regim de la' },
  { code: 'dateEndBreach', description: 'Incalcat regim pana la' },
  { code: 'breachCause', description: 'Motivul incalcarii' },
  { code: 'institution', description: 'Institutia' },
];

const Container = styled.div`
  padding: 3rem;
`;

const ModalContent = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const Boxs = styled.div`
  margin-top: 1rem;
`;

class ListaCertificate extends Component {
  static propTypes = {
    certificates: PropTypes.instanceOf(Array).isRequired,
    cetificateStatuses: PropTypes.instanceOf(Array).isRequired,
    pageDetails: PropTypes.instanceOf(Object).isRequired,
    updateCetificate: PropTypes.instanceOf(Function).isRequired,
    getUsersForSearchCertificates: PropTypes.instanceOf(Function).isRequired,
    reset: PropTypes.instanceOf(Function).isRequired,
    sendCancelToCNAS: PropTypes.instanceOf(Function).isRequired,
    listaCertificate: PropTypes.instanceOf(Array).isRequired,
    getPage: PropTypes.instanceOf(Function).isRequired,
    taxonomies: PropTypes.instanceOf(Array).isRequired,
    users: PropTypes.instanceOf(Array).isRequired,
    sendConcediuToCNAS: PropTypes.instanceOf(Function).isRequired,
    verifyCertificateOnCNASAction: PropTypes.instanceOf(Function).isRequired,
    rejectCertificate: PropTypes.instanceOf(Function).isRequired,
    zipAction: PropTypes.instanceOf(Function).isRequired,
    unzipAction: PropTypes.instanceOf(Function).isRequired,
    downloadFile: PropTypes.instanceOf(Function).isRequired,
    showError: PropTypes.instanceOf(Function).isRequired,
    loading: PropTypes.instanceOf(Function).isRequired,
    postSignForCertId: PropTypes.instanceOf(Function).isRequired,
  };

  state = {
    closeModal: false,
    approveModal: false,
    verifyModal: false,
    rejectModal: false,
    zipModal: false,
    unzipModal: false,
    certificateId: null,
  };

  componentDidMount() {
    this.checkPasswordExpiraton();
    const { getUsersForSearchCertificates, getPage } = this.props;
    const user = getUserFromToken();
    getUsersForSearchCertificates(user.id);
    const pageDetails = {
      search: [],
      page: 1,
    };
    getPage(pageDetails);
  }

  changeCertificateStatus = (cert, status) => {
    const { updateCetificate, cetificateStatuses } = this.props;
    const certificate = cert;
    const newStatus = cetificateStatuses.find(el => el.description === status);
    certificate.status = newStatus;
    updateCetificate(certificate);
  };

  closeStatus = id => {
    this.setState(state => ({
      closeModal: !state.closeModal,
      certificateId: id,
    }));
    this.doReset();
  };

  approveStatus = id => {
    this.setState(state => ({
      approveModal: !state.approveModal,
      certificateId: id,
    }));
  };

  verifyStatus = id => {
    this.setState(state => ({
      verifyModal: !state.verifyModal,
      certificateId: id,
    }));
  };

  getBelongings = () => {
    return [
      { id: 1, description: 'Certificate proprii' },
      { id: 2, description: 'Toate certificatele' },
    ];
  };

  rejectStatus = id => {
    this.setState(state => ({
      rejectModal: !state.rejectModal,
      certificateId: id,
    }));
  };

  zipStatus = id => {
    this.setState(state => ({
      zipModal: !state.zipModal,
      certificateId: id,
    }));
  };

  unzipStatus = id => {
    this.setState(state => ({
      unzipModal: !state.unzipModal,
      certificateId: id,
    }));
  };

  doReset = () => {
    const { reset } = this.props;
    reset();
  };

  filterCertificates = () => {
    const { getPage } = this.props;
    const pageDetails = this.getPageDetails();
    getPage(pageDetails);
  };

  downloadFile = () => {
    const { downloadFile } = this.props;
    const pageDetails = this.getPageDetails();
    downloadFile(pageDetails);
  };

  checkPasswordExpiraton = () => {
    const { showError } = this.props;
    const user = getUserFromToken();
    const today = new Date();
    const newdate = new Date();
    newdate.setDate(today.getDate() + 3);
    if (
      user &&
      user.dateExpiration &&
      new Date(user.dateExpiration) <= newdate
    ) {
      showError(
        `Parola dvs. va expira la data de ${new Date(
          user.dateExpiration,
        ).toLocaleDateString()}. Va rugam s-o modificati.`,
      );
    }
  };

  getPageDetails = () => {
    const { listaCertificate, users } = this.props;
    let { pageDetails } = this.props;
    pageDetails = {
      ...pageDetails,
      search: [],
      page: 1,
    };

    let isArchived = false;
    if (listaCertificate.values) {
      const userFound = Object.entries(listaCertificate.values).find(
        value => value[0] === 'initiatorUser',
      );
      if (!hasRole('ROLE_ADMIN') && (!userFound || !userFound[1])) {
        users.userOptions.forEach(u => {
          pageDetails.search.push({
            key: 'initiatorUser',
            operation: ':',
            value: u.id,
            orPredicate: true,
            precondition: true,
          });
        });
      }

      Object.entries(listaCertificate.values).forEach(value => {
        if (value[1]) {
          if (value[0] === 'institution') {
            pageDetails.search.push({
              key: 'initiatorUser.institution.id',
              operation: ':',
              value: value[1],
            });
          } else if (value[0] === 'completeStartDate') {
            const date = new Date(value[1]);
            pageDetails.search.push({
              key: 'dateCompletion',
              operation: '>',
              value: `${date.getDate()}.${date.getMonth() +
                1}.${date.getFullYear()}`,
            });
          } else if (value[0] === 'completeEndDate') {
            const date = new Date(value[1]);
            pageDetails.search.push({
              key: 'dateCompletion',
              operation: '<',
              value: `${date.getDate()}.${date.getMonth() +
                1}.${date.getFullYear()}`,
            });
          } else if (value[0] === 'vacationStartDateFrom') {
            const date = new Date(value[1]);
            pageDetails.search.push({
              key: 'startDate',
              operation: '>',
              value: `${date.getDate()}.${date.getMonth() +
                1}.${date.getFullYear()}`,
            });
          } else if (value[0] === 'vacationStartDateTo') {
            const date = new Date(value[1]);
            pageDetails.search.push({
              key: 'startDate',
              operation: '<',
              value: `${date.getDate()}.${date.getMonth() +
                1}.${date.getFullYear()}`,
            });
          } else if (value[0] === 'vacationEndDateFrom') {
            const date = new Date(value[1]);
            pageDetails.search.push({
              key: 'endDate',
              operation: '>',
              value: `${date.getDate()}.${date.getMonth() +
                1}.${date.getFullYear()}`,
            });
          } else if (value[0] === 'vacationEndDateTo') {
            const date = new Date(value[1]);
            pageDetails.search.push({
              key: 'endDate',
              operation: '<',
              value: `${date.getDate()}.${date.getMonth() +
                1}.${date.getFullYear()}`,
            });
          } else if (value[0] === 'idnp') {
            pageDetails.search.push({
              key: 'patientDetails.idnp',
              operation: ':',
              value: value[1],
            });
          } else {
            pageDetails.search.push({
              key: value[0],
              operation: ':',
              value: value[1],
            });
          }
          if (value[0] === 'archived' && value[1]) {
            isArchived = true;
          }
        }
      });
    } else if (!hasRole('ROLE_ADMIN')) {
      users.userOptions.forEach(u => {
        pageDetails.search.push({
          key: 'initiatorUser',
          operation: ':',
          value: u.id,
          orPredicate: true,
          precondition: true,
        });
      });
    }

    if (!isArchived) {
      pageDetails.search.push({
        key: 'archived',
        operation: ':',
        value: false,
        precondition: false,
      });
    }

    pageDetails.search = pageDetails.search.map(search => {
      return {
        ...search,
        isPrecondition: true,
      };
    });

    return pageDetails;
  };

  sendConcediuToCNAS2() {
    const { sendConcediuToCNAS } = this.props;
    const { certificateId } = this.state;
    sendConcediuToCNAS(certificateId);
    this.setState({ approveModal: false });
  }

  verifyStatusCNAS() {
    const { verifyCertificateOnCNASAction } = this.props;
    const { certificateId } = this.state;
    verifyCertificateOnCNASAction(certificateId);
    this.setState({ verifyModal: false });
  }

  rejectCertificate2() {
    const { rejectCertificate } = this.props;
    const { certificateId } = this.state;
    rejectCertificate(certificateId);
    this.setState({ rejectModal: false });
  }

  zip() {
    const { zipAction } = this.props;
    const { certificateId } = this.state;
    zipAction(certificateId);
    this.setState({ zipModal: false });
  }

  unzip() {
    const { unzipAction } = this.props;
    const { certificateId } = this.state;
    unzipAction(certificateId);
    this.setState({ unzipModal: false });
  }

  postRequestToMSign() {
    const { postSignForCertId } = this.props;
    const { certificateId } = this.state;
    postSignForCertId(certificateId);
    this.setState({ approveModal: false });
  }

  closeCertificate() {
    const { certificateId } = this.state;
    const { listaCertificate, sendCancelToCNAS, showError } = this.props;
    this.setState({ closeModal: false });
    if (listaCertificate.values && listaCertificate.values.cancelationCause) {
      sendCancelToCNAS(
        certificateId,
        listaCertificate.values ? listaCertificate.values.cancelationCause : -1,
      );
      this.doReset();
    } else {
      showError(`Selectați vă rog cauza anulării`);
    }
  }

  render() {
    const {
      certificates,
      pageDetails,
      taxonomies,
      users,
      getPage,
      loading,
    } = this.props;
    const {
      closeModal,
      approveModal,
      verifyModal,
      rejectModal,
      zipModal,
      unzipModal,
    } = this.state;
    const { search } = pageDetails;
    let userValue = null;
    if (users && users.userOptions && users.userOptions.length === 1) {
      userValue = users.userOptions[0].id;
    }
    return (
      <div className="box">
        <div className="box__container">
          <AlchemyNotification />
          <Container>
            <div className="box__quarter-block-responsive">
              <Field
                name="initiatorUser"
                value={userValue}
                component={AlchemyAdvancedSelect}
                options={
                  users &&
                  users.userOptions &&
                  users.userOptions.map(usr => {
                    return {
                      id: usr.id,
                      description: `${usr.lastName} ${usr.firstName}`,
                    };
                  })
                }
                labelText="Registratorul"
              />
              <Field
                name="series"
                type="text"
                component={AlchemyTextInput}
                label="Seria certificatului"
              />
              <Field
                name="number"
                type="text"
                component={AlchemyTextInput}
                label="Numarul certificatului"
              />
              <Field
                name="status"
                component={AlchemyAdvancedSelect}
                options={taxonomies && taxonomies.NmCertificateStatuses}
                labelText="Statut"
              />
              <Field
                name="completeStartDate"
                labelText="Data completarii de la"
                component={AlchemyDateTimePicker}
                showTimeSelect={false}
              />
              <Field
                name="completeEndDate"
                labelText="Data completarii pina la"
                component={AlchemyDateTimePicker}
                showTimeSelect={false}
              />

              <Field
                name="vacationStartDateFrom"
                labelText="Data acordarii concediului de la"
                component={AlchemyDateTimePicker}
                showTimeSelect={false}
              />
              <Field
                name="vacationStartDateTo"
                labelText="Data acordarii concediului pina la"
                component={AlchemyDateTimePicker}
                showTimeSelect={false}
              />
              <Field
                name="vacationEndDateFrom"
                labelText="Data inchiderii concediului de la"
                component={AlchemyDateTimePicker}
                showTimeSelect={false}
              />
              <Field
                name="vacationEndDateTo"
                labelText="Data inchiderii concediului pina la"
                component={AlchemyDateTimePicker}
                showTimeSelect={false}
              />
              <Field
                name="incapacityCauseCategory"
                component={AlchemyAdvancedSelect}
                options={taxonomies && taxonomies.NmIncapacityCauseCategory}
                labelText="Categoria"
              />
              <Field
                name="incapacityCauseSubcategory"
                component={AlchemyAdvancedSelect}
                options={taxonomies && taxonomies.NmIncapacityCauseSubcategory}
                labelText="Subcategoria"
              />
            </div>

            <div className="box__quarter-block">
              <Field
                name="idnp"
                type="text"
                component={AlchemyTextInput}
                label="IDNP"
              />
              <Field
                name="belonging"
                component={AlchemyAdvancedSelect}
                options={this.getBelongings()}
                labelText="Apartenență"
              />
              {hasRole('ROLE_ADMIN') && (
                <React.Fragment>
                  <Field
                    name="institution"
                    component={AlchemyAdvancedSelect}
                    options={taxonomies && taxonomies.NmInstitutions}
                    labelText="Institutie"
                  />
                  <Field
                    name="archived"
                    component={AlchemyCheckbox}
                    type="checkbox"
                    label="Arhivate"
                  />
                </React.Fragment>
              )}
            </div>

            <div className="box__quarter-block reset-container">
              <div className="box__triple-block">
                <Button
                  component="topButton"
                  kind="primary"
                  onClick={this.doReset}
                >
                  Reseteaza
                  <Reset20 className="bx--btn__icon" />
                </Button>

                <Button
                  component="topButton"
                  kind="primary"
                  onClick={this.downloadFile}
                >
                  Descarca
                  <Download16 className="bx--btn__icon" />
                </Button>
                <Button
                  component="topButton"
                  kind="primary"
                  onClick={this.filterCertificates}
                >
                  Cautare
                  <Search16 className="bx--btn__icon" />
                </Button>
              </div>
            </div>
          </Container>
          <Modal
            className="other-modals"
            open={closeModal}
            onRequestClose={this.closeStatus}
            modalHeading="Anulare"
            primaryButtonText="Da"
            secondaryButtonText="Nu"
            onRequestSubmit={() => this.closeCertificate()}
            onSecondarySubmit={this.closeStatus}
          >
            <ModalContent>
              <Boxs>
                <Field
                  className="pidar"
                  name="cancelationCause"
                  component={AlchemyAdvancedSelect}
                  options={taxonomies && taxonomies.NmCancelationCause}
                  labelText="Cauza anularii"
                />
              </Boxs>
            </ModalContent>
          </Modal>
          <Modal
            className="other-modals"
            open={approveModal}
            onRequestClose={this.approveStatus}
            modalHeading="Aprobare"
            primaryButtonText="Da"
            secondaryButtonText="Nu"
            shouldSubmitOnEnter
            onSecondarySubmit={this.approveStatus}
            onRequestSubmit={() => {
              this.postRequestToMSign();
            }}
          >
            <p>Sunteti sigur?</p>
          </Modal>
          <Modal
            className="other-modals"
            open={verifyModal}
            onRequestClose={this.verifyStatus}
            modalHeading="Verificare statut certificat"
            primaryButtonText="Da"
            secondaryButtonText="Nu"
            shouldSubmitOnEnter
            onSecondarySubmit={this.verifyStatus}
            onRequestSubmit={() => {
              this.verifyStatusCNAS();
            }}
          >
            <p>Sunteti sigur?</p>
          </Modal>
          <Modal
            className="other-modals"
            open={rejectModal}
            onRequestClose={this.rejectStatus}
            modalHeading="Trimite catre registrator"
            primaryButtonText="Da"
            secondaryButtonText="Nu"
            shouldSubmitOnEnter
            onSecondarySubmit={this.rejectStatus}
            onRequestSubmit={() => {
              this.rejectCertificate2();
            }}
          >
            <p>Sunteti sigur?</p>
          </Modal>
          <Modal
            className="other-modals"
            open={zipModal}
            onRequestClose={this.zipStatus}
            modalHeading="Arhivare"
            primaryButtonText="Da"
            secondaryButtonText="Nu"
            shouldSubmitOnEnter
            onSecondarySubmit={this.zipStatus}
            onRequestSubmit={() => {
              this.zip();
            }}
          >
            <p>Sunteti sigur?</p>
          </Modal>
          <Modal
            className="other-modals"
            open={unzipModal}
            onRequestClose={this.unzipStatus}
            modalHeading="Dezarhivare"
            primaryButtonText="Da"
            secondaryButtonText="Nu"
            shouldSubmitOnEnter
            onSecondarySubmit={this.unzipStatus}
            onRequestSubmit={() => {
              this.unzip();
            }}
          >
            <p>Sunteti sigur?</p>
          </Modal>
          <SearcheableTable
            title="Lista certificatelor"
            headers={headers}
            rows={certificates}
            getPage={getPage}
            direction="asc"
            pageDetails={pageDetails}
            searchPrecondition={search}
            tableResponsive
            searchFields={[
              'patientDetails.firstName',
              'patientDetails.lastName',
              'patientDetails.idnp',
            ]}
            cellRendering={cell => {
              if (cell.id.includes(':actions')) {
                const id = cell.id.match(/\d+/g)[0];
                const certificate = certificates.find(e => e.id === id);
                return (
                  <React.Fragment>
                    {loading ? (
                      <Loading />
                    ) : (
                      <React.Fragment>
                        <DataTable.TableCell key={cell.id}>
                          {certificate.statusObj.code !== 'CANCELED' && (
                            <React.Fragment>
                              {!certificate.archived &&
                                ((((hasRole('ROLE_VAL') &&
                                  (isTheSameUser(certificate.validatorUserId) ||
                                    isTheSameUser(
                                      certificate.initiatorUserId,
                                    ) ||
                                    isSubordinate(
                                      users.userOptions,
                                      certificate.initiatorUserId,
                                    ))) ||
                                  hasRole('ROLE_ADMIN')) &&
                                  (certificate.statusObj.code === 'PROCESS' ||
                                    certificate.statusObj.code === 'PENDING' ||
                                    certificate.statusObj.code === 'SIGNED' ||
                                    certificate.statusObj.code ===
                                      'UNCANCELED' ||
                                    certificate.statusObj.code === '00001' ||
                                    certificate.statusObj.code === '00002' ||
                                    certificate.statusObj.code === '00003' ||
                                    certificate.statusObj.code === '00004' ||
                                    certificate.statusObj.code === '00007' ||
                                    certificate.statusObj.code === '00005')) ||
                                  (hasRole('ROLE_REG') &&
                                    certificate.statusObj.code === 'PROCESS' &&
                                    isTheSameUser(
                                      certificate.initiatorUserId,
                                    ))) && (
                                  <NavLink
                                    title="Editare"
                                    to={`/concediu/${id}`}
                                    className="AccordLink"
                                  >
                                    <Edit16 />
                                  </NavLink>
                                )}
                              {!certificate.archived &&
                                ((((hasRole('ROLE_VAL') &&
                                  (isTheSameUser(certificate.validatorUserId) ||
                                    isSubordinate(
                                      users.userOptions,
                                      certificate.initiatorUserId,
                                    ) ||
                                    isTheSameUser(
                                      certificate.initiatorUserId,
                                    ))) ||
                                  hasRole('ROLE_ADMIN')) &&
                                  (certificate.statusObj.code === '00002' ||
                                    certificate.statusObj.code === '00005' ||
                                    certificate.statusObj.code === 'PROCESS' ||
                                    certificate.statusObj.code === 'PENDING' ||
                                    certificate.statusObj.code === 'SIGNED' ||
                                    certificate.statusObj.code === '00003')) ||
                                  (hasRole('ROLE_REG') &&
                                    certificate.statusObj.code === 'PROCESS' &&
                                    isTheSameUser(
                                      certificate.initiatorUserId,
                                    ))) && (
                                  <NavLink
                                    title="Anulare"
                                    to="#"
                                    onClick={() =>
                                      this.closeStatus(certificate)
                                    }
                                    className="AccordLink"
                                  >
                                    <Close16 />
                                  </NavLink>
                                )}
                            </React.Fragment>
                          )}
                          {!certificate.archived &&
                            (certificate.statusObj.code === 'PENDING' ||
                              certificate.statusObj.code === 'SIGNED' ||
                              certificate.statusObj.code === '00002' ||
                              certificate.statusObj.code === '00004') &&
                            ((hasRole('ROLE_VAL') &&
                              (isTheSameUser(certificate.validatorUserId) ||
                                isSubordinate(
                                  users.userOptions,
                                  certificate.initiatorUserId,
                                ) ||
                                isTheSameUser(certificate.initiatorUserId))) ||
                              hasRole('ROLE_ADMIN')) && (
                              <NavLink
                                to="#"
                                title="Trimite catre registrator"
                                onClick={() =>
                                  this.rejectStatus(certificate.id)
                                }
                                className="AccordLink"
                              >
                                <Launch16 />
                              </NavLink>
                            )}
                          {!certificate.archived &&
                            (certificate.statusObj.code === 'PENDING' ||
                              certificate.statusObj.code === '00002') &&
                            ((hasRole('ROLE_VAL') &&
                              (isTheSameUser(certificate.validatorUserId) ||
                                isSubordinate(
                                  users.userOptions,
                                  certificate.initiatorUserId,
                                ) ||
                                isTheSameUser(certificate.initiatorUserId))) ||
                              hasRole('ROLE_ADMIN')) && (
                              <NavLink
                                to="#"
                                title="Validare si semnare"
                                onClick={() =>
                                  this.approveStatus(certificate.id)
                                }
                                className="AccordLink"
                              >
                                <Checkmark />
                              </NavLink>
                            )}
                          {/* {!certificate.archived &&
                            (certificate.statusObj.code === '00001' ||
                              certificate.statusObj.code === '00002' ||
                              certificate.statusObj.code === '00003' ||
                              certificate.statusObj.code === '00004' ||
                              certificate.statusObj.code === '00005' ||
                              certificate.statusObj.code === '00006') &&
                            hasRole('ROLE_ADMIN') && (
                              <NavLink
                                to="#"
                                title="Verificare statut la CNAS"
                                onClick={() =>
                                  this.verifyStatus(certificate.id)
                                }
                                className="AccordLink"
                              >
                                <Renew />
                              </NavLink>
                            )} */}
                          {certificate.archived && hasRole('ROLE_ADMIN') && (
                            <NavLink
                              to="#"
                              title="Dezarhivare"
                              onClick={() => this.unzipStatus(certificate.id)}
                              className="AccordLink"
                            >
                              <WatsonHealthSaveSeries20 />
                            </NavLink>
                          )}
                          {!certificate.archived &&
                            hasRole('ROLE_ADMIN') &&
                            (certificate.statusObj.code === 'CANCELED' ||
                              certificate.statusObj.code === '00005' ||
                              certificate.statusObj.code === '00004') && (
                              <NavLink
                                title="Arhivare"
                                to="#"
                                onClick={() => this.zipStatus(certificate.id)}
                                className="AccordLink"
                              >
                                <WatsonHealthSaveAnnotation20 />
                              </NavLink>
                            )}
                        </DataTable.TableCell>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                );
              }
              return (
                <DataTable.TableCell
                  key={cell.id}
                  className={
                    cell.value && cell.value.length > 30 ? 'td-long' : null
                  }
                >
                  {cell.value}
                </DataTable.TableCell>
              );
            }}
          />
        </div>
      </div>
    );
  }
}

const ReduxForm = reduxForm({
  form: 'listaCertificate',
  enableReinitialize: true,
})(ListaCertificate);

export default connect(
  state => {
    const { users, concedii, taxonomies, form } = state;
    const { loading } = concedii;
    const { listaCertificate } = form;
    const page = concedii && concedii.page ? concedii.page : null;

    if (page) {
      return {
        loading,
        users: users && { userOptions: users.usersSearch },
        taxonomies: taxonomies.taxonomies,
        cetificateStatuses: taxonomies.taxonomies.NmCertificateStatuses,
        certificates: page.content.map(el => {
          return {
            ...el,
            id: el.id.toString(),
            primaryCertificateSeries: get(el, 'primaryCertificate.series', ''),
            primaryCertificateNumber: get(el, 'primaryCertificate.number', ''),
            primaryCertificateStartDateStr:
              el.primaryCertificate && el.primaryCertificate.startDate
                ? new Date(el.primaryCertificate.startDate).toLocaleDateString()
                : '',
            primaryCertificateEndDateStr:
              el.primaryCertificate && el.primaryCertificate.endDate
                ? new Date(el.primaryCertificate.endDate).toLocaleDateString()
                : '',
            startDateStr: el.dateCompletion
              ? new Date(el.dateCompletion).toLocaleDateString()
              : '',
            firstName: get(el, 'patientDetails.firstName', null),
            lastName: get(el, 'patientDetails.lastName', null),
            idnp: get(el, 'patientDetails.idnp', null),
            dateBirth:
              el.patientDetails && el.patientDetails.dateBirth
                ? new Date(el.patientDetails.dateBirth).toLocaleDateString()
                : '',
            grantedDays: el.grantedDays,
            statusObj: el.status,
            type: el.type && el.type.description,
            incapacityCauseCategory:
              el.incapacityCauseCategory &&
              el.incapacityCauseCategory.description,
            incapacityCauseSubcategory: get(
              el,
              'incapacityCauseSubcategory.description',
              null,
            )
              ? get(el, 'incapacityCauseSubcategory.description', null)
              : '',
            regimen: el.regimen && el.regimen.description,
            cpas: el.patientDetails && el.patientDetails.cpas,
            personStatus: get(el, 'personStatus.description', null)
              ? get(el, 'personStatus.description', null)
              : '',
            idnpChild: get(el, 'childDetails.idnp', null)
              ? get(el, 'childDetails.idnp', null)
              : '',
            phoneNumber: get(el, 'phoneNumber', null)
              ? get(el, 'phoneNumber', null)
              : '',
            birthCertificateSeries: get(el, 'birthCertificateSeries', null)
              ? get(el, 'birthCertificateSeries', null)
              : '',
            birthCertificateNumber: get(el, 'birthCertificateNumber', null)
              ? get(el, 'birthCertificateNumber', null)
              : '',
            childFirstName: get(el, 'childDetails.firstName', null)
              ? get(el, 'childDetails.firstName', null)
              : '',
            childLastName: get(el, 'childDetails.lastName', null)
              ? get(el, 'childDetails.lastName', null)
              : '',
            childFatherName: get(el, 'childDetails.fatherName', null)
              ? get(el, 'childDetails.fatherName', null)
              : '',
            childBirthDate: get(el, 'childDetails.birthDate', null)
              ? new Date(el.childDetails.birthDate).toLocaleDateString()
              : '',
            startDate: new Date(el.startDate).toLocaleDateString(),
            endDate: new Date(el.endDate).toLocaleDateString(),
            dateExitToWork:
              get(el, 'prescribed.id', null) === 1 && el.dateExitToWork
                ? new Date(el.dateExitToWork).toLocaleDateString()
                : '',
            dateStartBreach: el.dateStartBreach
              ? new Date(el.dateStartBreach).toLocaleDateString()
              : '',
            dateEndBreach: el.dateEndBreach
              ? new Date(el.dateEndBreach).toLocaleDateString()
              : '',
            dateCompletion: el.dateCompletion
              ? new Date(el.dateCompletion).toLocaleDateString()
              : '',
            prescribed: get(el, 'prescribed.description', null)
              ? get(el, 'prescribed.description', null)
              : '',
            breachCause: get(el, 'breachCause.description', null)
              ? get(el, 'breachCause.description', null)
              : '',
            certificationPlace: get(el, 'certificationPlace.description', null)
              ? get(el, 'certificationPlace.description', null)
              : '',
            institution: get(el, 'initiatorUser.institution.description', null),
            initiatorUserId: get(el, 'initiatorUser.id', null),
            initiatorUser:
              get(el, 'initiatorUser.firstName', null) &&
              get(el, 'initiatorUser.lastName', null)
                ? `${el.initiatorUser.firstName} ${el.initiatorUser.lastName}`
                : '',
            validatorUserId: get(el, 'validatorUser.id', null),
            validatorUser:
              get(el, 'validatorUser.firstName', null) &&
              get(el, 'validatorUser.lastName', null)
                ? `${el.validatorUser.firstName} ${el.validatorUser.lastName}`
                : '',
            validatorPhoneNumber: get(el, 'validatorUser.phoneNumber', ''),
            subcategoryStatus: get(
              el,
              'subcategoryStatus.operatorDescription',
              null,
            ),
            hasNotifications: get(el, 'hasNotifications', null) ? 'Da' : 'Nu',
            status:
              // eslint-disable-next-line eqeqeq
              el.cnasErrorMsg &&
              // eslint-disable-next-line eqeqeq
              el.status.code == '00002'
                ? `${get(el, 'status.description', null)} - ${get(
                    el,
                    'cnasErrorMsg',
                    null,
                  )}`
                : get(el, 'status.description', null),
          };
        }),
        pageDetails: {
          page: page.number ? page.number + 1 : 1,
          pageSize: page.page ? page.page : 10,
          totalItems: page.totalElements ? page.totalElements : 0,
          orderBy: page.orderBy ? page.orderBy : 'startDate',
          direction: page.direction ? page.direction : 'asc',
          search: page.search ? page.search : [],
        },
        listaCertificate,
      };
    }
    return {
      users: users && { userOptions: users.usersSearch },
      taxonomies: taxonomies.taxonomies,
      certificates: [],
      pageDetails: {
        page: 1,
        pageSize: 10,
        totalItems: 0,
        orderBy: 'startDate',
        direction: 'asc',
      },
      listaCertificate,
      loading,
    };
  },
  {
    getPage: concediiActions.getPage,
    getUsersForSearchCertificates: userActions.getUsersForSearchCertificates,
    updateCetificate: concediiActions.updateCetificate,
    sendConcediuToCNAS: concediiActions.sendConcediuToCNAS,
    filterCertificate: concediiActions.filterCertificate,
    downloadFile: concediiActions.downloadFile,
    postSignForCertId: msignActions.postSignForCertId,
    sendCancelToCNAS: concediiActions.sendCancelToCNAS,
    rejectCertificate: concediiActions.rejectCertificate,
    zipAction: concediiActions.zipCertificate,
    unzipAction: concediiActions.unzipCertificate,
    verifyCertificateOnCNASAction: concediiActions.verifyCertificateOnCNAS,
    showError: alertActions.error,
  },
)(ReduxForm);
