/* eslint-disable react/prop-types */
import React from 'react';
import { FormGroup, Checkbox } from 'carbon-components-react';

class AlchemyCheckboxGroup extends React.Component {
  checkboxGroup() {
    const { options, input } = this.props;
    return options.map(option => {
      return (
        <Checkbox
          id={option.id}
          labelText={option.name}
          checked={input.value.indexOf(option.id) !== -1}
          onChange={(value, id, event) => {
            const newValue = [...input.value];
            if (event.target.checked) {
              newValue.push(id);
            } else {
              newValue.splice(newValue.indexOf(id), 1);
            }
            return input.onChange(newValue);
          }}
        />
      );
    });
  }

  render() {
    return <FormGroup>{this.checkboxGroup()}</FormGroup>;
  }
}

export default AlchemyCheckboxGroup;
