import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  DataTable,
  Button,
  MultiSelect,
  Modal,
  Form,
} from 'carbon-components-react';
import styled from 'styled-components';
import Add24 from '@carbon/icons-react/es/add/24';
import { NavLink } from 'react-router-dom';
import Edit16 from '@carbon/icons-react/es/edit/16';
import { userActions, groupActions } from '../../redux/actions';
import SearcheableTable from '../../components/table/SearcheableTable';
import EditUserForm from './forms/EditUserForm';
import CreateUserForm from './forms/CreateUserForm';
import { getUserGroupFromCell } from '../../helpers/utils';
import { $brand01, $ui01, $ui05 } from '../../themeVariables';
import AlchemyTextInput from '../../components/form/AlchemyTextInput';

const headers = [
  { code: 'id', description: 'Id' },
  { code: 'userName', description: 'Nume utilizator' },
  { code: 'email', description: 'Email' },
  { code: 'firstName', description: 'Prenume' },
  { code: 'lastName', description: 'Nume' },
  { code: 'idnp', description: 'IDNP' },
  { code: 'institutionValue', description: 'Institutie' },
];

const ModalContent = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
  min-height: 400px;
`;

const Boxs = styled.div`
  margin-top: 1rem;
`;

const groupsName = 'Roluri';

const InputGroup = styled.div`
  margin-bottom: 1.3rem;
  text-align: left;
`;

const Label = styled.label`
  font-size: 0.75rem;
`;

const Input = styled.input`
  font-size: 0.875rem;
  font-weight: 400;
  width: 100%;
  background: ${$ui01};
  padding: 0 1rem;
  margin: 8px 0;
  box-sizing: border-box;
  border: 0.2rem solid transparent;
  -webkit-transition: 0.1s;
  transition: 0.1s;
  outline: 2px solid #e0e0e0;
  outline-offset: -2px;
  background: #fff;
  height: 2.5rem;

  &::placeholder {
    color: #8c8c8c;
  }
  &:focus {
    color: ${$ui05};
    outline: 2px solid ${$brand01};
  }
`;

class UserTable extends Component {
  static propTypes = {
    users: PropTypes.instanceOf(Array).isRequired,
    allGroups: PropTypes.instanceOf(Array),
    editUser: PropTypes.instanceOf(Function).isRequired,
    pageDetails: PropTypes.instanceOf(Object).isRequired,
    userForm: PropTypes.instanceOf(Object).isRequired,
    getPage: PropTypes.instanceOf(Function).isRequired,
    getGroups: PropTypes.instanceOf(Function).isRequired,
    resetPassword: PropTypes.instanceOf(Function).isRequired,
  };

  static defaultProps = {
    allGroups: [],
  };

  state = {
    showCreateUserForm: false,
    resetPwdModal: false,
    user: null,
    password: '',
  };

  componentDidMount() {
    const { getPage, getGroups } = this.props;
    getPage({
      sortDirection: null,
      page: 1,
      pageSize: 10,
      orderBy: null,
      search: null,
    });
    getGroups();
  }

  onGroupsDropdownChange = ({ selectedItems }, cell) => {
    const userId = cell.match(/\d+/g)[0];
    const { users, editUser } = this.props;
    // eslint-disable-next-line
    const changedUser = users.filter(user => user.id == userId)[0];
    const changedUsersWithNewPermissions = {
      ...changedUser,
      roles: selectedItems,
    };
    editUser(changedUsersWithNewPermissions);
  };

  openCreateUserForm = () => {
    this.setState({
      showCreateUserForm: true,
    });
  };

  resetPasswordModal = user => {
    this.setState(state => ({
      resetPwdModal: !state.resetPwdModal,
      user,
    }));
  };

  closeCreateUserForm = () => {
    const { getPage } = this.props;
    this.setState({
      showCreateUserForm: false,
    });
    getPage({
      sortDirection: null,
      page: 1,
      pageSize: 10,
      orderBy: null,
      search: null,
    });
  };

  resetPassword = user => {
    const { resetPassword } = this.props;
    const { password } = this.state;
    resetPassword(user.id, password);
    this.setState(state => ({
      resetPwdModal: !state.resetPwdModal,
      user,
    }));
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  filterHandler = input => {
    const filterName =
      input.currentTarget.name === 'institution'
        ? 'institution.description'
        : input.currentTarget.name;
    const { userForm, getPage } = this.props;
    let filters = [];

    if (userForm.values) {
      Object.entries(userForm.values).forEach(value => {
        filters.push({
          key: `${
            value[0] === 'institution' ? 'institution.description' : value[0]
          }`,
          operation: ':',
          value: value[1],
        });
      });
    }

    filters = filters.filter(e => e.key !== filterName);
    filters.push({
      key: `${filterName}`,
      operation: ':',
      value: `${input.currentTarget.value}`,
    });
    getPage({
      sortDirection: null,
      page: 1,
      pageSize: 10,
      orderBy: null,
      search: filters,
    });
  };

  render() {
    const { users, allGroups, pageDetails, getPage } = this.props;
    const { showCreateUserForm, resetPwdModal, user, password } = this.state;
    const filterStyle = {
      marginBottom: '1rem',
    };
    if (showCreateUserForm) {
      return <CreateUserForm closeCreateUserForm={this.closeCreateUserForm} />;
    }

    return (
      users && (
        <React.Fragment>
          <div className="box__quarter-block" style={filterStyle}>
            <Field
              name="firstName"
              type="text"
              component={AlchemyTextInput}
              label="Prenume utilizator"
              onChange={this.filterHandler}
            />
            <Field
              name="lastName"
              type="text"
              component={AlchemyTextInput}
              label="Nume utilizator"
              onChange={this.filterHandler}
            />
            <Field
              name="idnp"
              type="text"
              component={AlchemyTextInput}
              label="IDNP utilizator"
              onChange={this.filterHandler}
            />
            <Field
              name="institution"
              type="text"
              component={AlchemyTextInput}
              label="Institutia"
              onChange={this.filterHandler}
            />
          </div>
          <SearcheableTable
            title="Lista Utilizatori"
            headers={headers}
            rows={users}
            getPage={getPage}
            isExpanded
            tableExpanded
            pageDetails={pageDetails}
            className="table-responsive"
            searchFields={[
              'userName',
              'firstName',
              'lastName',
              'email',
              'idnp',
              // 'institution',
            ]}
            cellRendering={cell => {
              if (cell.id.includes(':roles')) {
                const userGroups = getUserGroupFromCell(cell.id, users);
                return (
                  <DataTable.TableCell className="bx--pt0" key={cell.id}>
                    <MultiSelect.Filterable
                      id={cell.id}
                      className="data-input"
                      items={allGroups}
                      itemToString={item => (item ? item.code : '')}
                      placeholder={groupsName}
                      initialSelectedItems={userGroups}
                      onChange={data =>
                        this.onGroupsDropdownChange(data, cell.id)
                      }
                    />
                  </DataTable.TableCell>
                );
              }
              if (cell.id.includes(':actions')) {
                const id = cell.id.match(/\d+/g)[0];
                const userSel = users.find(e => e.id === id);
                return (
                  <React.Fragment>
                    <DataTable.TableCell key={cell.id}>
                      <NavLink
                        title="Resetare parola"
                        to="#"
                        onClick={() => this.resetPasswordModal(userSel)}
                        className="AccordLink"
                      >
                        <Edit16 />
                      </NavLink>
                    </DataTable.TableCell>
                  </React.Fragment>
                );
              }
              return (
                <DataTable.TableCell key={cell.id}>
                  {cell.value}
                </DataTable.TableCell>
              );
            }}
          >
            <Button
              component="topButton"
              kind="primary"
              onClick={() => this.openCreateUserForm()}
            >
              Adauga
              <Add24 className="bx--btn__icon" />
            </Button>
            <EditUserForm component="editForm" />
          </SearcheableTable>
          <Modal
            open={resetPwdModal}
            onRequestClose={this.resetPasswordModal}
            modalHeading="Resetare parola"
            primaryButtonText="Salvare"
            secondaryButtonText="Anulare"
            onRequestSubmit={() => this.resetPassword(user)}
            onSecondarySubmit={this.resetPasswordModal}
          >
            <ModalContent>
              <Boxs>
                <Form>
                  <InputGroup>
                    <Label>Parola</Label>
                    <Input
                      name="password"
                      type="password"
                      value={password}
                      onChange={this.handleChange}
                    />
                  </InputGroup>
                </Form>
              </Boxs>
            </ModalContent>
          </Modal>
        </React.Fragment>
      )
    );
  }
}
const ReduxForm = reduxForm({
  form: 'userForm',
  enableReinitialize: true,
})(UserTable);
export default connect(
  state => {
    const { users, groups, form } = state;
    const { userForm } = form;
    const page = users && users.page ? users.page : null;
    if (page && page.totalElements > 0) {
      return {
        users: page.content.map(u => {
          return {
            ...u,
            id: u.id.toString(),
            institutionValue: u.institution && u.institution.description,
          };
        }),
        pageDetails: {
          page: page.number ? page.number + 1 : 1,
          totalItems: page.totalElements ? page.totalElements : 0,
          isLastPage: page.last,
          pageSize: page.size ? page.size : 0,
        },
        allGroups: groups.groups,
        recorders: [],
        userForm,
      };
    }
    return {
      userForm,
      recorders: [],
      users: [],
      groups: [],
      pageDetails: { page: 0, totalItems: 0, pageSize: 0 },
    };
  },
  {
    getPage: userActions.getPage,
    getGroups: groupActions.getGroups,
    editUser: userActions.editUser,
    resetPassword: userActions.resetPassword,
  },
)(ReduxForm);
