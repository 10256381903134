import { TaxonomyActions } from '../types';

const initialState = {
  taxonomies: {},
};

export function taxonomies(state = initialState, action) {
  switch (action.type) {
    case TaxonomyActions.TAXONOMY_GET_ALL_REQUEST:
      return {
        ...state,
      };
    case TaxonomyActions.TAXONOMY_GET_ALL_SUCCESS:
      return {
        ...state,
        taxonomies: action.taxonomies,
      };
    case TaxonomyActions.TAXONOMY_GET_ALL_ERROR:
      return {
        taxonomies: state.taxonomies,
        error: action.error,
      };
    case TaxonomyActions.TAXONOMY_GET_ALL_WITHOUT_CONTENT_REQUEST:
      return {
        ...state,
      };
    case TaxonomyActions.TAXONOMY_GET_ALL_WITHOUT_CONTENT_SUCCESS:
      return {
        ...state,
        taxonomiesWithoutContent: action.taxonomiesWithoutContent,
      };
    case TaxonomyActions.TAXONOMY_GET_ALL_WITHOUT_CONTENT_ERROR:
      return {
        taxonomiesWithoutContent: state.taxonomiesWithoutContent,
        error: action.error,
      };
    case TaxonomyActions.GET_SERVER_DATE_REQUEST:
      return {
        ...state,
      };
    case TaxonomyActions.GET_SERVER_DATE_SUCCESS:
      return {
        ...state,
        serverDate: action.serverDate,
      };
    case TaxonomyActions.GET_SERVER_DATE_FAILURE:
      return {
        error: action.error,
      };
    case TaxonomyActions.TAXONOMY_GET_ENTITY_REQUEST:
      return {
        ...state,
      };
    case TaxonomyActions.TAXONOMY_GET_ENTITY_SUCCESS:
      return {
        ...state,
        taxonomyEntity: action.page,
      };
    case TaxonomyActions.TAXONOMY_GET_ENTITY_ERROR:
      return {
        taxonomyEntity: state.page,
        error: action.error,
      };
    case TaxonomyActions.TAXONOMY_GET_BY_ID_REQUEST:
      return {
        ...state,
      };
    case TaxonomyActions.TAXONOMY_GET_BY_ID_SUCCESS:
      return {
        ...state,
        requestedTaxonomy: action.taxonomy,
      };
    case TaxonomyActions.TAXONOMY_GET_BY_ID_ERROR:
      return {
        requestedTaxonomy: state.taxonomy,
        error: action.error,
      };
    case TaxonomyActions.TAXONOMY_GET_COLUMN_DETAILS_REQUEST:
      return {
        ...state,
      };
    case TaxonomyActions.TAXONOMY_GET_COLUMN_DETAILS_SUCCESS:
      return {
        ...state,
        columnDetails: action.columnDetails,
      };
    case TaxonomyActions.TAXONOMY_GET_COLUMN_DETAILS_ERROR:
      return {
        columnDetails: state.columnDetails,
        error: action.error,
      };
    case TaxonomyActions.CLEAR_REQUESTED_TAXONOMY:
      return {
        ...state,
        requestedTaxonomy: null,
      };
    default:
      return state;
  }
}
