import { AuditActions } from '../types';
import { post } from '../../helpers';

const getAuditLoginsPage = pageDetails => async dispatch => {
  dispatch({ type: AuditActions.GET_USER_LOGINS_REQUEST });
  try {
    const response = await post(`/audit/user-logins`, pageDetails);
    const page = await response.json();

    dispatch({ type: AuditActions.GET_USER_LOGINS_SUCCESS, page });
  } catch (err) {
    dispatch({ type: AuditActions.GET_USER_LOGINS_ERROR });
  }
};

const getAuditVisitsPage = pageDetails => async dispatch => {
  dispatch({ type: AuditActions.GET_USER_VISITS_REQUEST });
  try {
    const response = await post(`/audit/visits`, pageDetails);
    const page = await response.json();

    dispatch({ type: AuditActions.GET_USER_VISITS_SUCCESS, page });
  } catch (err) {
    dispatch({ type: AuditActions.GET_USER_VISITS_ERROR });
  }
};

export const auditActions = {
  getAuditLoginsPage,
  getAuditVisitsPage,
};
