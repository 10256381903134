import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { userActions } from '../../redux/actions';
import SearcheableTable from '../../components/table/SearcheableTable';

const headers = [
  { code: 'id', description: 'Id' },
  { code: 'userName', description: 'Nume utilizator' },
  { code: 'email', description: 'Email' },
  { code: 'firstName', description: 'Prenume' },
  { code: 'lastName', description: 'Nume' },
];

class GroupUsersTable extends Component {
  static propTypes = {
    users: PropTypes.instanceOf(Array).isRequired,
    pageDetails: PropTypes.instanceOf(Object).isRequired,
    data: PropTypes.instanceOf(Object).isRequired,
    getPage: PropTypes.instanceOf(Function).isRequired,
  };

  componentDidMount() {
    const { getPage, data } = this.props;

    getPage({
      sortDirection: null,
      page: 1,
      pageSize: 5,
      orderBy: null,
      search: [this.createPrecondition(data)],
    });
  }

  createPrecondition = data => {
    return {
      orPredicate: false,
      key: 'roles.id',
      operation: ':',
      value: data.id,
    };
  };

  render() {
    const { users, pageDetails, getPage, data } = this.props;
    const searchPrecondition = this.createPrecondition(data);

    return (
      users && (
        <SearcheableTable
          headers={headers}
          rows={users}
          getPage={getPage}
          pageDetails={pageDetails}
          searchPrecondition={searchPrecondition}
          searchFields={['username', 'firstName', 'lastName', 'email']}
        >
          {/* <DataTable.TableBatchActions component="batchActions">
            <DataTable.TableBatchAction
              onClick={selectedRows => {
                const selectedUsers = selectedRows.map(e => e.id);
                removeUserFromGroup(data.id, selectedUsers);
              }}
            >
              Elimina din rol
            </DataTable.TableBatchAction>
          </DataTable.TableBatchActions> */}
        </SearcheableTable>
      )
    );
  }
}

export default connect(
  state => {
    const { users } = state;
    const page = users && users.page ? users.page : null;

    return page && page.totalElements > 0
      ? {
          users: page.content.map(u => {
            return {
              ...u,
              id: u.id.toString(),
            };
          }),
          pageDetails: {
            page: page.number ? page.number + 1 : 1,
            totalItems: page.totalElements ? page.totalElements : 0,
            isLastPage: page.last,
            pageSize: page.size ? page.size : 0,
          },
        }
      : { users: [], pageDetails: { page: 0, totalItems: 0, pageSize: 0 } };
  },
  {
    getPage: userActions.getPage,
  },
)(GroupUsersTable);
