import React, { Component } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import GlobalStyle from './GlobalStyle';
import LoginPage from './pages/Login';
import LogoutRedirectPage from './pages/LogoutRedirect';
import LoginNewPage from './pages/LoginNew';
import MainLayout from './pages/MainLayout';
import DefaultLayout from './pages/DefaultLayout';
import UserManagementTabs from './modules/users/UserManagementTabs';
import ConcediuGrandForm from './modules/concedii/ConcediuGrandForm';
import ListaCerficate from './modules/concedii/ListaCertificate';
import EditUserForm from './modules/users/forms/EditUserForm';
import BlankPage from './pages/BlankPage';
import { history } from './helpers';
import {
  alertActions,
  taxonomyActions,
  concediiActions,
} from './redux/actions';
import { PrivateRoute } from './components/PrivateRoute';
import ProfilePage from './pages/ProfilePage';
import ChangePassword from './pages/ChangePassword';
import ChangeExpiredPassword from './pages/ChangeExpiredPassword';
import CreateTaxonomyForm from './modules/taxonomy/forms/CreateTaxonomyForm';
import MSignReceiveForm from './modules/msign/MSignReceiveForm';
import MSignRedirectForm from './modules/msign/MSignRedirectForm';
import MPassLoginResult from './modules/mpass/MPassLoginResult';
import TaxonomyManagementForm from './modules/taxonomy/TaxonomyManagementForm';
import Audit from './modules/audit/Audit';
import { inactivityChecker } from './inactivityChecker';
import Wrong from './pages/Wrong';
import GuideFiles from './pages/GuideFiles';
import SetStatusForm from './modules/concedii/SetStatusForm';
import Notificari from './modules/concedii/Notificari';
import CheckCertificateStatus from './pages/CheckCertificateStatus';
import ReportCertificatesNumber from './modules/concedii/ReportCertificatesNumber';
import FisaBeneficiar from './modules/concedii/FisaBeneficiar';

const UserManagementPage = MainLayout(UserManagementTabs);
const TaxonomyManagementPage = MainLayout(TaxonomyManagementForm);
const NewTaxonomyPage = MainLayout(CreateTaxonomyForm);
const ConcediuGrandFormPage = MainLayout(ConcediuGrandForm);
const SetStatusPage = MainLayout(SetStatusForm);
const ListaCerficatePage = MainLayout(ListaCerficate);
const NotificariPage = MainLayout(Notificari);
const ReportCertificatesNumberPage = MainLayout(ReportCertificatesNumber);
const FisaBeneficiarPage = MainLayout(FisaBeneficiar);
const CheckCertificateStatusPage = DefaultLayout(CheckCertificateStatus);
const EditUserFormPage = MainLayout(EditUserForm);
const BlanksManagementPage = MainLayout(BlankPage);
const ProfileUserPage = MainLayout(ProfilePage);
const ChangePasswordPage = MainLayout(ChangePassword);
const AuditPage = MainLayout(Audit);
const WrongPages = MainLayout(Wrong);
const GuideFilesPage = MainLayout(GuideFiles);

class App extends Component {
  static propTypes = {
    clear: PropTypes.instanceOf(Function).isRequired,
    resetConcediuReduxForm: PropTypes.instanceOf(Function).isRequired,
  };

  constructor(props) {
    super(props);
    const { clear, resetConcediuReduxForm } = this.props;
    // clear alerts on location change
    history.listen(address => {
      if (address.pathname === '/forma-concediu-medical') {
        resetConcediuReduxForm();
        clear();
      }
    });

    inactivityChecker();
  }

  componentDidMount() {
    if (sessionStorage.getItem('token')) {
      // eslint-disable-next-line react/prop-types
      const { getAllTaxonomies } = this.props;
      getAllTaxonomies();
    }
  }

  render() {
    return (
      <React.Fragment>
        <GlobalStyle />
        <Router history={history}>
          {/* <PrivateRoute exact path="/" component={HomePage} /> */}
          <Switch>
            <PrivateRoute
              exact
              path="/forma-concediu-medical"
              component={ConcediuGrandFormPage}
            />
            <PrivateRoute
              exact
              path="/profil-utilizator"
              component={ProfileUserPage}
            />
            <PrivateRoute
              exact
              path="/schimbare-parola"
              component={ChangePasswordPage}
            />
            <PrivateRoute
              path="/concediu/:id"
              component={ConcediuGrandFormPage}
            />
            <PrivateRoute exact path="/" component={ListaCerficatePage} />
            <PrivateRoute
              exact
              path="/notifications"
              component={NotificariPage}
            />
            <PrivateRoute
              exact
              path="/report-certificates-number"
              component={ReportCertificatesNumberPage}
            />
            <PrivateRoute
              exact
              path="/fisa-beneficiar"
              component={FisaBeneficiarPage}
            />
            <PrivateRoute
              exact
              // restriction={!hasRole('ROLE_ADMIN')}
              path="/access-management"
              component={UserManagementPage}
            />
            <PrivateRoute
              exact
              // restriction={!hasRole('ROLE_ADMIN')}
              path="/set-status"
              component={SetStatusPage}
            />
            <PrivateRoute
              exact
              // restriction={!hasRole('ROLE_ADMIN')}
              path="/taxonomy-management"
              component={TaxonomyManagementPage}
            />
            <PrivateRoute
              exact
              path="/audit"
              // restriction={!(hasRole('ROLE_ADMIN') || hasRole('ROLE_AUDIT'))}
              component={AuditPage}
            />
            <PrivateRoute
              exact
              // restriction={!hasRole('ROLE_ADMIN')}
              path="/taxonomy-management/:entityName"
              component={TaxonomyManagementPage}
            />
            <PrivateRoute
              exact
              // restriction={!hasRole('ROLE_ADMIN')}
              path="/new-taxonomy/:entityName/:entityDescription"
              component={NewTaxonomyPage}
            />
            <PrivateRoute
              exact
              // restriction={!hasRole('ROLE_ADMIN')}
              path="/edit-taxonomy/:id/:entityName/:entityDescription"
              component={NewTaxonomyPage}
            />
            <PrivateRoute
              exact
              // restriction={!hasRole('ROLE_ADMIN')}
              path="/edit-user-form"
              component={EditUserFormPage}
            />
            <PrivateRoute
              exact
              path="/blanks-management"
              component={BlanksManagementPage}
            />
            <PrivateRoute
              exact
              path="/msign-redirect"
              component={MSignRedirectForm}
            />
            <PrivateRoute
              exact
              path="/msign-receive/:certificateId/:requestId"
              component={MSignReceiveForm}
            />
            <PrivateRoute
              exact
              path="/login-result/:token"
              component={MPassLoginResult}
            />
            <Route path="/login" component={LoginPage} />
            <Route
              path="/logout-redirect/:logoutResponse"
              component={LogoutRedirectPage}
            />
            <Route path="/login-new" component={LoginNewPage} />
            <Route
              exact
              path="/schimbare-parola-expirata"
              component={ChangeExpiredPassword}
            />
            <Route
              exact
              path="/check-certificate-status"
              component={CheckCertificateStatusPage}
            />
            <Route exact path="/guide-files" component={GuideFilesPage} />
            <Route component={WrongPages} />
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

export default connect(
  state => {
    const { authentication } = state;
    return {
      user: authentication.user,
    };
  },
  {
    clear: alertActions.clear,
    resetConcediuReduxForm: concediiActions.resetConcediuReduxForm,
    getAllTaxonomies: taxonomyActions.getAllTaxonomies,
  },
)(App);
