import { AlertActions } from '../types';

export function alert(state = {}, action) {
  switch (action.type) {
    case AlertActions.SUCCESS:
      return {
        type: action.type,
        message: action.message || 'Succes',
      };
    case AlertActions.ERROR:
      return {
        type: action.type,
        message: action.message || 'Exceptie',
      };
    case AlertActions.WARNING:
      return {
        type: action.type,
        message: action.message || 'Atentionare',
      };
    case AlertActions.CLEAR:
      return {};
    default:
      return state;
  }
}
