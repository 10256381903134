/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { taxonomyActions } from '../../../redux/actions';
import { Field, reduxForm } from 'redux-form';
import { Form, Button } from 'carbon-components-react';
import AlchemyTextInput from '../../../components/form/AlchemyTextInput';
import { history } from '../../../helpers/history';
import AlchemyDateTimePicker from '../../../components/form/AlchemyDateTimePicker';
import AlchemyNotification from '../../../components/form/AlchemyNotification';
import * as validation from '../../../helpers/validation';

import '../../style.scss';

class CreateTaxonomyForm extends React.PureComponent {
  componentDidMount() {
    const {
      match,
      getTaxonomyById,
      clearInitialValues,
      getColumnDetails,
    } = this.props;
    const { params } = match;
    const { id, entityName } = params;
    getColumnDetails(entityName);
    if (id) {
      getTaxonomyById(id, entityName);
    } else {
      clearInitialValues();
    }
  }

  render() {
    const {
      handleSubmit,
      submitting,
      createTaxonomyReduxForm,
      match,
      editTaxonomy,
      columnDetails,
    } = this.props;
    const { params } = match;
    const { entityName, entityDescription, id } = params;

    let title = 'Adauga - ' + entityDescription;
    if (id) {
      title = 'Editare - ' + entityDescription;
    }

    let fields = [];
    columnDetails
      ? columnDetails.forEach(element => {
          fields.push({
            code: element.columnName,
            description: element.columnDescription,
            dataType: element.dataType,
          });
        })
      : [];

    return (
      <React.Fragment>
        <AlchemyNotification />
        <Form
          className="box"
          onSubmit={handleSubmit(() => {
            return id
              ? editTaxonomy(id, entityName)
              : createTaxonomyReduxForm(entityName);
          })}
        >
          <div className="box__container">
            <h1 className="box__title">{title}</h1>
            <h4 />
            <div className="box__double-block ">
              {fields
                .filter(el => el.code != 'id')
                .map(el => {
                  if (el.dataType === 'date') {
                    return (
                      <Field
                        name={el.code}
                        labelText={el.description}
                        component={AlchemyDateTimePicker}
                      />
                    );
                  }
                  if (el.dataType === 'int') {
                    return (
                      <Field
                        name={el.code}
                        type="number"
                        component={AlchemyTextInput}
                        label={el.description}
                        validate={validation.required}
                      />
                    );
                  }
                  return (
                    <Field
                      name={el.code}
                      type="text"
                      component={AlchemyTextInput}
                      label={el.description}
                      validate={validation.required}
                    />
                  );
                })}
            </div>
            <div>
              <div className="box__quarter-block ">
                <button
                  className="alchemy-discard-button"
                  type="button"
                  onClick={() => {
                    history.push(`/taxonomy-management/${entityName}`);
                  }}
                >
                  Anuleaza
                </button>
                <button
                  className="alchemy-button"
                  type="submit"
                  disabled={submitting}
                >
                  Salveaza
                </button>
              </div>
            </div>
          </div>
        </Form>
      </React.Fragment>
    );
  }
}

const ReduxForm = reduxForm({
  form: 'createTaxonomyForm',
  enableReinitialize: true,
})(CreateTaxonomyForm);

export default connect(
  state => {
    const { taxonomies } = state;
    return {
      taxonomies: taxonomies.taxonomies,
      initialValues: taxonomies.requestedTaxonomy,
      columnDetails: taxonomies.columnDetails,
    };
  },
  {
    createTaxonomyReduxForm: taxonomyActions.createTaxonomyReduxForm,
    getTaxonomyById: taxonomyActions.getTaxonomyById,
    editTaxonomy: taxonomyActions.editTaxonomy,
    clearInitialValues: taxonomyActions.clearRequestedTaxonomy,
    getColumnDetails: taxonomyActions.getColumnDetails,
  },
)(ReduxForm);
