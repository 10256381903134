import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DataTable, Button } from 'carbon-components-react';
import Search16 from '@carbon/icons-react/es/search/16';
import Reset20 from '@carbon/icons-react/es/reset/20';
import get from 'lodash/get';
import { Field, reduxForm } from 'redux-form';
import {
  concediiActions,
  userActions,
  alertActions,
  msignActions,
} from '../../redux/actions';
import SearcheableTable from '../../components/table/SearcheableTable';
import AlchemyTextInput from '../../components/form/AlchemyTextInput';
import AlchemyAdvancedSelect from '../../components/form/AlchemyAdvancedSelect';
import AlchemyDateTimePicker from '../../components/form/AlchemyDateTimePicker';
import '../style.scss';
import AlchemyNotification from '../../components/form/AlchemyNotification';
import { getUserFromToken, hasRole } from '../../helpers/utils';

const headers = [
  { code: 'id', description: 'ID' },
  { code: 'notification', description: 'Notificare' },
  { code: 'idnp', description: 'IDNP pacient' },
  {
    code: 'cpas',
    description: 'CPAS pacient',
  },
  { code: 'lastName', description: 'Nume pacient' },
  { code: 'firstName', description: 'Prenume pacient' },
  { code: 'series', description: 'Serie certificat' },
  { code: 'number', description: 'Numar certificat' },
  { code: 'type', description: 'Tip certificat' },
  { code: 'status', description: 'Statut certificat' },
  { code: 'dateCompletionStr', description: 'Data completarii' },
  { code: 'startDate', description: 'Data acordarii concediului' },
  { code: 'endDate', description: 'Data inchiderii concediului' },
  { code: 'initiatorUser', description: 'Registrator' },
  { code: 'validatorUser', description: 'Validator nume prenume' },
  { code: 'validatorPhoneNumber', description: 'Validator numar de telefon' },
  { code: 'institution', description: 'Institutia' },
];

const Container = styled.div`
  padding: 3rem;
`;

class Notificari extends Component {
  static propTypes = {
    notificationsList: PropTypes.instanceOf(Array).isRequired,
    pageDetails: PropTypes.instanceOf(Object).isRequired,
    getUsersForSearchCertificates: PropTypes.instanceOf(Function).isRequired,
    reset: PropTypes.instanceOf(Function).isRequired,
    listaCertificate: PropTypes.instanceOf(Array).isRequired,
    getPageNotifications: PropTypes.instanceOf(Function).isRequired,
    taxonomies: PropTypes.instanceOf(Array).isRequired,
    users: PropTypes.instanceOf(Array).isRequired,
  };

  componentDidMount() {
    const { getUsersForSearchCertificates, getPageNotifications } = this.props;
    const user = getUserFromToken();
    getUsersForSearchCertificates(user.id);
    const pageDetails = {
      search: [],
      page: 1,
    };
    getPageNotifications(pageDetails);
  }

  doReset = () => {
    const { reset } = this.props;
    reset();
  };

  filterCertificates = () => {
    const { getPageNotifications } = this.props;
    const pageDetails = this.getPageDetails();
    getPageNotifications(pageDetails);
  };

  getPageDetails = () => {
    const { listaCertificate, users } = this.props;
    let { pageDetails } = this.props;
    pageDetails = {
      ...pageDetails,
      search: [],
      page: 1,
    };

    if (listaCertificate.values) {
      const userFound = Object.entries(listaCertificate.values).find(
        value => value[0] === 'initiatorUser',
      );
      if (!hasRole('ROLE_ADMIN') && (!userFound || !userFound[1])) {
        users.userOptions.forEach(u => {
          pageDetails.search.push({
            key: 'certificate.initiatorUser',
            operation: ':',
            value: u.id,
            orPredicate: true,
            precondition: true,
          });
        });
      }

      Object.entries(listaCertificate.values).forEach(value => {
        if (value[1]) {
          if (value[0] === 'institution') {
            pageDetails.search.push({
              key: 'certificate.initiatorUser.institution.id',
              operation: ':',
              value: value[1],
            });
          } else if (value[0] === 'initiatorUser') {
            pageDetails.search.push({
              key: 'certificate.initiatorUser.id',
              operation: ':',
              value: value[1],
            });
          } else if (value[0] === 'validatorUser') {
            pageDetails.search.push({
              key: 'certificate.validatorUser.id',
              operation: ':',
              value: value[1],
            });
          } else if (value[0] === 'notification') {
            pageDetails.search.push({
              key: 'notification.id',
              operation: ':',
              value: value[1],
            });
          } else if (value[0] === 'series') {
            pageDetails.search.push({
              key: 'certificate.series',
              operation: ':',
              value: value[1],
            });
          } else if (value[0] === 'numberNr') {
            pageDetails.search.push({
              key: 'certificate.number',
              operation: ':',
              value: value[1],
            });
          } else if (value[0] === 'completeStartDate') {
            const date = new Date(value[1]);
            pageDetails.search.push({
              key: 'certificate.dateCompletion',
              operation: '>',
              value: `${date.getDate()}.${date.getMonth() +
                1}.${date.getFullYear()}`,
            });
          } else if (value[0] === 'completeEndDate') {
            const date = new Date(value[1]);
            pageDetails.search.push({
              key: 'certificate.dateCompletion',
              operation: '<',
              value: `${date.getDate()}.${date.getMonth() +
                1}.${date.getFullYear()}`,
            });
          } else if (value[0] === 'vacationStartDateFrom') {
            const date = new Date(value[1]);
            pageDetails.search.push({
              key: 'certificate.startDate',
              operation: '>',
              value: `${date.getDate()}.${date.getMonth() +
                1}.${date.getFullYear()}`,
            });
          } else if (value[0] === 'vacationStartDateTo') {
            const date = new Date(value[1]);
            pageDetails.search.push({
              key: 'certificate.startDate',
              operation: '<',
              value: `${date.getDate()}.${date.getMonth() +
                1}.${date.getFullYear()}`,
            });
          } else if (value[0] === 'vacationEndDateFrom') {
            const date = new Date(value[1]);
            pageDetails.search.push({
              key: 'certificate.endDate',
              operation: '>',
              value: `${date.getDate()}.${date.getMonth() +
                1}.${date.getFullYear()}`,
            });
          } else if (value[0] === 'vacationEndDateTo') {
            const date = new Date(value[1]);
            pageDetails.search.push({
              key: 'certificate.endDate',
              operation: '<',
              value: `${date.getDate()}.${date.getMonth() +
                1}.${date.getFullYear()}`,
            });
          } else if (value[0] === 'idnp') {
            pageDetails.search.push({
              key: 'certificate.patientDetails.idnp',
              operation: ':',
              value: value[1],
            });
          } else {
            pageDetails.search.push({
              key: value[0],
              operation: ':',
              value: value[1],
            });
          }
        }
      });
    } else if (!hasRole('ROLE_ADMIN')) {
      users.userOptions.forEach(u => {
        pageDetails.search.push({
          key: 'initiatorUser',
          operation: ':',
          value: u.id,
          orPredicate: true,
          precondition: true,
        });
      });
    }

    pageDetails.search = pageDetails.search.map(search => {
      return {
        ...search,
        isPrecondition: true,
      };
    });

    return pageDetails;
  };

  render() {
    const {
      notificationsList,
      pageDetails,
      taxonomies,
      users,
      getPageNotifications,
    } = this.props;

    const { search } = pageDetails;
    let userValue = null;
    if (users && users.userOptions && users.userOptions.length === 1) {
      userValue = users.userOptions[0].id;
    }
    let validators = [];
    if (users && users.userOptions) {
      validators = users.userOptions.filter(
        el =>
          el.roles &&
          el.roles.some(
            role => role.code === 'ROLE_ADMIN' || role.code === 'ROLE_VAL',
          ),
      );
    }
    let validatorValue = null;
    if (validators && validators.length === 1) {
      validatorValue = validators[0].id;
    }
    return (
      <div className="box">
        <div className="box__container">
          <AlchemyNotification />
          <Container>
            <div className="box__quarter-block-responsive">
              <Field
                name="notification"
                component={AlchemyAdvancedSelect}
                options={taxonomies && taxonomies.NmNotifications}
                labelText="Notificare"
              />
              <Field
                name="idnp"
                type="text"
                component={AlchemyTextInput}
                label="IDNP"
              />
              <Field
                name="series"
                type="text"
                component={AlchemyTextInput}
                label="Seria certificatului"
              />
              <Field
                name="numberNr"
                type="text"
                component={AlchemyTextInput}
                label="Numarul certificatului"
              />
              <Field
                name="completeStartDate"
                labelText="Data completarii de la"
                component={AlchemyDateTimePicker}
                showTimeSelect={false}
              />
              <Field
                name="completeEndDate"
                labelText="Data completarii pina la"
                component={AlchemyDateTimePicker}
                showTimeSelect={false}
              />
              <Field
                name="vacationStartDateFrom"
                labelText="Data acordarii concediului de la"
                component={AlchemyDateTimePicker}
                showTimeSelect={false}
              />
              <Field
                name="vacationStartDateTo"
                labelText="Data acordarii concediului pina la"
                component={AlchemyDateTimePicker}
                showTimeSelect={false}
              />
              <Field
                name="vacationEndDateFrom"
                labelText="Data inchiderii concediului de la"
                component={AlchemyDateTimePicker}
                showTimeSelect={false}
              />
              <Field
                name="vacationEndDateTo"
                labelText="Data inchiderii concediului pina la"
                component={AlchemyDateTimePicker}
                showTimeSelect={false}
              />
              <Field
                name="initiatorUser"
                value={userValue}
                component={AlchemyAdvancedSelect}
                options={
                  users &&
                  users.userOptions &&
                  users.userOptions.map(usr => {
                    return {
                      id: usr.id,
                      description: `${usr.lastName} ${usr.firstName}`,
                    };
                  })
                }
                labelText="Registratorul"
              />
              {(hasRole('ROLE_ADMIN') || hasRole('ROLE_VAL')) && (
                <Field
                  name="validatorUser"
                  value={validatorValue}
                  component={AlchemyAdvancedSelect}
                  options={
                    validators &&
                    validators.map(usr => {
                      return {
                        id: usr.id,
                        description: `${usr.lastName} ${usr.firstName}`,
                      };
                    })
                  }
                  labelText="Validatorul"
                />
              )}
            </div>

            <div className="box__quarter-block">
              {hasRole('ROLE_ADMIN') && (
                <React.Fragment>
                  <Field
                    name="institution"
                    component={AlchemyAdvancedSelect}
                    options={taxonomies && taxonomies.NmInstitutions}
                    labelText="Institutie"
                  />
                </React.Fragment>
              )}
            </div>

            <div className="box__quarter-block reset-container">
              <div className="box__triple-block">
                <Button
                  component="topButton"
                  kind="primary"
                  onClick={this.doReset}
                >
                  Reseteaza
                  <Reset20 className="bx--btn__icon" />
                </Button>
                <Button
                  component="topButton"
                  kind="primary"
                  onClick={() => this.filterCertificates()}
                >
                  Cautare
                  <Search16 className="bx--btn__icon" />
                </Button>
              </div>
            </div>
          </Container>
          <SearcheableTable
            title="Lista notificarilor"
            headers={headers}
            rows={notificationsList}
            getPage={getPageNotifications}
            direction="asc"
            pageDetails={pageDetails}
            searchPrecondition={search}
            tableResponsive
            searchFields={[
              'patientDetails.firstName',
              'patientDetails.lastName',
              'patientDetails.idnp',
            ]}
            cellRendering={cell => {
              return (
                <DataTable.TableCell
                  key={cell.id}
                  className={
                    cell.value && cell.value.length > 30 ? 'td-long' : null
                  }
                >
                  {cell.value}
                </DataTable.TableCell>
              );
            }}
          />
        </div>
      </div>
    );
  }
}

const ReduxForm = reduxForm({
  form: 'listaCertificate',
  enableReinitialize: true,
})(Notificari);

export default connect(
  state => {
    const { users, concedii, taxonomies, form } = state;
    const { loading } = concedii;
    const { listaCertificate } = form;
    const page =
      concedii && concedii.pageNotification ? concedii.pageNotification : null;

    if (page) {
      return {
        loading,
        users: users && { userOptions: users.usersSearch },
        taxonomies: taxonomies.taxonomies,
        cetificateStatuses: taxonomies.taxonomies.NmCertificateStatuses,
        notificationsList: page.content.map(el => {
          return {
            ...el,
            id: el.id.toString(),
            notification: get(el, 'notification.description', null),
            idnp: get(el, 'certificate.patientDetails.idnp', null),
            cpas: get(el, 'certificate.patientDetails.cpas', null),
            lastName: get(el, 'certificate.patientDetails.lastName', null),
            firstName: get(el, 'certificate.patientDetails.firstName', null),
            series: get(el, 'certificate.series', null),
            number: get(el, 'certificate.number', null),
            type: get(el, 'certificate.type.description', null),
            status: get(el, 'certificate.status.description', null),
            dateCompletionStr:
              el.certificate && el.certificate.dateCompletion
                ? new Date(el.certificate.dateCompletion).toLocaleDateString()
                : '',
            startDate:
              el.certificate &&
              new Date(el.certificate.startDate).toLocaleDateString(),
            endDate:
              el.certificate &&
              new Date(el.certificate.endDate).toLocaleDateString(),
            initiatorUser:
              get(el, 'certificate.initiatorUser.firstName', null) &&
              get(el, 'certificate.initiatorUser.lastName', null)
                ? `${el.certificate.initiatorUser.firstName} ${
                    el.certificate.initiatorUser.lastName
                  }`
                : '',
            validatorUser:
              get(el, 'certificate.validatorUser.firstName', null) &&
              get(el, 'certificate.validatorUser.lastName', null)
                ? `${el.certificate.validatorUser.firstName} ${
                    el.certificate.validatorUser.lastName
                  }`
                : '',
            validatorPhoneNumber: get(
              el,
              'certificate.validatorUser.phoneNumber',
              '',
            ),
            institution: get(
              el,
              'certificate.initiatorUser.institution.description',
              null,
            ),
          };
        }),
        pageDetails: {
          page: page.number ? page.number + 1 : 1,
          pageSize: page.page ? page.page : 10,
          totalItems: page.totalElements ? page.totalElements : 0,
          orderBy: page.orderBy ? page.orderBy : 'id',
          direction: page.direction ? page.direction : 'asc',
          search: page.search ? page.search : [],
        },
        listaCertificate,
      };
    }
    return {
      users: users && { userOptions: users.usersSearch },
      taxonomies: taxonomies.taxonomies,
      notificationsList: [],
      pageDetails: {
        page: 1,
        pageSize: 10,
        totalItems: 0,
        orderBy: 'id',
        direction: 'asc',
      },
      listaCertificate,
      loading,
    };
  },
  {
    getPageNotifications: concediiActions.getPageNotifications,
    getUsersForSearchCertificates: userActions.getUsersForSearchCertificates,
    updateCetificate: concediiActions.updateCetificate,
    sendConcediuToCNAS: concediiActions.sendConcediuToCNAS,
    filterCertificate: concediiActions.filterCertificate,
    downloadFile: concediiActions.downloadFile,
    postSignForCertId: msignActions.postSignForCertId,
    sendCancelToCNAS: concediiActions.sendCancelToCNAS,
    rejectCertificate: concediiActions.rejectCertificate,
    zipAction: concediiActions.zipCertificate,
    unzipAction: concediiActions.unzipCertificate,
    verifyCertificateOnCNASAction: concediiActions.verifyCertificateOnCNAS,
    showError: alertActions.error,
  },
)(ReduxForm);
