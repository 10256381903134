import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { AccordionItem, Accordion } from 'carbon-components-react';
import './sidebar.scss';
// import AppSwitcher20 from '@carbon/icons-react/es/app-switcher/20';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hasRole } from '../helpers/utils';
import { userActions } from '../redux/actions';

const SidebarContent = styled.div`
  padding: 1rem 0px;
  border-right: 1px solid rgba(61, 61, 61, 0.06);
  background-color: #fff;
`;

const MenuSwitcher = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-right: 2px;
`;

const Switcher = styled.button`
  display: flex;
  flex-direction: row-reverse;
  /* padding: 0.5rem; */
  height: 50px;
  background: #fff;
  border: 0.125rem solid transparent;
  outline: 2px solid transparent;
  &:active {
    outline: 2px solid #0353e9;
    background: #f3f3f3;
  }
  &:hover {
    cursor: pointer;
  }
`;

// const AppSwitcher20Icon = styled(AppSwitcher20)`
//   fill: #000;
// `;

const Menu = styled.div`
  display: block;
`;

const MenuTitle = styled.span`
  font-size: 1rem;
  transform: rotate(270deg);
`;

const LinkContent = styled.div`
  width: 100%;
  padding: 0.5rem 0px;
  padding-left: 0.275rem;
  cursor: pointer;
`;

const showMenu = {
  display: 'block',
};

const hideMenu = {
  display: 'none',
};

class SidebarComponent extends React.Component {
  stats = {};

  static propTypes = {
    logoutSAML: PropTypes.instanceOf(Function).isRequired,
    statusMenu: PropTypes.bool.isRequired,
    menuStatus: PropTypes.instanceOf(Function).isRequired,
  };

  render() {
    const { menuStatus, statusMenu, logoutSAML } = this.props;
    return (
      <SidebarContent>
        <MenuSwitcher>
          <Switcher type="button" onClick={menuStatus}>
            {/* <AppSwitcher20Icon /> */}
            <MenuTitle>Meniu</MenuTitle>
          </Switcher>
        </MenuSwitcher>
        <Menu style={statusMenu ? showMenu : hideMenu}>
          <Accordion>
            {(hasRole('ROLE_ADMIN') ||
              hasRole('ROLE_REG') ||
              hasRole('ROLE_VAL')) && (
              <AccordionItem title="Concedii medicale" open>
                {(hasRole('ROLE_REG') || hasRole('ROLE_ADMIN')) && (
                  <LinkContent>
                    <NavLink
                      className="AccordLink"
                      to="/forma-concediu-medical"
                    >
                      Creare certificat
                    </NavLink>
                  </LinkContent>
                )}
                <LinkContent>
                  <NavLink to="/" className="AccordLink">
                    Listă certificate
                  </NavLink>
                </LinkContent>
                {(hasRole('ROLE_VAL') || hasRole('ROLE_ADMIN')) && (
                  <LinkContent>
                    <NavLink to="/notifications" className="AccordLink">
                      Notificări
                    </NavLink>
                  </LinkContent>
                )}
                {(hasRole('ROLE_VAL') || hasRole('ROLE_ADMIN')) && (
                  <LinkContent>
                    <NavLink
                      to="/report-certificates-number"
                      className="AccordLink"
                    >
                      Raport privind numărul de certificate
                    </NavLink>
                  </LinkContent>
                )}
                {(hasRole('ROLE_VAL') || hasRole('ROLE_ADMIN')) && (
                  <LinkContent>
                    <NavLink to="/fisa-beneficiar" className="AccordLink">
                      Fișă beneficiar
                    </NavLink>
                  </LinkContent>
                )}
              </AccordionItem>
            )}
            {hasRole('ROLE_ADMIN') && (
              <AccordionItem title="Administrare" open>
                <LinkContent>
                  <NavLink to="/access-management" className="AccordLink">
                    Administrare utilizatori
                  </NavLink>
                </LinkContent>
                <LinkContent>
                  <NavLink to="/taxonomy-management" className="AccordLink">
                    Administrare nomenclatoare
                  </NavLink>
                </LinkContent>
              </AccordionItem>
            )}
            {(hasRole('ROLE_ADMIN') || hasRole('ROLE_AUDIT')) && (
              <LinkContent>
                <NavLink to="/audit" className="AccordLink-primary bold">
                  Audit
                </NavLink>
              </LinkContent>
            )}
            <LinkContent>
              <NavLink
                to="/profil-utilizator"
                className="AccordLink-primary bold"
              >
                Profil utilizator
              </NavLink>
            </LinkContent>
            <LinkContent>
              <NavLink to="/guide-files" className="AccordLink-primary bold">
                Ghiduri
              </NavLink>
            </LinkContent>
            <LinkContent>
              <NavLink
                to="#"
                onClick={() => logoutSAML()}
                className="AccordLink-primary bold"
              >
                Iesire
              </NavLink>
            </LinkContent>
          </Accordion>
        </Menu>
        {/* <span className="version">v{process.env.REACT_APP_VERSION}</span> */}
      </SidebarContent>
    );
  }
}

// Sidebar.propTypes = {
//   statusMenu: PropTypes.bool.isRequired,
//   menuStatus: PropTypes.instanceOf(Function).isRequired,
// };

const StyledSidebar = styled(SidebarComponent)`
  grid-column: sidebar-start / sidebar-end;
`;

export default connect(
  null,
  {
    logoutSAML: userActions.logoutSAML,
  },
)(StyledSidebar);
